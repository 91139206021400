import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";


import NGPagePC from "./NGPagePC";
import NGPageMobile from "./NGPageMobile";
import NGPageTablet from "./NGPageTablet";



import i18n from "../../../../i18n";


import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';


const viewportHeight = window.innerHeight;


class NGPage extends Component {
	
    constructor(props) {
		
          super(props);

	
     }
	 
	 
	 componentWillMount(){
   	   //  document.body.style.backgroundImage = "#fff";
	 }
	 
	 componentWillUnmount(){
    	    //  document.body.style.backgroundImage = null;
	 }
	 

	
	
	
	
  render() {

	  
    return (
	    <Translation>
		    { t =>
		          <div>        		
				<BrowserView>
		                         <NGPagePC store={this.props.store}  />
				</BrowserView>	
	
				{isMobileOnly &&
				    <MobileView>
		                         <NGPageMobile store={this.props.store}  />			
				   </MobileView>
				}

				{isTablet &&
				   <TabletView>
				        <NGPageTablet store={this.props.store}  />
				   </TabletView>
				}
											  
		         </div>
		  }
		 </Translation>
    )
  }
}



const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(NGPage);


