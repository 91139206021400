import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";

import { View, Text, Image, FlatList, ActivityIndicator} from "react-native-web";

import PubSub from 'pubsub-js';

import Select from 'react-select';

import Slider from "react-slick";

import { Slide } from 'react-slideshow-image';

import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.scss";

//import {Link} from "react-router-dom";

import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';


//import MetaTags from 'react-meta-tags';
//import HeaderPC from "../header/HeaderPC";
import   FooterTablet from "../../footer/FooterTablet";

import i18n from "../../../i18n";


import HamburgerMenu from 'react-hamburger-menu';

import { Fade } from 'react-slideshow-image';


var africaCountries = [
		   { value: 'TG', label: i18n.t('TG') },
		   { value: 'CI', label: i18n.t('CI')},
		   { value: 'GH', label: i18n.t('GH')},
		   { value: 'SN', label: i18n.t('SN')},
		   { value: 'NG', label: i18n.t('NG')}
];

var globalOptions = [
	{
	   label: i18n.t('Africa'),
	   options: africaCountries
	},
	{
	   label: i18n.t('America'),
 	   options: [
 		   { value: 'America_empty',  label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	},
	{
	   label: i18n.t('Asia_and_oceania'),
 	   options: [
 		   { value: 'Asia_empty ', label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	},
	{
	   label: i18n.t('Europe'),
 	   options: [
 		   { value: 'Europe_empty', label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	}

];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}


const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div  className={className}
      style={{...style, display: "block",position: 'absolute', zIndex: 9, top: '50%', right: '20px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div  className={className}
      style={{ ...style, display: "block",position: 'absolute', zIndex: 9, top: '50%', left: '20px'}}
      onClick={onClick}
    />
  );
}

//@inject("appStore")
//@observer
class HomePageTablet extends Component {

    constructor(props) {

          super(props);



       //   console.log(">>>  store:" + JSON.stringify(store))

		//  store.dispatch("Hello World")

   	 this.state = {
			 displayPopUp: true,
           backgroundColor: "black",
          activeColor: "info",
		selectedOption: null,
		 options: globalOptions,
		 bodyPos: 0,
		open: false,
		 currentYear: new Date().getFullYear()
   	};

     }


	 componentWillMount(){
   	   document.body.style.backgroundImage = "#fff";

	   window.addEventListener("orientationchange", function() {
	   	// Announce the new orientation number
		   window.location.reload();
	   }, false);
	 }

	 componentWillUnmount(){
    	   document.body.style.backgroundImage = null;
	 }



	 handleChange = selectedOption => {

		 console.log(">>> selectedOption:" + selectedOption);

	     this.setState(
	       { selectedOption },
	       () => console.log(`Option selected:`, this.state.selectedOption)
	     );

		 window.location.href = "/" + selectedOption.value.toLowerCase();

	  }

	 handleClick() {
		 if (this.state.open)
		 {
			// var _owidth = '0%';
			 document.getElementById("myNav").style.width = "0%";
			 document.body.style.overflow = 'auto';
			 document.body.style.position = 'relative';
			 window.scrollTo(0, this.state.bodyPos);

		 }
		 else
		 {
		 	//var _owidth = '100%';
			var bodyPos  = window.pageYOffset || document.documentElement.scrollTop;
			 this.setState({
				 bodyPos: bodyPos
			 });
			 document.getElementById("myNav").style.width = "100%";
			 document.body.style.overflow = 'hidden';
			 document.body.style.position = 'fixed';
		 }

	 	this.setState({
	 		open: !this.state.open
	 	});
	 }


     setLocale(lang) {

		   	   console.log("Locale Lang:" +lang)
		         //   this.props.appStore.locale = lang;

		 	 	  localStorage.setItem("language", lang);
		 	 	  i18n.changeLanguage(lang);

		 	   	this.setState({
		 	   		open: false
		 	   	});

		 	    document.getElementById("myNav").style.width = "0%";
		 	    document.body.style.overflow = 'auto';
		 	   document.body.style.position = 'relative';
		 	    window.scrollTo(0, this.state.bodyPos);


	  		  var _options = [], _africaCountries = [];
	  		 setTimeout (function(){
	  			   _africaCountries  = [
	  	 						   { value: 'TG', label: i18n.t('TG') },
	  	 						   { value: 'CI', label: i18n.t('CI')},
	  	 						   { value: 'GH', label: i18n.t('GH')},
	  	 						   { value: 'SN', label: i18n.t('SN')},
	  	 						   { value: 'NG', label: i18n.t('NG')}
	  	 				             ];
	  			   _africaCountries = _africaCountries.sort((a,b) => a.label.localeCompare(b.label));

	  			    _options = [
	  							{
	  							   label: i18n.t('Africa'),
	  							   options: _africaCountries
	  							},
	  							{
	  							   label: i18n.t('America'),
	  						 	   options: [
	  						 		   { value: 'America_empty',  label: i18n.t('Not_available_yet'), isDisabled: true },
	  						 	   ]
	  							},
	  							{
	  							   label: i18n.t('Asia_and_oceania'),
	  						 	   options: [
	  						 		   { value: 'Asia_empty ', label: i18n.t('Not_available_yet'), isDisabled: true },
	  						 	   ]
	  							},
	  							{
	  							   label: i18n.t('Europe'),
	  						 	   options: [
	  						 		   { value: 'Europe_empty', label: i18n.t('Not_available_yet'), isDisabled: true },
	  						 	   ]
	  							}

	  						];
	  	     	           _options = _options.sort((a,b) => a.label.localeCompare(b.label));
	  		  }.bind(this),100);


	   		 setTimeout (function(){
	   			     this.setState({
	   			       options: _options
	   			     });
	   		  }.bind(this),200);
     }


		 closePopUp() {
				this.setState({
					displayPopUp: false
				});
		}


		whatchFilm(t) {
       PubSub.publish('launchVideoPop', t);
    }



  render() {

	  if (viewportWidth  > viewportHeight)
	  {
		  var _n = 3;
	  }
	  else
	  {
	  	var _n = 2;
	  }

	  var settings = {
	    className: "",
	      centerMode: true,
	      infinite: true,
	      centerPadding: "2px",
	      slidesToShow: _n,
	      speed: 500,
		  arrows: true,
	       nextArrow: <SampleNextArrow />,
	        prevArrow: <SamplePrevArrow />
	      };

				let slideImages;

				if (i18n.language == "fr")
				{
						slideImages = [
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_2_tablet_fr.jpg',
						    caption: ''
						  },
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_blurred.jpg',
						    caption: ''
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/africans_1.jpg',
						    caption: 'Faire de la COP27 la COP Africaine'
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/youth_world_pc.jpg',
						    caption: 'Inspirer les jeunes à mener les initiatives de transition énergétique'
						  }
						];
				}
				else
				{
						slideImages = [
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_2_tablet_en.jpg',
						    caption: ''
						  },
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_blurred.jpg',
						    caption: ''
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/africans_1.jpg',
						    caption: 'Make the COP27 the African COP'
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/youth_world_pc.jpg',
						    caption: "Inspire young people to lead the energy transition initiatives"
						  }
						];
				}


    return (
    <Translation>
	    { t =>
	         <div>


						 {this.state.displayPopUp && 1==2 &&
							 <div style={{position: 'fixed', zIndex: 999, width: '100%', height: '100%', top: 0, left: 0,
																			background: 'rgba(0,0,0,0.8)'}}>

								<div style={{position: 'relative', width: '100%', height: '100%',
														background: 'transparent'}}>

									  <div onClick={this.closePopUp.bind(this)} style={{cursor: 'pointer',  position: 'relative',  width: '100%', height: '100%',}}>
											  <div style={{position: 'fixed', top: '10px', right: '20px', zIndex: 99999, color: '#fff', fontSize: '60px'}}>
												   	&times;
										    </div>
										</div>

										<div style={{position: 'absolute', top: '80px', width: '660px', left: '50%', marginLeft: '-330px',
											   zIndex: 99999}}>

											<div style={{clear: 'both', height: '0px'}} />
											 <div style={{margin:'auto', background:  '#fff', width: '660px', borderRadius: '10px'}}>

												 <div style={{clear: 'both', height: '20px'}} />

												 <div style={{margin:'auto', background:  '#fff', width: '600px', color: '#333', fontSize: '25px', textAlign: 'center'}}>
	                            {i18n.language == "fr" &&
																<div>
																	  Rejoindre la communcauté de la Youth Initiative Jeunesse est désormais possible via l'application Debyoo pour <a href="https://apple.co/3g76hoz" target="_bblank">iPhone</a> et <a href="https://bit.ly/3kU3QJA" target="_bblank">Android</a>
																</div>
															}
															{i18n.language == "en" &&
																<div>
																	  Joining the Youth Initiative Jeunesse community is now possible via the Debyoo app for <a href="https://apple.co/3g76hoz" target="_bblank">iPhone</a> and <a href="https://bit.ly/3kU3QJA" target="_bblank">Android</a>
																</div>
															}
												 </div>

												 <div style={{clear: 'both', height: '20px'}} />

												 <div style={{margin:'auto', background:  '#fff', width: '600px', border: '1px  solid #333', height: '337px'}}>
													 <iframe width="600" height="337" src="https://www.youtube.com/embed/EFH4pwHtVdQ?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1" frameborder="0" allowfullscreen></iframe>
												 </div>

												  <div style={{clear: 'both', height: '30px'}} />


														<div style={{margin:'auto', background:  '#fff', width: '300px', color: '#333', fontSize: '20px', textAlign: 'center'}}>
		 																												{t('Download')} {'Debyoo'}
		 												</div>


		 											<div style={{clear: 'both', height: '20px'}} />

		 													<div style={{textAlign: 'center', marginTop: '0px'}}>
		 																	 {i18n.language == "fr" &&
		 																			 <a href={'https://itunes.apple.com/fr/app/debyoo/id1460835118'} target="_blank">
		 																					 <img src={'https://s3.amazonaws.com/debyooinc20.img/apple_store_download.png'} style={{width: '180px'}} />
		 																			 </a>
		 																	 }
		 																		{i18n.language == "en" &&
		 																		<a href={'https://itunes.apple.com/app/debyoo/id1460835118'} target="_blank">
		 																			<img src={'https://s3.amazonaws.com/debyooinc20.img/apple_store_download.png'} style={{width: '180px'}} />
		 																		</a>
		 																	 }

		 																	 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

		 																	 <a href={'https://play.google.com/store/apps/details?id=com.androidv.debyooapp&hl='+i18n.language} target="_blank">
		 																		<img src={'https://s3.amazonaws.com/debyooinc20.img/google_store_download.png'} style={{width: '180px'}} />
		 																	</a>
		 										    </div>


														<div style={{clear: 'both', height: '20px'}} />


											</div>
										</div>

										</div>


								</div>
						}



							 <div className="main_header">


								<div style={{position: 'absolute', top: '2px', left: '15px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
								       <a href="/">
								         <img src={'https://yinitj5.s3.amazonaws.com/yinitj_logo_transp_bar.png'} style={{height: '66px'}} />
								       </a>
								</div>

								<div style={{position: 'absolute', top: '25px', right: '20px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
												 <HamburgerMenu
												 	isOpen={this.state.open}
												 	menuClicked={this.handleClick.bind(this)}
												 	width={35}
												 	height={18}
												 	strokeWidth={1}
												 	rotate={0}
												 	color='black'
												 	borderRadius={0}
												 	animationDuration={0.5}
												 />
								</div>


							</div>



							 <div id="myNav" className="mobileMenuOverlay"
									style={{height:  viewportHeight-70,background: '#fafafa', textAlign: 'center', paddingTop: '30px', lineHeight: '55px', fontSize: '18px'}}>

										    <div  style={{borderBottom: '1px solid #eee', width: '70%', margin: 'auto'}}>
											   <a href="/">
													{t('Home')}
											     </a>
										    </div>
										   <div  style={{borderBottom: '1px solid #eee', width: '70%', margin: 'auto'}}>
											   <Link activeClass="activeMenuLink" className="menu_link" to="menu_challenges" onClick={() => this.handleClick()}  spy={true} smooth={true} offset={-72} duration={700} delay={0}>
													{t('The_challenges')}
											     </Link>
										   </div>
										  <div  style={{borderBottom: '1px solid #eee', width: '70%', margin: 'auto'}}>
											    <Link activeClass="activeMenuLink" className="menu_link" to="menu_youth" onClick={() => this.handleClick()}  spy={true} smooth={true} offset={-72} duration={700} delay={0}>
													{t('Why_youth')}
											     </Link>
										  </div>
										 <div  style={{borderBottom: '1px solid #eee', width: '70%', margin: 'auto'}}>
											    <Link activeClass="activeMenuLink" className="menu_link"  to="menu_mission" onClick={() => this.handleClick()}  spy={true} smooth={true} offset={-72} duration={700} delay={0}>
													{t('Mission_and_vision')}
											     </Link>
										  </div>
										  <div  style={{borderBottom: '1px solid #eee', width: '70%', margin: 'auto'}}>
											    <Link activeClass="activeMenuLink" className="menu_link" to="menu_scope" onClick={() => this.handleClick()}  spy={true} smooth={true} offset={-72} duration={700} delay={0}>
													{t('Scope_of_action')}
											     </Link>
										 </div>
										 <div  style={{borderBottom: '1px solid #eee', width: '70%', margin: 'auto'}}>
												 <a href="/support">
												    {t('Support')}
													</a>
										 </div>
										 <div  style={{borderBottom: '1px solid #eee', width: '70%', margin: 'auto'}}>
												 <a href="/privacy">
												    {t('Privacy')}
													</a>
										 </div>


									      <div style={{position: 'relative', width: '95px', margin: 'auto', marginTop: '60px'}}>
											   {i18n.language == "fr" &&
												 <div onClick={() => this.setLocale("en")}
												    style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '0px', display: 'block', fontSize: '15px', background: 'rgba(255,255,255,0)',
													  borderRadius: '50%', width: '50px', lineHeight: '50px', height: '50px', textAlign: 'center',
													 border: '1px solid #333', color: '#333'}}>
											          EN
											      </div>
											  }
											   {i18n.language == "en" &&
											  <div onClick={() => this.setLocale("en")}
												    style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '0px', display: 'block', fontSize: '15px', background: 'rgba(255,255,255,0)',
													  borderRadius: '50%', width: '50px', lineHeight: '50px', height: '50px', textAlign: 'center',
													 border: '1px solid #2489CE', color: '#333'}}>
											          EN
											      </div>
											  }
											   {i18n.language == "fr" &&
											  <div onClick={() => this.setLocale("fr")}
												   style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '60px', display: 'block', fontSize: '15px', background: 'rgba(255,255,255,0)',
												   borderRadius: '50%', width: '50px', lineHeight: '50px', height: '50px', textAlign: 'center',
												  border: '1px solid #2489CE', color: '#333'}}>
											             FR
											      </div>
										          }
											   {i18n.language == "en" &&
												  <div onClick={() => this.setLocale("fr")}
												   style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '50px', display: 'block', fontSize: '15px', background: 'rgba(255,255,255,0)',
												   borderRadius: '50%', width: '50px', lineHeight: '50px', height: '50px', textAlign: 'center',
												  border: '1px solid #333', color: '#333'}}>
											             FR
											      </div>
										          }
									     </div>
							 </div>


						        <div style={{margin: 'auto', height: '70px'}} />


										{1==2 &&
										  <a href="/covid19"><div style={{cursor: 'pointer', width: '100%',  height: '60px',  lineHeight: '60px', color: '#fff',
												    fontSize: '17px', background: '#0271e3', textAlign: 'center'}}>
			 								      {i18n.language == "fr" &&
			                           <div>{"COVID-19 :: SOYEZ PRUDENT »"}</div>
			 											}
			 											{i18n.language == "en" &&
			                            <div>{"COVID-19 :: STAY ALERT »"}</div>
			 											}
			 							 </div></a>
									 }



									 <div  style={{display: 'none', position: 'relative', width: '100%', minHeight: '400px',  height: viewportHeight-150, overflow: 'hidden', marginTop: '0px', zIndex: 0, fontFamily: 'Roboto'}}>

																		 <div style={{width:'100%',height:'100%',background:'rgba(0,0,0,0.5)',
																								 position:'absolute',left:'0px', margin:'0px', padding:'0px', top:'0px', zIndex: 2}}>
																		 </div>
																		 <div style={{width:'100%',height:'100%',background:'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABZJREFUeNpi2r9//38gYGAEESAAEGAAasgJOgzOKCoAAAAASUVORK5CYII=)',
																								 position:'absolute',left:'0px', margin:'0px', padding:'0px', top:'0px', zIndex: 1}}>
																		 </div>
																		 <div style={{background: 'url(https://togotptogo4.s3.amazonaws.com/ezgif.com-optimize.gif) no-repeat center top',
																									backgroundSize: 'cover', height: '110%', }}>
																		</div>

																	 <div style={{position: 'absolute', zIndex: 3, width: '100%', top: '90px', textAlign: 'center', color: '#fff',
																							 textShadow: '1px 1px 1px #000'}}>

																							 {i18n.language == "fr" &&
																								 <div style={{position: 'relative', width: '80%', textAlign: 'center', fontSize: '60px', margin: 'auto'}}>
																									 <div>Togo The Place To Go</div>
																									 <div style={{fontSize: '22px', marginTop: '60px'}}>Narrateur</div>
																									 <div style={{fontSize: '35px', marginTop: '5px'}}>Julio Teko</div>
																								 </div>
																							 }
																							 {i18n.language == "en" &&
																								 <div style={{position: 'relative', width: '80%', textAlign: 'center', fontSize: '60px', margin: 'auto'}}>
																										<div>Togo The Place To Go</div>
																									 <div style={{fontSize: '22px', marginTop: '60px'}}>Narrator</div>
																									 <div style={{fontSize: '35px', marginTop: '5px'}}>Julio Teko</div>
																								 </div>
																							 }



																							 {i18n.language == "fr" &&
																								 <a href={'https://www.debyoo.com'} target={'_blank'}>
																								   <div style={{cursor: 'pointer', position: 'relative', width: '350px',  height: '40px', lineHeight: '40px',
																															borderRadius: '30px', border: '2px solid #fff', background: 'rgba(0,0,0,0.4)',
																															 textAlign: 'center', fontSize: '22px', margin: 'auto', marginTop: '80px', }}>
																											 <div style={{float:'left', marginLeft: '22px', height: '40px', lineHeight: '40px', color: '#fff'}}>Regarder la vidéo</div>
																											 <div style={{float:'right', marginRight: '5px', marginTop: '3px'}}><img src={'/img/swiper_next.png'} style={{width: '34px'}}/></div>
																											 <div style={{clear:'both'}}></div>
																								   </div>
																							 </a>
																							 }
																							 {i18n.language == "en" &&
																								 <a href={'https://www.debyoo.com'} target={'_blank'}>
																								    <div  style={{cursor: 'pointer', position: 'relative', width: '220px',  height: '40px', lineHeight: '40px',
																															borderRadius: '30px', border: '2px solid #fff', background: 'rgba(0,0,0,0.4)',
																															 textAlign: 'center', fontSize: '22px', margin: 'auto', marginTop: '80px', }}>
																															 <div style={{float:'left', marginLeft: '22px', height: '40px', lineHeight: '40px', color: '#fff'}}>Watch the video</div>
																															 <div style={{float:'right', marginRight: '5px', marginTop: '3px'}}><img src={'/img/swiper_next.png'} style={{width: '34px'}}/></div>
																															 <div style={{clear:'both'}}></div>
																								    </div>
																								 </a>
																							 }



																							 {i18n.language == "fr" &&
																								 <div style={{position: 'relative', width: '80%', textAlign: 'center', fontSize: '40px', margin: 'auto', marginTop: '70px'}}>
																										 <div style={{fontSize: '20px', marginBottom: '5px'}}>Première diffusion le</div>
																										 <div>30.10.2020</div>
																								 </div>
																							 }
																							 {i18n.language == "en" &&
																								 <div style={{position: 'relative', width: '80%', textAlign: 'center', fontSize: '40px', margin: 'auto', marginTop: '70px'}}>
																											<div style={{fontSize: '20px', marginBottom: '5px'}}>Release date</div>
																											<div>10.30.2020</div>
																								 </div>
																							 }

																	</div>


																	<div style={{position: 'absolute', zIndex: 3, width: '90%', left: '5%', bottom: '5px', textAlign: 'center'}}>


																			<div style={{float: 'left', marginLeft: '30px'}}><img src={'https://togotptogo4.s3.amazonaws.com/debyoo_originals_white_shadow.png'} style={{height: '60px'}} /></div>

																			<div style={{float: 'left', marginLeft: '70px', marginTop: '5px'}}><img src={'https://togotptogo4.s3.amazonaws.com/TogotpTogo_Logo_white_flat.png'} style={{height: '50px'}} /></div>

																		 <div style={{float: 'right', marginRight: '30px', marginTop: '25px'}}><img src={'https://togotptogo4.s3.amazonaws.com/ahoefilms_white_shadow.png'} style={{height: '32px'}} /></div>

																		 <div style={{float: 'right', marginRight: '70px', marginTop: '25px'}}><img src={'https://togotptogo4.s3.amazonaws.com/telioprod_white_shadow.png'} style={{height: '32px'}} /></div>
																	</div>

						 </div>




      						 {1==2 &&
										 <div style={{background: '#000', width: '100%', textAlign:  'center'}}>
      						                    <div style={{}}>
      							                   <img src={'https://yinitj5.s3.amazonaws.com/yinitj_picture.jpg'} style={{width: '80%', maxWidth: '600px'}}/>
      								     </div>
   								      <div style={{margin: 'auto', height: '20px'}} />
      								     <div className="country_select" style={{margin: 'auto', width: '80%', maxWidth: '380px', color: '#333', textAlign: 'left', fontWeight: 'normal'}}>
      									      <div style={{margin: 'auto', height: '20px'}} />
      										<div>
      											<div style={{float: 'left', lineHeight: '25px'}}>
      											       <img src={'https://yinitj5.s3.amazonaws.com/globe_icon.png'} style={{width: '25px'}}/>
      											</div>
      											<div style={{float: 'left', marginLeft: '10px', lineHeight: '25px', fontSize: '18px',  color: '#5184bd'}}>{t('Local_yinitj')}</div>
      											 <div className="clear-both" style={{height: '0px'}}/>
      										</div>
      									       <div className="clear-both" style={{height: '10px'}}/>
      										<Select
      										   styles={customStyles}
      									           value={this.state.selectedOption}
      									           onChange={this.handleChange}
      									           options={this.state.options}
      										    placeholder={t('Select_a_country')}
      										   noOptionsMessage={() => t('No_options')}
      									         />
      								      </div>
      								      <div className="clear-both" style={{height: '20px'}}/>
      						    </div>
										}


										<div style={{background: '#fff', width: '100%', textAlign:  'center'}}>


 												 <div style={{margin: 'auto', height: '30px'}} />



												 <div style={{background: '#fff', width: '96%', margin: 'auto', textAlign:  'center'}}>

															 <div style={{float: 'left', maxWidth: '50%', marginRight: '10px'}}>
																	 <img src={'https://yinitj5.s3.amazonaws.com/yinitj_logo_transp_bar.png'} style={{height: '180px'}}/>
															 </div>

			 												 <div style={{float: 'left', marginLeft: '20px', maxWidth: '30%', display: 'table', height: '180px',}}>
															  <a href="https://www.bbluesummit.com" target="_blank">
			 														 <div style={{height: '180px', fontFamily: 'Lato', textAlign: 'left',
			 																				fontSize: '25px', display: 'table-cell', verticalAlign: 'middle'}}>
			 															 {i18n.language == "fr" &&
																		   <div>
																				    <div>en route vers</div>
																						<div>le SABB1</div>
																						<div style={{fontSize: '12px'}}>1er Sommet Annuel Belle Bleue</div>
																				</div>
																			}
																			{i18n.language == "en" &&
 																		   <div>
 																				    <div>on the road</div>
 																						<div>to the BBAS1</div>
																						<div style={{fontSize: '12px'}}>1st Beautiful Blue Annual Summit</div>
 																				</div>
 																			}
			 														 </div>
																	 </a>
			 												 </div>
			 												 <div style={{float: 'right', textAlign: 'left'}}>
			 														 <a href="https://www.bbluesummit.com" target="_blank"><img src={'https://yinitj5.s3.amazonaws.com/cop27/blue_planet.png'} style={{height: '160px'}}/></a>
			 												 </div>

			 												 <div className="clear-both" style={{height: '30px'}}/>

												 </div>
 										</div>



										{1==2 &&
											<div>

													<div style={{width: '100%', backgroundColor: '#222'}}>

																		<div className="clear-both" style={{height: '20px'}}/>

																		 <div>
																			 <iframe width={viewportWidth} height={(viewportWidth*9)/16} src="https://www.youtube.com/embed/diJfzR3tgiE?rel=0&modestbranding=0&autohide=1&showinfo=0&controls=1&autoplay=0&rel=0&showinfo=0" frameborder="0" allowfullscreen></iframe>
																		 </div>

																		<div className="clear-both" style={{height: '20px'}}/>

													</div>


													 <div className="clear-both" style={{height: '20px'}}/>


												 <div style={{width: '80%', maxWidth: '600px', margin: 'auto'}}>
													 <div style={{float: 'right', width: '35%'}}>
															 {i18n.language == "fr" &&
																<a href="/rdc/rachat">
																	<div style={{backgroundColor: '#0079ff', width: '240px', height: '70px', color: '#fff', fontSize: '25px',
																						textAlign: 'center', borderRadius: '10px', fontFamily: 'Lato', display: 'table-cell'}}><View style={{width: 240, height: 70, justifyContent: 'center'}}>Faire le don</View></div>
																</a>
																}
																{i18n.language == "en" &&
																 <a href="/rdc/rachat">
																	 <div style={{backgroundColor: '#0079ff', width: '240px', height: '70px', color: '#fff', fontSize: '25px',
																						 textAlign: 'center', borderRadius: '10px', fontFamily: 'Lato', display: 'table-cell'}}><View style={{width: 240, height: 70, justifyContent: 'center'}}>Make a donation</View></div>
																 </a>
																 }
												 </div>
													 <div style={{float: 'left', width: '62%', height: '70px', color: '#333', fontSize: '21px', textAlign: 'left', fontWeight: 'normal', fontFamily: 'Lato'}}>
																 <View style={{width: '100%', height: 70, justifyContent: 'center'}}>
																 {i18n.language == "fr" && <div>Contribuez volontairement au rachat de la RDC, Pays-Solution</div>}
																 {i18n.language == "en" && <div>Voluntarily contribute to the redemption of the DRC, Solution-Country</div>}
																 </View>
														</div>
														<div className="clear-both" style={{height: '0px'}}/>
												 </div>
												 <div className="clear-both" style={{height: '30px'}}/>

										</div>
									}




										<div style={{background: 'url(https://yinitj5.s3.amazonaws.com/youth_rdc_pc.jpg) no-repeat center top', backgroundSize: 'cover',
											position: 'relative', width: '100%', height: '460px', overflow: 'hidden', marginTop: '2px', zIndex: 0}}>

											<div style={{position: 'absolute', zIndex: 9999, top: '10%', left: '15%', textAlign: 'center', width: '70%', fontSize: '40px', color: '#fff', fontFamily: 'Lato'}}>
	 									      {i18n.language == "fr" &&
	 									        <div>
	 										       <div style={{fontSize: '42px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '2px 2px 2px #000'}}>La jeunesse congolaise se réveille et se mobilise.</div>
	 										       <div style={{fontSize: '25px', marginTop: '30px', fontFamily: 'Lato', textShadow: '2px 2px 2px #000'}}>
	 										         «La transition énergétique nous concerne tous»
	 										       </div>
	 										       <div style={{fontSize: '25px', marginTop: '2px', fontFamily: 'Lato', textShadow: '2px 2px 2px #000'}}>
	 										         «La survie de toute l'humanité dépend de notre forêt»
	 										       </div>
	 									        </div>
	 								             }
	 									      {i18n.language == "en" &&
	 									        <div>
	 										       <div style={{fontSize: '42px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '2px 2px 2px #000'}}>La jeunesse congolaise se réveille et se mobilise.</div>
	 										       <div style={{fontSize: '25px', marginTop: '30px', fontFamily: 'Lato', textShadow: '2px 2px 2px #000'}}>
	 										        «La transition énergétique nous concerne tous»
	 										       </div>
	 										       <div style={{fontSize: '25px', marginTop: '2px', fontFamily: 'Lato', textShadow: '2px 2px 2px #000'}}>
	 										         «La survie de toute l'humanité dépend de notre forêt»
	 										       </div>
	 									        </div>
	 									      }

														<div className="clear-both" style={{height: '20px'}}/>

													<a href="https://www.bbluesummit.com" target="_blank">
													 <div  style={{margin: 'auto', position: 'relative', width: '220px',  height: '50px', lineHeight: '50px',
																		 borderRadius: '30px', border: '2px solid #fff', background: 'rgba(0,0,0,1)',
																			textAlign: 'center', fontSize: '22px',  marginTop: '20px', }}>
															{i18n.language == "fr" &&  <div style={{float:'left', marginLeft: '22px', height: '50px', lineHeight: '50px',  color:'#fff', fontFamily: 'Lato'}}>En savoir plus</div>}
															{i18n.language == "en" &&  <div style={{float:'left', marginLeft: '22px', height: '50px', lineHeight: '50px',  color:'#fff', fontFamily: 'Lato'}}>Learn more</div>}
															<div style={{float:'right', marginRight: '5px', marginTop: '3px'}}><img src={'https://d3van47cb9u9tj.cloudfront.net/img/swiper_next.png'} style={{width: '44px'}}/></div>
															<div style={{clear:'both'}}></div>
													</div>
												</a>


	 								   </div>

									 </div>



										<div style={{display: 'none', width: '100%', height: '420px', margin: 'auto', marginTop: '5px', overflow: 'hidden'}}>
										 <div className="slide-container">
							        <Slide arrows={false} slidesToShow={3} defaultIndex={1}>
							         {slideImages.map((slideImage, index)=> (
							            <div className="each-slide" key={index} style={{height: '420px'}}>
							              <div style={{background: index === 1 ? `url(${slideImage.url}) no-repeat center top`: '#fff'}}>
							               {index !== 0 && index !== 1 &&
															 <div style={{position: 'absolute', zIndex: 9, bottom: '25%', width: '500px', padding: '20px', fontFamily: 'Lato', borderRadius: '10px', margin: 'auto',
																      backgroundColor: 'rgba(0,0,0,0.7)', color: '#fff',
																       fontSize: '30px', textShadow: '1px 1px 1px #000', textAlign: 'center'}}>
																  {slideImage.caption}
															</div>
														}
														{index === 0 &&
															<div style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.6)', color: '#fff',
																			fontSize: '50px', textShadow: '1px 1px 1px #000', textAlign: 'center'}}>

																			  <img src={`${slideImage.url}`} style={{width: '100%'}}/>



															</div>
														}
														{index !== 0 && index !== 1 &&
															<div style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.6)', color: '#fff',
																			fontSize: '50px', textShadow: '1px 1px 1px #000', textAlign: 'center'}}>

																			   <div style={{background: `url(${slideImage.url}) no-repeat center top`, backgroundSize: 'cover',
																				     position: 'relative', width: '100%', height: '500px', overflow: 'hidden'}}>

																					</div>



															</div>
														}
   											  	{index === 1 &&
															<div style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.6)', color: '#fff',
																			fontSize: '50px', textShadow: '1px 1px 1px #000', textAlign: 'center'}}>

																	<div style={{width: '80%', margin: 'auto', marginTop: '30px'}}>
																		<div style={{float: 'left', width: '32%', overflow: 'hidden', borderRadius: '10px'}}>
																			<img src={'https://yinitj5.s3.amazonaws.com/cop27/letter_girl_2.jpg'} style={{width: '100%', borderRadius: '10px'}}/>
																		</div>
																		<div style={{float: 'left', marginLeft: '12px', width: '32%', overflow: 'hidden', borderRadius: '10px'}}>
																			<img src={'https://yinitj5.s3.amazonaws.com/cop27/letter_girl_1.jpg'} style={{width: '100%', borderRadius: '10px'}}/>
																		</div>
																		<div style={{float: 'left', marginLeft: '12px', width: '32%', overflow: 'hidden', borderRadius: '10px'}}>
																			<img src={'https://yinitj5.s3.amazonaws.com/cop27/letter_boy.jpg'} style={{width: '100%', borderRadius: '10px'}}/>
																		</div>
																		<div className="clear-both" style={{height: '0px'}}/>
																	</div>

																	<div style={{width: '80%', margin: 'auto', marginTop: '30px',  textShadow: '1px 1px 1px #000', }}>
																		{i18n.language == "fr" &&
																			<div style={{textAlign: 'center', fontSize: '40px',  color: '#fff', fontFamily: 'Lato',}}>
																				LETTRE À MA BELLE BLEUE
																			</div>
																		}
																		{i18n.language == "en" &&
																			<div style={{textAlign: 'center', fontSize: '40px', color: '#fff', fontFamily: 'Lato',}}>
																				 LETTER TO MY BEAUTIFUL BLUE
																			</div>
																		}

																		{i18n.language == "fr" &&
																			<div style={{textAlign: 'center', fontSize: '28px', marginTop: '20px',  color: '#959f31', fontFamily: 'Lato',}}>
																				REJOIGNEZ LE MOUVEMENT
																			</div>
																		}
																		{i18n.language == "en" &&
																			<div style={{textAlign: 'center', fontSize: '28px', marginTop: '20px', color: '#959f31', fontFamily: 'Lato',}}>
																				 JOIN THE MOVEMENT
																			</div>
																		}

																		{i18n.language == "fr" &&
																			<div style={{textAlign: 'center', fontSize: '25px', marginTop: '20px',  color: '#ff', fontFamily: 'Lato',}}>
																				pour...
																			</div>
																		}
																		{i18n.language == "en" &&
																			<div style={{textAlign: 'center', fontSize: '25px', marginTop: '20px', color: '#fff', fontFamily: 'Lato',}}>
																				 to...
																			</div>
																		}
																	</div>

														  </div>
													  }
							              </div>
							            </div>
							          ))}
							        </Slide>
							      </div>
										</div>





										<div style={{position: 'relative',width: '100%', marginTop: '2px',
			                           textAlign: 'center', backgroundColor: '#000',
			                           borderTop: '4px solid #fff', borderBottom: '4px solid #fff'}}>

			                           {i18n.language == "en" &&
			                             <div style={{marginTop: '30px'}}>

			                               <div style={{width: '80%', margin: 'auto', marginTop: '10px', fontFamily: 'Lato', fontWeight: 'bold', color: '#959f31', fontSize: '27px'}}>
			                                    The Youth Initiative Jeunesse mobile application is here to:
			                               </div>

			                               <div style={{clear: 'both'}}></div>



			                               <div style={{width: '80%', margin: 'auto', marginTop: '30px', fontFamily: 'Lato', fontSize: '25px', color: '#fff'}}>
			                                    <span style={{color: '#959f31'}}>•</span> Take youth engagement in the 21st century challenges to the next level
			                               </div>
			                               <div style={{width: '80%', margin: 'auto', marginTop: '10px',fontFamily: 'Lato', color: '#fff', fontSize: '25px'}}>
			                                     <span style={{color: '#959f31'}}>•</span> Spotlight youth-led initiatives for global changes and sustainable development
			                               </div>



			                             </div>
			                           }
			                           {i18n.language == "fr" &&
			                             <div style={{marginTop: '30px'}}>

			                               <div style={{width: '80%', margin: 'auto',  marginTop: '10px', fontFamily: 'Lato', fontWeight: 'bold', color: '#959f31', fontSize: '27px'}}>
			                                     L'application mobile de la Youth Initiative Jeunesse est disponible. Elle vise à:
			                               </div>

			                               <div style={{clear: 'both'}}></div>


			                               <div style={{width: '80%', margin: 'auto', marginTop: '30px', fontFamily: 'Lato',  fontSize: '25px', color: '#fff'}}>
			                                    <span style={{color: '#959f31'}}>•</span> Faire passer l'engagement des jeunes face aux défis du 21ème siècle à un niveau supérieur
			                               </div>
			                               <div style={{width: '80%', margin: 'auto',  marginTop: '10px', fontFamily: 'Lato', color: '#fff', fontSize: '25px'}}>
			                                     <span style={{color: '#959f31'}}>•</span> Mettre en lumière les initiatives des jeunes pour les changements globaux et le développement durable
			                               </div>



			                             </div>
			                          }

			                         <div style={{width: '80%', maxWidth: '1024px', margin: 'auto', marginTop: '30px',}}><img src={'https://yinitj5.s3.amazonaws.com/cop27/5_facets_iphone_min_3-min.png'} style={{width: '100%'}}/></div>




			                         {i18n.language == "en" &&
			                         <div>
			                             <div style={{width: '80%', margin: 'auto', color: '#438aad', marginTop: '50px', fontFamily: 'Lato', fontSize: '20px'}}>
			                                Available starting mid-September
			                             </div>


			                             <div style={{width: '80%', margin: 'auto',  position: 'relative', marginTop: '40px'}}>
			                                 {i18n.language == "fr" &&
			                                     <img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download_white.png'} style={{width: '100px'}} />

			                                 }
			                                 {i18n.language == "en" &&
			                                     <img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download_white.png'} style={{width: '100px'}} />

			                                 }
			                                 &nbsp;&nbsp;&nbsp;&nbsp;

			                                  <img src={'https://d3van47cb9u9tj.cloudfront.net/google_store_download_white.png'} style={{width: '100px'}} />

			                             </div>

																	 <div style={{display: 'none', width: '80%', margin: 'auto',  position: 'relative', marginTop: '40px'}}>
			                                 {i18n.language == "fr" &&
			                                     <a href="https://apps.apple.com/fr/app/youth-initiative-jeunesse/id1641538504" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download_white.png'} style={{width: '100px'}} /></a>

			                                 }
			                                 {i18n.language == "en" &&
			                                     <a href="https://apps.apple.com/app/youth-initiative-jeunesse/id1641538504" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download_white.png'} style={{width: '100px'}} /></a>

			                                 }
			                                 &nbsp;&nbsp;&nbsp;&nbsp;

			                                   <a href="https://play.google.com/store/apps/details?id=com.youthinitiativejeunesse" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/google_store_download_white.png'} style={{width: '100px'}} /></a>

			                             </div>

			                         </div>
			                       }



			                       {i18n.language == "fr" &&
			                       <div>
			                           <div style={{width: '80%', margin: 'auto', color: '#438aad', marginTop: '50px', fontFamily: 'Lato', fontSize: '20px'}}>
			                            Disponible dès la mi-septembre
			                           </div>


			                         <div style={{width: '80%', margin: 'auto',  position: 'relative', marginTop: '40px'}}>
			                             {i18n.language == "fr" &&
			                                 <img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download_white.png'} style={{width: '100px'}} />

			                             }
			                             {i18n.language == "en" &&
			                                 <img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download_white.png'} style={{width: '100px'}} />

			                             }
			                             &nbsp;&nbsp;&nbsp;&nbsp;

			                               <img src={'https://d3van47cb9u9tj.cloudfront.net/google_store_download_white.png'} style={{width: '100px'}} />

			                         </div>

															 <div style={{display: 'none', width: '80%', margin: 'auto',  position: 'relative', marginTop: '40px'}}>
																	{i18n.language == "fr" &&
																			<a href="https://apps.apple.com/fr/app/youth-initiative-jeunesse/id1641538504" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download_white.png'} style={{width: '100px'}} /></a>

																	}
																	{i18n.language == "en" &&
																			<a href="https://apps.apple.com/app/youth-initiative-jeunesse/id1641538504" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download_white.png'} style={{width: '100px'}} /></a>

																	}
																	&nbsp;&nbsp;&nbsp;&nbsp;

																		<a href="https://play.google.com/store/apps/details?id=com.youthinitiativejeunesse" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/google_store_download_white.png'} style={{width: '100px'}} /></a>

															</div>

			                     </div>
			                   }




			                      <div style={{clear: 'both', height: '20px'}}></div>



			           </div>


										<div style={{background: 'url(https://yinitj5.s3.amazonaws.com/cop27/global_warming_2.jpg) no-repeat center top', backgroundSize: 'cover',
											position: 'relative', width: '100%', height: '460px', overflow: 'hidden', marginTop: '2px', zIndex: 0}}>

											<div style={{position: 'absolute', zIndex: 9999, top: '25%', right: '20px', textAlign: 'right', width: '65%', fontSize: '40px', color: '#fff', fontFamily: 'Lato'}}>
	 									      {i18n.language == "fr" &&
	 									        <div>
	 										       <div style={{fontSize: '40px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '2px 2px 2px #000'}}>Le changement climatique n'est pas un jeu.</div>
	 										       <div style={{fontSize: '30px', marginTop: '30px', fontFamily: 'Lato'}}>
	 										         Il se produit maintenant.
	 										       </div>
	 										       <div style={{fontSize: '30px', marginTop: '2px', fontFamily: 'Lato'}}>
	 										         Affectant chaque aspect de notre vie.
	 										       </div>
	 									        </div>
	 								             }
	 									      {i18n.language == "en" &&
	 									        <div>
	 										       <div style={{fontSize: '40px', fontWeight: 'bold', fontFamily: 'Arial', textShadow: '2px 2px 2px #000'}}>Climate change is not a game.</div>
	 										       <div style={{fontSize: '30px', marginTop: '30px', fontFamily: 'Lato'}}>
	 										         It's happening now.
	 										       </div>
	 										       <div style={{fontSize: '30px', marginTop: '2px', fontFamily: 'Lato'}}>
	 										         Affecting every aspect of human life.
	 										       </div>
	 									        </div>
	 									      }
	 								   </div>

									 </div>



									 <div style={{background: 'url(https://yinitj5.s3.amazonaws.com/cop27/girl_planting_2.jpg) no-repeat center top', backgroundSize: 'cover',
										 position: 'relative', width: '100%', height: '460px', overflow: 'hidden', marginTop: '2px', zIndex: 0}}>

													 <div style={{position: 'absolute', zIndex: 9999, top: '10%', right: '20px', textAlign: 'right', width: '55%', fontSize: '40px', color: '#111', fontFamily: 'Lato'}}>
															 {i18n.language == "fr" &&
																<div style={{fontSize: '40px', fontWeight: 'bold', fontFamily: 'Arial'}}>L'horloge du changement climatique tourne.</div>
																		}
															 {i18n.language == "en" &&
																 <div style={{fontSize: '40px', fontWeight: 'bold', fontFamily: 'Arial'}}>The climate clock is ticking.</div>
															 }
													</div>


													<div style={{position: 'absolute', zIndex: 9999, bottom: '10px', right: '20px', textAlign: 'right', width: '65%', fontSize: '40px', color: '#fff', fontFamily: 'Lato'}}>
															{i18n.language == "fr" &&
															  <div style={{fontSize: '50px', fontWeight: 'bold', marginTop: '30px', fontFamily: 'Arial', textShadow: '4px 4px 4px #000'}}>
																	 Il est temps d'agir.
																 </div>
															}
															{i18n.language == "en" &&
																<div style={{fontSize: '50px', fontWeight: 'bold', marginTop: '30px', fontFamily: 'Arial', textShadow: '4px 4px 4px #000'}}>
																	 The time to act is now.
																 </div>
															}
												 </div>

									</div>



   						        <div style={{background: 'url(https://yinitj5.s3.amazonaws.com/youth_world_pc.jpg) no-repeat center top', backgroundSize: 'cover',
   									    position: 'relative', width: '100%', height: '300px', overflow: 'hidden', marginTop: '0px', zIndex: 0}}>
   						       </div>


						        <div style={{position: 'relative', background: '#fff', width: '100%',fontFamily: 'Lato'}}>
								<div style={{position: 'relative', background: '#fff', width: '80%', margin: 'auto', paddingTop: '40px', paddingBottom: '40px', textAlign: 'center', color: '#333', fontSize: '25px'}}>
								     {i18n.language == "fr" &&
								        <div>Dédiée à la jeunesse, la plateforme «Youth Intiative Jeunesse» a pour but de créer une dynamique pour les questions liées aux jeunes dans le monde entier.</div>
								    }
								    {i18n.language == "en" &&
								          <div>Dedicated to youth, the platform «Youth Intiative Jeunesse» aims to create a dynamic for youth issues around the world.</div>
								    }
							     </div>
							 </div>


							  <div id="menu_challenges" className="element" style={{position: 'relative', background: '#fff', width: '100%', paddingBottom: '20px',fontFamily: 'Lato'}}>
								  <div style={{position: 'relative', background: '#fff', width: '80%', margin: 'auto', paddingTop: '30px', paddingBottom: '30px', textAlign: 'justify', color: '#333', fontSize: '22px', borderTop: '5px solid #ee0064', borderBottom: '5px solid #ee0064'}}>
								     {i18n.language == "fr" &&
								        <div>
								              <div style={{fontSize: '30px', fontWeight: 'bold', textAlign: 'center', color: '#ee0064'}}>Les défis...</div>
								             <div style={{marginTop: '15px', fontFamily: 'Lato'}}> Le monde change, et cela de plus en plus vite. Les défis, qu’ils soient écologiques ou humains, qui nous attendent dans les années à venir sont d'une complexité et d'une ampleur inédites. Parmi les défis écologiques, nous avons la destruction de la biodiversité, le dérèglement climatique et la pollution généralisée de notre planète. L’humanité quant à elle est aussi menacée par entre autres l’épuisement de nos ressources pétrolières, minérales, aquatiques et halieutiques. Sans oublier la pauvreté, la faim, le chômage, la maladie, les vagues d’immigration…</div>
						                      </div>
						                   }
								     {i18n.language == "en" &&
								        <div>
									     <div style={{fontSize: '30px', fontWeight: 'bold', textAlign: 'center', color: '#ee0064'}}>The challenges...</div>
								            <div style={{marginTop: '15px', fontFamily: 'Lato'}}>The world is changing, and it is changing ever faster and faster. The challenges, both ecological and human, that await us in the coming years are of unprecedented complexity and scale. Among the ecological challenges we have are the destruction of biodiversity, climate change and the widespread pollution of our planet. Humanity is also threatened by, among other things, the depletion of our oil, mineral, aquatic and fisheries resources. Not to mention poverty, hunger, unemployment, disease, waves of immigration…</div>
								         </div>
								     }
								  </div>
							 </div>


  							    <div id="menu_youth" className="element" style={{background: 'url(https://yinitj5.s3.amazonaws.com/youth_joining_hands_phone.jpg) no-repeat center top',
  									  backgroundSize: 'cover', position: 'relative', width: '100%', height: viewportHeight*0.95, overflow: 'hidden',fontFamily: 'Lato', marginTop: '0px', zIndex: 0}}>
  								   <div style={{position: 'absolute', zIndex: 9999, bottom: '20px', left: '10%', width: '80%', textAlign: 'justify', fontSize: '22px', color: '#111'}}>
  									      {i18n.language == "fr" &&
  									        <div>
  										       <div style={{fontSize: '30px', fontWeight: 'bold', color: '#111'}}>Pourquoi la jeunesse?</div>
  										       <div style={{marginTop: '10px', fontFamily: 'Lato'}}>
  										         Il est impératif de développer une nouvelle vision de notre rapport avec la Terre et un nouveau modèle social. Les jeunes, qui sont les principaux concernés par les questions liées à l’avenir du monde, ont un rôle essentiel à jouer dans le développement de cette nouvelle vision et du nouveau modèle social. Ce dernier nécessitera un effort collectif impliquant les gouvernements, le secteur privé, la société civile et la jeunesse, avec une prise d'initiatives et de responsabilité des jeunes.
  										       </div>
  										       <div style={{marginTop: '30px', fontFamily: 'Lato'}}>
  										         Par le biais de la «Youth Initiative Jeunesse», nous nous engageons à soutenir, encourager et promouvoir l'initiative des jeunes dans le monde entier.
  										       </div>
  									        </div>
  								             }
  									      {i18n.language == "en" &&
  									        <div>
  										       <div style={{fontSize: '30px', fontWeight: 'bold', color: '#111'}}>Why youth?</div>
  										       <div style={{marginTop: '10px', fontFamily: 'Lato'}}>
  										         It is imperative to develop a new vision of our relationship with the Earth and a new social model. Young people, who are the main stakeholders in issues related to the future of the world, have an essential role to play in the development of this new vision and the new social model. The latter will require a collective effort involving governments, the private sector, civil society and youth, with youth leadership and responsibility.
  										       </div>
  										       <div style={{marginTop: '30px', fontFamily: 'Lato'}}>
  										         Through the «Youth Initiative Jeunesse», we are committed to supporting, encouraging and promoting youth initiative worldwide.
  										       </div>
  									        </div>
  									      }
  								   </div>
  							   </div>


						     <div style={{position: 'relative', background: '#fff', width: '100%', paddingTop: '30px', paddingBottom: '30px',fontFamily: 'Lato'}}>
							    <div style={{position: 'relative', background: '#fff', width: '80%', margin: 'auto', paddingTop: '40px', paddingBottom: '40px', textAlign: 'justify', color: '#333', fontSize: '18px', borderTop: '5px solid #bbad0d', borderBottom: '5px solid #bbad0d'}}>
								      {i18n.language == "fr" &&
									      <div style={{color: '#bbad0d', fontSize: '30px', fontWeight: 'bold', textAlign: 'center'}}>
									        «C’est la fièvre de la jeunesse qui maintient le reste du monde à la température normale. Quand la jeunesse se refroidit, le reste du monde claque des dents»
									      </div>
								      }
								      {i18n.language == "en" &&
									      <div style={{color: '#bbad0d', fontSize: '30px', fontWeight: 'bold', textAlign: 'center'}}>
									        «The fever of youth is what maintains the rest of the world at a normal temperature. When youth grows cold, the rest of the world's teeth chatter»
									      </div>
								     }
								      <div style={{marginTop: '15px', textAlign: 'center', fontSize: '18px', fontWeight: 'normal', color: '#555', fontFamily: 'Lato'}}>
									     <b>Georges Bernanos</b>, <i> {i18n.language == "fr" && <span>Ecrivain (Févr 1888 - Juil 1948)</span>}{i18n.language == "fr" && <span>Writer (Feb 1888 - Jul 1948)</span>}</i>
									 </div>
								 </div>
						       </div>



								  <div id="menu_mission" className="element" style={{width: '100%', overflow: 'hidden', marginTop: '0px', zIndex: 0,fontFamily: 'Lato', borderTop: '1px solid #ccc', borderBottom: '1px solid #fff'}}>
								     <div  style={{background: 'url(https://yinitj5.s3.amazonaws.com/youth_our_mission.jpg) no-repeat center top', backgroundSize: 'cover', position: 'relative', width: '100%', height: viewportHeight*0.8}}>
								       <div style={{position: 'absolute', zIndex: 3, top: '0px', width: '100%'}}>
								         <div style={{textAlign: 'center', color: '#fff', fontSize: '25px', padding: '30px', textShadow: '-2px 0px #000'}}>
								            {i18n.language == "fr" &&
								           <div>
								             <div style={{fontSize: '40px', fontWeight: 'bold', marginTop: '0px'}}>Notre mission</div>
								             <div style={{marginTop: '15px'}}>
								               • Encourager et inspirer les jeunes à prendre des initiatives.
								             </div>
								             <div style={{marginTop: '10px'}}>
								               • Soutenir et accompagner les jeunes qui se donnent et qui s’engagent à créer des changements positifs en eux-mêmes, chez les autres et pour le monde.
								             </div>
								           </div>
									     }
									     {i18n.language == "en" &&
								           <div>
								             <div style={{fontSize: '40px', fontWeight: 'bold', marginTop: '10px'}}>Our mission</div>
								             <div style={{marginTop: '15px'}}>
								               • To encourage and inspire young people to take initiatives.
								             </div>
								             <div style={{marginTop: '10px'}}>
								               • To support and accompany young people who are selflessly committed to creating positive changes in themselves, in others and for the world.
								             </div>
								           </div>
									    }
								         </div>
								       </div>
								     </div>
								   </div>

								  <div style={{width: '100%', overflow: 'hidden', marginTop: '0px', zIndex: 0, borderTop: '1px solid #ccc', borderBottom: '1px solid #fff',fontFamily: 'Lato'}}>
								     <div  style={{background: 'url(https://yinitj5.s3.amazonaws.com/youth_our_vision.jpg) no-repeat center top', backgroundSize: 'cover', position: 'relative', width: '100%', height: viewportHeight*0.8}}>
								       <div style={{position: 'absolute', zIndex: 3, bottom: '20px', width: '100%'}}>
								         <div style={{textAlign: 'center', color: '#fff', fontSize: '25px', padding: '30px', textShadow: '-2px 0px #000'}}>
								            {i18n.language == "fr" &&
								           <div>
								             <div style={{fontSize: '40px', fontWeight: 'bold', marginTop: '10px'}}>Notre vision</div>
								             <div style={{marginTop: '15px'}}>
								               Mettre des outils et des moyens à disposition des jeunes afin que, de charge, la jeunesse devienne ainsi ressource.
								             </div>
								           </div>
									    }
									     {i18n.language == "en" &&
								           <div>
								             <div style={{fontSize: '40px', fontWeight: 'bold', marginTop: '10px'}}>Our vision</div>
								             <div style={{marginTop: '15px'}}>
								               Making tools and means available to young people so that, from being a liability, youth itself becomes a resource.
								             </div>
								           </div>
									     }
								         </div>
								       </div>
								     </div>
							   </div>



								 <div id="menu_scope" className="element" style={{background: 'url(https://yinitj5.s3.amazonaws.com/youth_action_field.jpg) no-repeat center top', backgroundSize: 'cover',fontFamily: 'Lato', position: 'relative', width: '100%', height: viewportHeight*0.9, overflow: 'hidden', marginTop: '0px', zIndex: 0}}>
								   <div style={{position: 'absolute', zIndex: 9999, top: '40px', left: '10%', width: '80%', textAlign: 'center', fontSize: '25px', color: '#fff', textShadow: '-1px 0px #000'}}>
								     {i18n.language == "fr" &&
								     <div>
								       <div style={{fontSize: '50px', fontWeight: 'bold', color: '#fff', textAlign: 'center'}}>Notre champ d’action</div>
								       <div style={{marginTop: '10px', lineHeight: '27px'}}>
								         Nous travaillons avec les personnes physiques et les personnes morales (universités, associations, collectifs…) dans différents secteurs, allant de l’éducation à la finance en passant par la culture, la mode, la technonogie, le sport, etc.
								       </div>
								     </div>
								     }
								     {i18n.language == "en" &&
								     <div>
								       <div style={{fontSize: '50px', fontWeight: 'bold', color: '#fff', textAlign: 'center'}}>Our scope of action</div>
								       <div style={{marginTop: '10px', lineHeight: '27px'}}>
								         We work with individuals and legal entities (universities, associations, collectives...) in different sectors, ranging from education to finance, culture, fashion, technology, sport, etc.
								       </div>
								     </div>
								     }
								   </div>
								   <div style={{position: 'absolute', zIndex: 9999, bottom: '10px', left: '10%', width: '80%', textAlign: 'center', fontSize: '25px', color: '#fff', textShadow: '-1px 0px #000'}}>
								    {i18n.language == "fr" &&
								     <div>
								       <div style={{lineHeight: '27px'}}> Nous avons à la fois une approche globale, afin de répondre aux défis de la mondialisation, et également une approche locale afin de répondre aux défis locaux tout en nous adaptant aux histoires et cultures de chaque pays.
								       </div>
								     </div>
								      }
								     {i18n.language == "en" &&
								     <div>
								       <div style={{lineHeight: '27px'}}>We have both a global approach, in order to respond to the challenges of globalization, and also a local approach, in order to respond to local challenges while adapting to the particular histories and cultures of each country.
								       </div>
								     </div>
								      }
								   </div>
							   </div>


							      <div style={{position: 'relative', background: '#fff', width: '100%', paddingTop: '30px', paddingBottom: '30px'}}>
								     <div style={{position: 'relative', background: '#fff', width: '80%', margin: 'auto', paddingTop: '40px', paddingBottom: '40px', textAlign: 'justify', color: '#333', fontSize: '20px', borderTop: '5px solid #bbad0d', borderBottom: '5px solid #bbad0d'}}>
									     {i18n.language == "fr" &&
									        <div style={{color: '#bbad0d', fontSize: '45px', fontWeight: 'bold', textAlign: 'center'}}>
									           «Les jeunes représentent l’espoir et non pas un danger»
									         </div>
									    }
									     {i18n.language == "en" &&
									        <div style={{color: '#bbad0d', fontSize: '45px', fontWeight: 'bold', textAlign: 'center'}}>
									            «Youth represent promise – not peril»
									         </div>
									     }
									     <div style={{marginTop: '15px', textAlign: 'center', fontSize: '18px', fontWeight: 'normal', color: '#555', fontFamily: 'Lato'}}>
										   <b>Ban Ki-Moon</b>, <i> {i18n.language == "fr" && <span>Secrétaire général de l'ONU (Jan 2007 - Déc 2016)</span>}{i18n.language == "en" && <span>Secretary-General of the United Nations (Jan 2007 - Dec 2016)</span>}</i>
									     </div>
									  </div>
							   </div>


 							 <div className="clear-both" style={{height: '50px',background: '#fff', }}/>

 						        <div style={{position: 'relative', background: '#fff', width: '100%', paddingTop: '30px', paddingBottom: '30px'}}>
					 							     <div style={{position: 'relative', background: '#fff', width: '80%', margin: 'auto',fontFamily: 'Lato'}}>
					 								     {i18n.language == "fr" &&
					 								        <div style={{color: '#333', fontSize: '30px', fontWeight: 'bold', textAlign: 'center'}}>
					 								                Rejoignez une des nos communautés et construisons ensemble une plateforme où les jeunes seront inspirés et vont également inspirer les autres.
					 								         </div>
					 								    }
					 								     {i18n.language == "en" &&
					 								        <div style={{color: '#333', fontSize: '30px', fontWeight: 'bold', textAlign: 'center'}}>
					 								             Join one of our communities and let's build together a platform for youths to inspire and be inspired.
					 								         </div>
					 								     }

															 {i18n.language == "fr" &&
																<div style={{width: '600px', margin: 'auto', color: '#555', fontSize: '25px', textAlign: 'center', marginTop: '50px'}}>
																			Vous pouvez adhérez gratuitement à la Youth Initiative Jeunesse uniquement via l'application mobile de la Youth Initiative Jeunesse.
																 </div>
														}
														 {i18n.language == "en" &&
																<div style={{width: '600px', margin: 'auto', color: '#555', fontSize: '25px',  textAlign: 'center', marginTop: '50px'}}>
																		 You can join for free the Youth Initiative Jeunesse only via the Youth Initiative Jeunesse mobile application
																 </div>
														 }
					 								 </div>





													<div style={{textAlign: 'center', marginTop: '30px'}}>
														 <img src={'https://yinitj5.s3.amazonaws.com/cop27/5_facets_iphone_min_3-min.png'} style={{width: '600px'}}/>
													</div>

													{i18n.language == "fr" &&
														 <div style={{width: '600px', margin: 'auto', color: '#438aad', fontSize: '20px', textAlign: 'center', marginTop: '30px',fontFamily: 'Lato'}}>
																	 Appli Mobile disponible dès la mi-septembre
															</div>
												 }
													{i18n.language == "en" &&
														 <div style={{width: '600px', margin: 'auto', color: '#438aad', fontSize: '20px',  textAlign: 'center', marginTop: '30px',fontFamily: 'Lato'}}>
																 Mobile App available starting mid-September
															</div>
													}


													<div style={{position: 'relative', width: '80%', textAlign: 'center', margin: 'auto', marginTop: '20px'}}>
																{i18n.language == "fr" &&
																		<img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download.png'} style={{width: '180px'}} />

																}
																{i18n.language == "en" &&
																	<img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download.png'} style={{width: '180px'}} />

																}
																&nbsp;&nbsp;&nbsp;&nbsp;

														   <img src={'https://d3van47cb9u9tj.cloudfront.net/google_store_download.png'} style={{width: '180px'}} />

													</div>


													<div style={{display: 'none', position: 'relative', width: '80%', textAlign: 'center', margin: 'auto', marginTop: '20px'}}>
																{i18n.language == "fr" &&
																		<a href="https://apps.apple.com/fr/app/youth-initiative-jeunesse/id1641538504" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download.png'} style={{width: '180px'}} /></a>

																}
																{i18n.language == "en" &&
																		<a href="https://apps.apple.com/app/youth-initiative-jeunesse/id1641538504" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download.png'} style={{width: '180px'}} /></a>

																}
																&nbsp;&nbsp;&nbsp;&nbsp;

														  <a href="https://play.google.com/store/apps/details?id=com.youthinitiativejeunesse" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/google_store_download.png'} style={{width: '180px'}} /></a>

													</div>

 						      </div>

 							<div className="clear-both" style={{height: '20px',background: '#fff', }}/>







						          <div className="clear-both" style={{height: '50px',background: '#fff', }}/>


							 <FooterTablet />



			        </div>
	  }
	 </Translation>
    )
  }
}


const customStyles = {
  option: (provided, state) => ({
    ...provided,
   // borderBottom: '1px dotted pink',
  //  color: state.isSelected ? 'red' : 'blue',
    paddingLeft: '25px',
	 cursor: state.isDisabled ? 'default' : 'pointer',
  }),
  groupHeading:  (provided) => ({
	  paddingBottom: '5px',
	  color: '#aaa',
	 borderBottom: '1px dotted #ddd',
	  width: '95%',
	  marginLeft: '10px'
   }),
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageTablet);
