import React, { Component, useState } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";

import Validator from "validator";

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { View, Text, Image, FlatList, ActivityIndicator} from "react-native-web";




import {Link} from "react-router-dom";
import { Fade } from 'react-slideshow-image';


import HeaderMobile from "../../../header/HeaderMobile";
import   FooterMobile from "../../../footer/FooterMobile";

import i18n from "../../../../i18n";

import {generateIPForREST} from "../../../../actions";



import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';
//isBrowser,isMobile, isTablet

const fadeImages = [
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_stories.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/Publish_SP4_06.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_feels4.png'
];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;


const CheckoutForm = (props) => {
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {

    setProcessing(true);
    props.isProcessing(true);

    event.preventDefault();

    if (elements == null) {
      return;
    }

    /*const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });*/

    stripe.createToken(elements.getElement(CardElement)).then(function(result) {

      if (result.error !== undefined)
      {
          props.handleStatus('error', result.error.message);
          setProcessing(false);
          props.isProcessing(false);


      }
      else
      {
         //alert(JSON.stringify(result.token.id) + ' /// ' + JSON.stringify(result.error))
         props.handleStatus('success', result.token.id)
      }
    });



  //  alert(JSON.stringify(token) + ' /// ' + JSON.stringify(error) + ' /// ' + JSON.stringify(paymentMethod))
  };

  return (

    <form onSubmit={handleSubmit}>
     {processing && <div style={{height: '50px', overflow: 'hidden', maxHeight: '50px'}}><ActivityIndicator animating={true} size="small" color={'#4250ca'}/></div>}
      <CardElement options={{ hidePostalCode: true }}/>
      {!processing &&
        <button type="submit" disabled={!stripe || !elements || !props.isValid}>
          {props.payLabel}
        </button>
      }
      {processing &&
        <button type="submit" disabled>
          <ActivityIndicator animating={true} size="small" color={'#fff'}/>
        </button>
     }
    </form>
  );
};

//@inject("appStore")
//@observer
class CDPageMobile extends Component {

    constructor(props) {

          super(props);



       //   console.log(">>>  store:" + JSON.stringify(store))

		//  store.dispatch("Hello World")

   	 this.state = {
        message: '',
       status: "",
       name: '',
       email: '',
       country: '',
       amount: '',
       currency: 'EUR',
       processing: false,
         window: 'initial',
           backgroundColor: "black",
          activeColor: "info",
		 currentYear: new Date().getFullYear()
   	};

     }


	 componentWillMount(){
   	   document.body.style.backgroundImage = "#fff";

	   window.addEventListener("orientationchange", function() {
	   	// Announce the new orientation number
		   window.location.reload();
	   }, false);
	 }

	 componentWillUnmount(){
    	   document.body.style.backgroundImage = null;
	 }


    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
        //   this.props.appStore.locale = lang;

	 	  localStorage.setItem("language", lang);
	 	  i18n.changeLanguage(lang);
    }


    selectPaymentMode(t) {
       this.setState({
         window: t
       });
    }


    handleStatus = (s, tok) => {
      if (s === 'success')
      {
        var ip = generateIPForREST();
        ip = 'https://api0.debyoo.com/';

        //var jwtToken = localStorage.getItem("jwtToken") || '';
        //  this.props.setLanguage(responseData.country_code);

      //  alert(tok + ' /// ' + ip)

      var _cc = "CD";

      if (localStorage.getItem('country_code') === null)
      {

      }
      else
      {
          _cc = localStorage.getItem('country_code');

      }



      fetch(ip+'process_yij_stripe_payment_api', {
                                   method: 'POST',
                                   headers: {
                                     'Accept': 'application/json',
                                     'Content-Type': 'application/json'
                                   },
                                   body: JSON.stringify({
                                     name: this.state.name,
                                     email: this.state.email,
                                     lang: i18n.locale,
                                     country: _cc,
                                     stripeToken: tok,
                                     amount: parseFloat(this.state.amount),
                                     currency: this.state.currency,
                                   })
            })
            .then((response) => response.json())
            .then((responseData) => {
                 //alert('>>> Response :: ' + JSON.stringify(responseData))
                 if (responseData.status == "success")
                 {
                     this.setState({
                       status: 'success',
                       processing: false
                     });
                 }
                 else
                 {
                   this.setState({
                     status: 'error',
                     processing: false
                   });
                 }
              })
              .catch(function(error) {
              //   alert('>>> Error :: ' + JSON.stringify(error))
            }.bind(this));
      }
      else
      {

            setTimeout(() => {
              this.setState({
                message: ''
              });
            },4000);

          this.setState({
            message: tok
          });
      }

    }

    isProcessing = (b) => {
      this.setState({
        processing: b
      });
    }

    onChange = (e) => {
      this.setState({
        name: e.target.value
      });

    }

    onChangeEmail = (e) => {
      this.setState({
        email: e.target.value
      });

    }

    onChangeAmount = (e) => {
      this.setState({
        amount: e.target.value
      });

    }

    get isValid() {
      let bool = false;
      if (this.state.name !== "" && this.state.name.length > 1 &&
          this.state.email !== "" && Validator.isEmail(this.state.email) &&
          this.state.amount.toString() !== "" && parseInt(this.state.amount) > 4)
      {
        bool = true;
      }
      return bool;
    }



  render() {
	//console.log("isMobile:" + isMobile)

  //	const { t, i18n } = useTranslation();

  /*	   const changeLanguage = lng => {
  	      i18n.changeLanguage(lng);
  	};*/

    return (
	    <Translation>
		    { t =>
		          <div>

					 <HeaderMobile store={this.props.store}/>

				  <div className="clear-both" style={{height: '72px',background: '#fff', }}/>

				    <div style={{position: 'relative', background: '#0849d9'}}>

				             <div className="clear-both" style={{height: '20px'}}/>

				          <div style={{margin: 'auto', width: '29px', height:  '29px', borderRadius: '50%', border: '1px solid #555'}}>
				                <img src={'https://s3.amazonaws.com/debyooinc20.allflagsround/cd.png'} style={{width: '30px'}} />
				          </div>

					    <div className="clear-both" style={{height: '20px'}}/>


              <div style={{width: '100%', color: '#fff', fontSize: '20px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                  {i18n.language == "fr" && <div>Faire le don volontaire pour le rachat de la RDC</div>}
                 {i18n.language == "en" && <div>Make the voluntary donation for the redemption of the DRC</div>}
               </div>

               <div className="clear-both" style={{height: '20px'}}/>
				  </div>





         {this.state.window == 'initial' &&
           <div style={{position: 'relative', width: '100%', background: '#fff'}}>

             <div className="clear-both" style={{height: '20px'}}/>


             <div style={{width: '90%', margin: 'auto', padding: '10px', backgroundColor: '#f1f1f1', color: '#777', fontSize: '13px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                 {i18n.language == "fr" && <div>La contribution au rachat peut se faire également via l’application mobile de la Youth Initiative Jeunesse (iOS et Android)</div>}
                {i18n.language == "en" && <div>La contribution au rachat peut se faire également via l’application mobile de la Youth Initiative Jeunesse (iOS et Android)</div>}
              </div>

              <div className="clear-both" style={{height: '20px'}}/>

               <div style={{width: '90%', margin: 'auto', color: '#111', fontSize: '18px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                   {i18n.language == "fr" && <div>Je souhaite effectuer le paiement</div>}
                  {i18n.language == "en" && <div>Je souhaite effectuer le paiement</div>}
                </div>

                <div className="clear-both" style={{height: '30px'}}/>

                <div onClick={this.selectPaymentMode.bind(this, 'bank')} style={{cursor: 'pointer', width: '90%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', border: '1px solid #0079ff', paddingTop: '15px', paddingBottom: '15px',
                            margin: 'auto', color: '#0079ff', fontSize: '16px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                    {i18n.language == "fr" && <div>Par virement bancaire</div>}
                    {i18n.language == "en" && <div>Par virement bancaire</div>}
                 </div>

                 <div onClick={this.selectPaymentMode.bind(this, 'card')} style={{cursor: 'pointer', width: '90%', margin: 'auto', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', border: '1px solid #0079ff',
                         borderTopWidth: 0, paddingTop: '10px', paddingBottom: '10px',
                       color: '#0079ff', fontSize: '16px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                     {i18n.language == "fr" && <div>Par carte de crédit/débit</div>}
                     {i18n.language == "en" && <div>Par carte de crédit/débit</div>}

                      <div style={{marginTop: '5px'}}><img src="https://yinitj5.s3.amazonaws.com/credit_card_icons.png" style={{width: '120px'}}/></div>

                  </div>

          </div>
        }




        {this.state.window == 'bank' &&
          <div style={{position: 'relative', width: '100%', background: '#fff'}}>

             <div className="clear-both" style={{height: '20px'}}/>

             <div style={{width: '90%', margin: 'auto', color: '#111', fontWeight: 'bold', fontSize: '20px', textAlign: 'center', fontFamily: 'Lato'}}>
                 {i18n.language == "fr" && <div>Paiement par virement bancaire</div>}
                {i18n.language == "en" && <div>Paiement par virement bancaire</div>}


              </div>


              <div className="clear-both" style={{height: '40px'}}/>


              <div style={{width: '90%', margin: 'auto', color: '#333', fontSize: '18px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                  {i18n.language == "fr" && <div>Veuillez tout d'abord effectuer le virement bancaire</div>}
                 {i18n.language == "en" && <div>Veuillez tout d'abord effectuer le virement bancaire</div>}
               </div>

               <div className="clear-both" style={{height: '40px'}}/>

               <div style={{width: '90%', margin: 'auto', color: '#555', fontSize: '16px', textAlign: 'center', fontFamily: 'Lato'}}>
                   {i18n.language == "fr" && <div><u><b style={{color: '#111'}}>Coordonnées bancaires</b></u></div>}
                  {i18n.language == "en" && <div><u><b style={{color: '#111'}}>Coordonnées bancaires</b></u></div>}

                      <div className="clear-both" style={{height: '20px'}}/>

                  {i18n.language == "fr" && <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>Titulaire</b>: Debyoo Scomm</div>}
                  {i18n.language == "en" && <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>Account owner</b>: Debyoo Scomm</div>}

                  <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>IBAN</b>: BE97 7340 5450 8349</div>

                  {i18n.language == "fr" && <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>Code BIC/SWIFT</b>: KREDBEBB</div>}
                  {i18n.language == "en" && <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>BIC/SWIFT Code</b>: KREDBEBB</div>}

                  {i18n.language == "fr" && <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>Banque</b>: KBC Bank</div>}
                  {i18n.language == "en" && <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>Bank</b>: KBC Bank</div>}

                  {i18n.language == "fr" && <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>Pays</b>: Belgique</div>}
                  {i18n.language == "en" && <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>Country</b>: Belgium</div>}

                  {i18n.language == "fr" && <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>Adresse de la compagnie</b>:</div>}
                  {i18n.language == "en" && <div style={{marginTop: '15px'}}><b style={{color: '#111'}}>Company address</b>:</div>}
                  <div style={{marginTop: '5px'}}>100A Temselaan</div>
                  <div style={{marginTop: '0px'}}>1853 Strombeek-Bever</div>
                  {i18n.language == "fr" && <div style={{marginTop: '0px'}}>Belgique</div>}
                  {i18n.language == "en" && <div style={{marginTop: '0px'}}>Belgium</div>}

                   <div className="clear-both" style={{height: '30px'}}/>

                  <div style={{marginTop: '15px', padding: '10px', margin: 'auto', backgroundColor: '#f1f1f1', width: '90%'}}><b style={{color: '#111'}}>Communication</b>: YIJ-RDC COP27</div>
                </div>

                  <div className="clear-both" style={{height: '50px'}}/>


             {1==2 &&
             <div style={{width: '800px', margin: 'auto', padding: '20px', backgroundColor: '#e2e5ec', color: '#111', fontSize: '22px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                  {i18n.language == "fr" && <div>Une fois le virement effectué, veuillez scanner ou photographier la preuve du virement bancaire et gardez-la prête. Cliquez ensuite sur le bouton ci-dessous pour enregistrer votre nom pour le Monument de Souvenir.</div>}
                 {i18n.language == "en" && <div>Une fois le virement effectué, veuillez scanner ou photographier la preuve du virement bancaire et gardez-la prête. Cliquez ensuite sur le bouton ci-dessous pour enregistrer votre nom pour le Monument de Souvenir.</div>}


                  <div className="clear-both" style={{height: '30px'}}/>


                 <a href="/rdc/memorial">
                   <div style={{cursor: 'pointer', width: '500px', borderRadius: '10px', border: '1px solid #0079ff', paddingTop: '20px', paddingBottom: '20px',
                             margin: 'auto', backgroundColor: '#0079ff', color: '#fff', fontSize: '20px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                     {i18n.language == "fr" && <div>S'enregistrer pour le Monument de Souvenir</div>}
                     {i18n.language == "en" && <div>S'enregistrer pour le Monument de Souvenir</div>}
                  </div>
                  </a>

               </div>
             }


             <div style={{width: '80%', margin: 'auto', padding: '20px', backgroundColor: '#e2e5ec', color: '#111', fontSize: '18px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                  {i18n.language == "fr" && <div>Une fois le virement effectué, scannez ou photographiez la preuve du virement bancaire et veuillez nous l'envoyer à l'adresse email suivante: <a href="mailto:rachatrdc@yinitj.com">rachatrdc@yinitj.com</a></div>}
                 {i18n.language == "en" && <div>Une fois le virement effectué, scannez ou photographiez la preuve du virement bancaire et veuillez nous l'envoyer à l'adresse email suivante: <a href="mailto:rachatrdc@yinitj.com">rachatrdc@yinitj.com</a></div>}


                  <div className="clear-both" style={{height: '30px'}}/>


                  {i18n.language == "fr" && <div>Une fois que nous aurons reçu votre preuve de paiement scannée ou photographiée, nous vous enverons votre IDENTIFIANT CONTRIBUTEUR (IDC). Conservez bien votre IDC qui vous permettra d'enregistrer votre nom dans le Monument de Souvenir.</div>}
                 {i18n.language == "en" && <div>Une fois que nous aurons reçu votre preuve de paiement scannée ou photographiée, nous vous enverons votre IDENTIFIANT CONTRIBUTEUR (IDC). Conservez bien votre IDC qui vous permettra d'enregistrer votre nom dans le Monument de Souvenir.</div>}



               </div>


                <div className="clear-both" style={{height: '100px'}}/>


               <div onClick={this.selectPaymentMode.bind(this, 'card')} style={{cursor: 'pointer', width: '90%', margin: 'auto',
                     color: '#0079ff', fontSize: '16px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                    {i18n.language == "fr" && <div>Je souhaite effectuer le paiement par carte de crédit/débit &rarr;</div>}
                    {i18n.language == "en" && <div>Je souhaite effectuer le paiement par carte de crédit/débit &rarr;</div>}
               </div>

                <div className="clear-both" style={{height: '30px'}}/>

               <div onClick={this.selectPaymentMode.bind(this, 'initial')} style={{cursor: 'pointer', width: '90%', margin: 'auto',
                     color: '#0079ff', fontSize: '16px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                    {i18n.language == "fr" && <div>Quitter</div>}
                    {i18n.language == "en" && <div>Leave</div>}
               </div>






         </div>
       }



       {this.state.window == 'card' &&
         <div style={{position: 'relative', width: '100%', background: '#fff'}}>

            <div className="clear-both" style={{height: '20px'}}/>

            <div style={{width: '90%', margin: 'auto', color: '#111', fontWeight: 'bold', fontSize: '18px', textAlign: 'center', fontFamily: 'Lato'}}>
                {i18n.language == "fr" && <div>Paiement par carte de crédit/débit</div>}
               {i18n.language == "en" && <div>Paiement par carte de crédit/débit</div>}

                <div style={{marginTop: '5px'}}><img src="https://yinitj5.s3.amazonaws.com/credit_card_icons.png" style={{width: '120px'}}/></div>
             </div>


             <div className="clear-both" style={{height: '25px'}}/>


             {this.state.status !== "success" &&
               <div style={{width: '98%', margin: 'auto', color: '#333', fontSize: '22px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                 {this.state.status === "error" &&
                   <div style={{width: '90%', marginBottom: '15px'}}>
                     <View style={{width: '100%', height: 60, justifyContent: 'center', backgroundColor: '#f53737', borderRadius: '10px'}}>
                         <Text style={{color: '#fff', textAlign: 'center', fontSize: 17}}>{'Désolé, votre paiement a échoué.'}</Text>
                     </View>
                   </div>
                 }

                 <div style={{width: '90%', margin: 'auto',}}>

                 <div style={{marginLeft: '5px'}}>
                      <View style={{height: 30, justifyContent: 'center',}}>
                           <Text style={{color: '#333', fontSize: 16, textAlign: 'left'}}>Nom du contributeur <span style={{color: 'red'}}>*</span></Text>
                      </View>
                 </div>
                 <input
                   id="name"
                   name="name"
                   style={{width: '100%', height: '30px'}}
                   value={this.state.name}
                   onChange={this.onChange}
                   autocapitalize="words"
                 />

                    <div className="clear-both" style={{height: '0px'}}/>



                   <div style={{marginLeft: '5px'}}>
                        <View style={{height: 30, justifyContent: 'center',}}>
                             <Text style={{color: '#333', fontSize: 16, textAlign: 'left'}}>Email du contributeur <span style={{color: 'red'}}>*</span></Text>
                        </View>
                   </div>
                   <input
                           type="email"
                           id="email"
                           name="email"
                           placeholder="example@example.com" style={{width: '100%', height: '30px'}}
                           value={this.state.email}
                           onChange={this.onChangeEmail}
                         />


                      <div className="clear-both" style={{height: '0px'}}/>


                      <div style={{marginLeft: '5px'}}>
                           <View style={{height: 30, justifyContent: 'center',}}>
                                <Text style={{color: '#333', fontSize: 16, textAlign: 'left'}}>Montant en EUR <span style={{color: 'red'}}>*</span></Text>
                           </View>
                      </div>

                            <input
                              id="amount"
                              name="amount"
                              placeholder="" style={{width: '100%', height: '30px'}}
                              pattern="[0-9]"
                              value={this.state.amount}
                              onChange={this.onChangeAmount}
                            />


                         <div className="clear-both" style={{height: '0px'}}/>

                 </div>

                 <div className="clear-both" style={{height: '20px'}}/>

                  <div style={{width: '355px', margin: 'auto',}}>
                     {i18n.language == "fr" && <CheckoutForm handleStatus={this.handleStatus} isProcessing={this.isProcessing} payLabel={'Payer'} isValid={this.isValid}/>}
                     {i18n.language == "en" && <CheckoutForm handleStatus={this.handleStatus} isProcessing={this.isProcessing} payLabel={'Pay'} isValid={this.isValid}/>}
                   </div>

              </div>
            }

            {this.state.status === "success" &&
                 <div style={{width: '90%', margin: 'auto', color: '#333', fontSize: '22px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                     <div style={{width: '95%', marginBottom: '15px'}}>
                       <View style={{width: '100%', justifyContent: 'center', backgroundColor: '#2EA822', borderRadius: '10px'}}>
                         <View style={{width: '100%', alignSelf: 'center', justifyContent: 'center', marginTop: 30, marginBottom: 30}}>
                           <Text style={{color: '#fff', textAlign: 'center', fontSize: 23}}>{'Votre paiement a été effectué avec succès.'}</Text>
                         </View>
                       </View>

                       <View style={{marginTop: 25}}><Text style={{color: '#2EA822', textAlign: 'center', fontSize: 18}}>{'Nous vous remercions sincèrement pour votre généreux soutien.'}</Text></View>

                       <View style={{marginTop: 25}}><Text style={{color: '#333', textAlign: 'center', fontSize: 18}}>{'Vous allez recevoir un email de confirmation avec votre IDENTIFIANT CONTRIBUTEUR (IDC).'}</Text></View>
                       <View style={{marginTop: 25}}><Text style={{color: '#333', textAlign: 'center', fontSize: 18}}>{`Conservez bien votre IDC qui, d'une part, est votre preuve de paiement et, d'autre part, vous permettra d'enregistrer votre nom dans le Monument de Souvenir.`}</Text></View>

                     </div>
                 </div>
           }





              <div className="clear-both" style={{height: '40px'}}/>




            <div className="clear-both" style={{height: '100px'}}/>

           {!this.state.processing && this.state.status !== "success" &&
             <div>
                   <div onClick={this.selectPaymentMode.bind(this, 'bank')} style={{cursor: 'pointer', width: '90%', margin: 'auto',
                         color: '#0079ff', fontSize: '18px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                        {i18n.language == "fr" && <div>Je souhaite effectuer le paiement par virement bancaire &rarr;</div>}
                        {i18n.language == "en" && <div>Je souhaite effectuer le paiement par virement bancaire &rarr;</div>}
                   </div>

                    <div className="clear-both" style={{height: '30px'}}/>

                   <div onClick={this.selectPaymentMode.bind(this, 'initial')} style={{cursor: 'pointer', width: '90%', margin: 'auto',
                         color: '#0079ff', fontSize: '18px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                        {i18n.language == "fr" && <div>Quitter</div>}
                        {i18n.language == "en" && <div>Leave</div>}
                   </div>
             </div>
            }


            {this.state.status === "success" &&
              <div>


                     <div className="clear-both" style={{height: '30px'}}/>

                    <a href="/rdc"><div style={{cursor: 'pointer', width: '90%', margin: 'auto',
                          color: '#0079ff', fontSize: '18px', textAlign: 'center', fontWeight: 'normal', fontFamily: 'Lato'}}>
                         {i18n.language == "fr" && <div>Quitter</div>}
                         {i18n.language == "en" && <div>Leave</div>}
                    </div></a>
              </div>
             }



       </div>
       }





             <div className="clear-both" style={{height: '160px',background: '#fff', }}/>





				          <div className="clear-both" style={{height: '10px',background: '#fff', }}/>


					 <FooterMobile />

		         </div>
		  }
		 </Translation>
    )
  }
}



const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setColorAction: (payload) => dispatch(setColorAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(CDPageMobile);
