import React from 'react';
import PropTypes from 'prop-types';
import {  Link, withRouter } from 'react-router-dom';
import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';
import { connect } from 'react-redux';
import {BrowserView,MobileView,TabletView, isMobileOnly, isIOS, isAndroid} from 'react-device-detect';
import { createBrowserHistory } from "history";

import { Gradient } from 'react-gradient';

import HamburgerMenu from 'react-hamburger-menu';

import { isLogin } from '../../utils';


import i18n from "../../i18n";



const history = createBrowserHistory();

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;


class HeaderMobile extends React.Component {

 constructor(props) {

       super(props);

	//  console.log("0. authenticated:" +JSON.stringify(this.props))

	 let path = this.props.history.location.pathname;

	 console.log("Path:" +JSON.stringify(path));


	 let pathBool = true;
	 if (path.match('signin') || path.match('signup') || path.match('resetpassword') ||
          path.match('home'))
	{
		pathBool = false;
	}

	 this.state = {
		 bodyPos: 0,
		open: false,
		isActive: true,
		pageName: path,
	      showMainHeader: pathBool
	};

	this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  componentDidMount() {




	setTimeout (function(){
		  console.log(">>>>Header: " +JSON.stringify(this.props.store))
	}.bind(this),1000);

	 //  console.log("checked" +checked)

 }


 handleClick() {
	 if (this.state.open)
	 {
		// var _owidth = '0%';
		 document.getElementById("myNav").style.width = "0%";
		 document.body.style.overflow = 'auto';
		 document.body.style.position = 'relative';
		 window.scrollTo(0, this.state.bodyPos);

	 }
	 else
	 {
	 	//var _owidth = '100%';
		var bodyPos  = window.pageYOffset || document.documentElement.scrollTop;
		 this.setState({
			 bodyPos: bodyPos
		 });
		 document.getElementById("myNav").style.width = "100%";
		 document.body.style.overflow = 'hidden';
		 document.body.style.position = 'fixed';
	 }

 	this.setState({
 		open: !this.state.open
 	});
 }



	handleLinkClick(event) {

	/*	setTimeout (function(){
			let path = this.props.history.location.pathname;

			 console.log(">>>2. Path:" +JSON.stringify(path));


			 let pathBool = true;
			 if (path.match('signin') || path.match('signup') || path.match('resetpassword'))
			{
				pathBool = false;
			}

			 this.setState({
				pageName: path,
			      showMainHeader: pathBool
			});
		}.bind(this), 100)

	*/

	}




    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
        //   this.props.appStore.locale = lang;

	 	  localStorage.setItem("language", lang);
	 	  i18n.changeLanguage(lang);

	   	this.setState({
	   		open: false
	   	});

	    document.getElementById("myNav").style.width = "0%";
	    document.body.style.overflow = 'auto';
	   document.body.style.position = 'relative';
	    window.scrollTo(0, this.state.bodyPos);
    }

  toggleMenu =  (e) => {
  	this.setState({
		isActive: !this.state.isActive
  	});
  }



  render() {

	//  const { t, i18n } = this.props;

    return (
         <Translation>
		{
		        t =>
			       <div>



	       		                <div className="main_header">


								<div style={{position: 'absolute', top: '2px', left: '15px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
								          <a href="/">
								                     <img src={'https://yinitj5.s3.amazonaws.com/yinitj_logo_transp_bar.png'} style={{height: '66px'}} />
								          </a>
								</div>

								<div style={{position: 'absolute', top: '25px', right: '15px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
												 <HamburgerMenu
												 	isOpen={this.state.open}
												 	menuClicked={this.handleClick.bind(this)}
												 	width={35}
												 	height={18}
												 	strokeWidth={1}
												 	rotate={0}
												 	color='black'
												 	borderRadius={0}
												 	animationDuration={0.5}
												 />
								</div>


					           </div>


						 <div id="myNav" className="mobileMenuOverlay"
								style={{height:  viewportHeight-70,background: '#fafafa', textAlign: 'center', paddingTop: '30px', lineHeight: '45px', fontSize: '15px'}}>

									    <div  style={{borderBottom: '1px solid #eee', width: '90%', margin: 'auto'}}>
										   <a href="/">
												{t('Home')}
										     </a>
									    </div>
									   <div  style={{borderBottom: '1px solid #eee', width: '90%', margin: 'auto'}}>
										   <a href="/?m=challenges">
												{t('The_challenges')}
										     </a>
									   </div>
									  <div  style={{borderBottom: '1px solid #eee', width: '90%', margin: 'auto'}}>
										    <a href="/?m=youth">
												{t('Why_youth')}
										     </a>
									  </div>
									 <div  style={{borderBottom: '1px solid #eee', width: '90%', margin: 'auto'}}>
										    <a href="/?m=mission">
												{t('Mission_and_vision')}
										     </a>
									  </div>
									  <div  style={{borderBottom: '1px solid #eee', width: '90%', margin: 'auto'}}>
										    <a href="/?m=scope">
												{t('Scope_of_action')}
										     </a>
									 </div>
									 <div  style={{borderBottom: '1px solid #eee', width: '90%', margin: 'auto'}}>
											 <a href="/support">
											 {t('Support')}
												</a>
									</div>
									 <div  style={{borderBottom: '1px solid #eee', width: '90%', margin: 'auto'}}>
											 <a href="/privacy">
											 {t('Privacy')}
												</a>
									</div>


								      <div style={{position: 'relative', width: '95px', margin: 'auto', marginTop: '40px'}}>
		   								   {i18n.language == "fr" &&
		   									 <div onClick={() => this.setLocale("en")}
		   									    style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '0px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
		   										  borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
		   										 border: '1px solid #333', color: '#333'}}>
		   								          EN
		   								      </div>
		   								  }
		   								   {i18n.language == "en" &&
		   								  <div onClick={() => this.setLocale("en")}
		   									    style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '0px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
		   										  borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
		   										 border: '1px solid #2489CE', color: '#333'}}>
		   								          EN
		   								      </div>
		   								  }
		   								   {i18n.language == "fr" &&
		   								  <div onClick={() => this.setLocale("fr")}
		   									   style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '50px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
		   									   borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
		   									  border: '1px solid #2489CE', color: '#333'}}>
		   								             FR
		   								      </div>
		   							          }
		   								   {i18n.language == "en" &&
		   									  <div onClick={() => this.setLocale("fr")}
		   									   style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '50px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
		   									   borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
		   									  border: '1px solid #333', color: '#333'}}>
		   								             FR
		   								      </div>
		   							          }
								     </div>
						 </div>

				      </div>



                  }
         </Translation>
     );
  }
}


const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setColorAction: (payload) => dispatch(setColorAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderMobile));
