import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";



import {Link} from "react-router-dom";



import {changeLanguage} from "../../../actions/changeLanguage";

import HeaderPC from "../../header/HeaderPC";
import HeaderMobile from "../../header/HeaderMobile";
import HeaderTablet from "../../header/HeaderTablet";

import FooterPC from "../../footer/FooterPC";
import FooterMobile from "../../footer/FooterMobile";
import FooterTablet from "../../footer/FooterTablet";

import i18n from "../../../i18n";

//import MetaTags from 'react-meta-tags';

import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';
//isBrowser,isMobile, isTablet

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

//@inject("appStore")
//@observer
class Covid19 extends Component {

    constructor(props) {

          super(props);



       //   console.log(">>>  store:" + JSON.stringify(store))

		//  store.dispatch("Hello World")

   	 this.state = {
		 height: 0,
		 addSpaceHeight: 100,
             backgroundColor: "black",
           activeColor: "info",
	     currentYear: new Date().getFullYear()
   	};

     }


     componentWillMount() {
	   document.body.style.backgroundColor = "#fff";

     }

	 componentWillUnmount(){
    	   document.body.style.backgroundColor = null;
	 }

	 componentDidMount() {
	     /*const height = this.divElement.clientHeight;
	      const footerHeight = this.footerElement.clientHeight;

		  console.log(">>> Div Height:" +height);
		  console.log(">>> Footer Height:" +footerHeight);
		  console.log(">>> Window Height:" +viewportHeight);
		  const addSpaceHeight = viewportHeight-height-footerHeight;
	        this.setState({ height: height, addSpaceHeight: addSpaceHeight });*/
	   }


    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
           this.props.appStore.locale = lang;
    }


	callbackHeader = (dataFromHeader) => {
		console.log("Good start but not yet there...: " + dataFromHeader);
     }


  render() {
	//console.log("isMobile:" + isMobile)

  //	const { t, i18n } = useTranslation();

  /*	   const changeLanguage = lng => {
  	      i18n.changeLanguage(lng);
  	};*/

    return (
    <Translation>
	    { t =>
	         <div>

					<BrowserView>



						       	<HeaderPC store={this.props.store}/>

                     <div className="clear-both" style={{height: '72px',background: '#fff', }}/>

           			     <div style={{position: 'relative', width: '100%', background: '#000', height: '300px',
                       background: 'url(https://yinitj5.s3.amazonaws.com/covid19_2.jpg) no-repeat center top',
                        backgroundSize: 'cover', position: 'relative',}}>

                     </div>

                     <div style={{width: '100%', backgroundColor: '#fff'}}>

             					   <div style={{margin: 'auto', height: '20px'}} />

             					    <div style={{position: 'relative', width: '1000px', margin: 'auto', textAlign: 'center', fontWeight: 'bold', fontSize: '35px', color: '#555'}}>
             						      {i18n.language == "fr" &&
             							    <div>
             							       <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}>COVID-19</div>
             							        <div style={{margin: 'auto', height: '40px'}} />
             							        <div>FACE AU CORONAVIRUS: <span style={{color: '#beae00'}}>POUR SE PROTÉGER ET PROTÉGER LES AUTRES</span></div>
             							     </div>
             							}
             						      {i18n.language == "en" &&
             							   <div>
             							       <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}>COVID-19</div>
             							        <div style={{margin: 'auto', height: '40px'}} />
             							      <div><span style={{color: '#beae00'}}>TO PROTECT YOURSELF AND OTHERS</span> FROM CORONAVIRUS</div>
             							   </div>
             							}
             					   </div>

             					   <div style={{margin: 'auto', height: '70px'}} />


                           <div style={{position: 'relative', width: '700px', margin: 'auto', textAlign: 'center', fontWeight: 'bold', fontSize: '29px', color: '#555'}}>
                               {i18n.language == "fr" &&
                               <div>
                                  <div style={{fontWeight: 'bold', fontSize: '30px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/wash_hands.png"  style={{width: '200px', height: '200px'}}/></div>
                                   <div style={{margin: 'auto', height: '20px'}} />
                                   <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Se laver très régulièrement les mains</div>
                                </div>
                                }
                               {i18n.language == "en" &&
                                    <div>
                                      <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/wash_hands.png"  style={{width: '200px', height: '200px'}}/></div>
                                       <div style={{margin: 'auto', height: '20px'}} />
                                       <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Wash your hands very often</div>
                                    </div>
                                }



                                 <div style={{margin: 'auto', height: '60px'}} />
                                 <div style={{margin: 'auto', borderTop: '5px solid #beae00', width: '300px'}} />
                                 <div style={{margin: 'auto', height: '60px'}} />



                                {i18n.language == "fr" &&
                                <div>
                                   <div style={{fontWeight: 'bold', fontSize: '30px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/coughing.png"  style={{width: '200px', height: '200px'}}/></div>
                                    <div style={{margin: 'auto', height: '20px'}} />
                                    <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Tousser ou éternuer dans son coude ou dans un mouchoir</div>
                                 </div>
                                 }
                                {i18n.language == "en" &&
                                     <div>
                                       <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/coughing.png"  style={{width: '200px', height: '200px'}}/></div>
                                        <div style={{margin: 'auto', height: '20px'}} />
                                        <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>When coughing or sneezing, cover your mouth and nose
with your arm or use a disposable tissue</div>
                                     </div>
                                 }


                                 <div style={{margin: 'auto', height: '60px'}} />
                                 <div style={{margin: 'auto', borderTop: '5px solid #beae00', width: '300px'}} />
                                 <div style={{margin: 'auto', height: '60px'}} />



                                {i18n.language == "fr" &&
                                <div>
                                   <div style={{fontWeight: 'bold', fontSize: '30px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/tissues.png"  style={{width: '200px', height: '200px'}}/></div>
                                    <div style={{margin: 'auto', height: '20px'}} />
                                    <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Utiliser un mouchoir à usage unique et le jeter</div>
                                 </div>
                                 }
                                {i18n.language == "en" &&
                                     <div>
                                       <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/tissues.png"  style={{width: '200px', height: '200px'}}/></div>
                                        <div style={{margin: 'auto', height: '20px'}} />
                                        <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Dispose of any tissues you have used</div>
                                     </div>
                                 }


                                 <div style={{margin: 'auto', height: '60px'}} />
                                 <div style={{margin: 'auto', borderTop: '5px solid #beae00', width: '300px'}} />
                                 <div style={{margin: 'auto', height: '60px'}} />



                                {i18n.language == "fr" &&
                                <div>
                                   <div style={{fontWeight: 'bold', fontSize: '30px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/greeting.png"  style={{width: '200px', height: '200px'}}/></div>
                                    <div style={{margin: 'auto', height: '20px'}} />
                                    <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Saluer sans serrer la main, éviter les embrassades</div>
                                 </div>
                                 }
                                {i18n.language == "en" &&
                                     <div>
                                       <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/greeting.png"  style={{width: '200px', height: '200px'}}/></div>
                                        <div style={{margin: 'auto', height: '20px'}} />
                                        <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Avoid close contact when greeting people</div>
                                     </div>
                                 }
                          </div>

                    </div>


                    <div className="clear-both" style={{height: '200px',background: '#fff', }}/>


         				    <FooterPC />







					</BrowserView>





					{isMobileOnly &&
						<MobileView>

                    <HeaderMobile store={this.props.store}/>

                    <div className="clear-both" style={{height: '72px',background: '#fff', }}/>

                    <div style={{position: 'relative', width: '100%', background: '#000', height: '200px',
                      background: 'url(https://yinitj5.s3.amazonaws.com/covid19_2.jpg) no-repeat center top',
                       backgroundSize: 'cover', position: 'relative',}}>

                    </div>

                    <div style={{width: '100%', backgroundColor: '#fff'}}>

                        <div style={{margin: 'auto', height: '20px'}} />

                         <div style={{position: 'relative', width: '90%', margin: 'auto', textAlign: 'center', fontWeight: 'bold', fontSize: '17px', color: '#555'}}>
                             {i18n.language == "fr" &&
                             <div>
                                <div style={{fontWeight: 'bold', fontSize: '25px', color: '#222'}}>COVID-19</div>
                                 <div style={{margin: 'auto', height: '20px'}} />
                                 <div>FACE AU CORONAVIRUS: <span style={{color: '#beae00'}}>POUR SE PROTÉGER ET PROTÉGER LES AUTRES</span></div>
                              </div>
                         }
                             {i18n.language == "en" &&
                            <div>
                                <div style={{fontWeight: 'bold', fontSize: '25px', color: '#222'}}>COVID-19</div>
                                 <div style={{margin: 'auto', height: '20px'}} />
                               <div><span style={{color: '#beae00'}}>TO PROTECT YOURSELF AND OTHERS</span> FROM CORONAVIRUS</div>
                            </div>
                         }
                        </div>

                        <div style={{margin: 'auto', height: '40px'}} />


                          <div style={{position: 'relative', width: '80%', margin: 'auto', textAlign: 'center', fontWeight: 'bold', color: '#555'}}>
                              {i18n.language == "fr" &&
                              <div>
                                 <div style={{fontWeight: 'bold', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/wash_hands.png"  style={{width: '100px', height: '100px'}}/></div>
                                  <div style={{margin: 'auto', height: '20px'}} />
                                  <div style={{fontWeight: 'bold', fontSize: '17px', color: '#333'}}>Se laver très régulièrement les mains</div>
                               </div>
                               }
                              {i18n.language == "en" &&
                                   <div>
                                     <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/wash_hands.png"  style={{width: '100px', height: '100px'}}/></div>
                                      <div style={{margin: 'auto', height: '20px'}} />
                                      <div style={{fontWeight: 'bold', fontSize: '17px', color: '#333'}}>Wash your hands very often</div>
                                   </div>
                               }



                                <div style={{margin: 'auto', height: '30px'}} />
                                <div style={{margin: 'auto', borderTop: '3px solid #beae00', width: '60%'}} />
                                <div style={{margin: 'auto', height: '30px'}} />



                               {i18n.language == "fr" &&
                               <div>
                                  <div style={{fontWeight: 'bold', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/coughing.png"  style={{width: '100px', height: '100px'}}/></div>
                                   <div style={{margin: 'auto', height: '20px'}} />
                                   <div style={{fontWeight: 'bold', fontSize: '17px', color: '#333'}}>Tousser ou éternuer dans son coude ou dans un mouchoir</div>
                                </div>
                                }
                               {i18n.language == "en" &&
                                    <div>
                                      <div style={{fontWeight: 'bold', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/coughing.png"  style={{width: '100px', height: '100px'}}/></div>
                                       <div style={{margin: 'auto', height: '20px'}} />
                                       <div style={{fontWeight: 'bold', fontSize: '17px', color: '#333'}}>When coughing or sneezing, cover your mouth and nose
      with your arm or use a disposable tissue</div>
                                    </div>
                                }


                                <div style={{margin: 'auto', height: '30px'}} />
                                <div style={{margin: 'auto', borderTop: '3px solid #beae00', width: '60%'}} />
                                <div style={{margin: 'auto', height: '30px'}} />



                               {i18n.language == "fr" &&
                               <div>
                                  <div style={{fontWeight: 'bold', fontSize: '30px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/tissues.png"  style={{width: '100px', height: '100px'}}/></div>
                                   <div style={{margin: 'auto', height: '20px'}} />
                                   <div style={{fontWeight: 'bold', fontSize: '17px', color: '#333'}}>Utiliser un mouchoir à usage unique et le jeter</div>
                                </div>
                                }
                               {i18n.language == "en" &&
                                    <div>
                                      <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/tissues.png"  style={{width: '100px', height: '100px'}}/></div>
                                       <div style={{margin: 'auto', height: '20px'}} />
                                       <div style={{fontWeight: 'bold', fontSize: '17px', color: '#333'}}>Dispose of any tissues you have used</div>
                                    </div>
                                }


                                <div style={{margin: 'auto', height: '30px'}} />
                                <div style={{margin: 'auto', borderTop: '3px solid #beae00', width: '60%'}} />
                                <div style={{margin: 'auto', height: '30px'}} />



                               {i18n.language == "fr" &&
                               <div>
                                  <div style={{fontWeight: 'bold', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/greeting.png"  style={{width: '100px', height: '100px'}}/></div>
                                   <div style={{margin: 'auto', height: '20px'}} />
                                   <div style={{fontWeight: 'bold', fontSize: '17px', color: '#333'}}>Saluer sans serrer la main, éviter les embrassades</div>
                                </div>
                                }
                               {i18n.language == "en" &&
                                    <div>
                                      <div style={{fontWeight: 'bold',  color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/greeting.png"  style={{width: '100px', height: '100px'}}/></div>
                                       <div style={{margin: 'auto', height: '20px'}} />
                                       <div style={{fontWeight: 'bold', fontSize: '17px', color: '#333'}}>Avoid close contact when greeting people</div>
                                    </div>
                                }
                         </div>

                   </div>


                   <div className="clear-both" style={{height: '100px',background: '#fff', }}/>


                   <div style={{position: 'relative', width: '90%', margin: 'auto'}}>
                      <FooterMobile />
                   </div>

					   </MobileView>
					}




					{isTablet &&
						<TabletView>

              <HeaderTablet store={this.props.store}/>

               <div className="clear-both" style={{height: '72px',background: '#fff', }}/>

               <div style={{position: 'relative', width: '100%', background: '#000', height: '300px',
                 background: 'url(https://yinitj5.s3.amazonaws.com/covid19_2.jpg) no-repeat center top',
                  backgroundSize: 'cover', position: 'relative',}}>

               </div>

               <div style={{width: '100%', backgroundColor: '#fff'}}>

                   <div style={{margin: 'auto', height: '20px'}} />

                    <div style={{position: 'relative', width: '80%', margin: 'auto', textAlign: 'center', fontWeight: 'bold', fontSize: '29px', color: '#555'}}>
                        {i18n.language == "fr" &&
                        <div>
                           <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}>COVID-19</div>
                            <div style={{margin: 'auto', height: '40px'}} />
                            <div>FACE AU CORONAVIRUS: <span style={{color: '#beae00'}}>POUR SE PROTÉGER ET PROTÉGER LES AUTRES</span></div>
                         </div>
                    }
                        {i18n.language == "en" &&
                       <div>
                           <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}>COVID-19</div>
                            <div style={{margin: 'auto', height: '40px'}} />
                          <div><span style={{color: '#beae00'}}>TO PROTECT YOURSELF AND OTHERS</span> FROM CORONAVIRUS</div>
                       </div>
                    }
                   </div>

                   <div style={{margin: 'auto', height: '70px'}} />


                     <div style={{position: 'relative', width: '70%', margin: 'auto', textAlign: 'center', fontWeight: 'bold', fontSize: '29px', color: '#555'}}>
                         {i18n.language == "fr" &&
                         <div>
                            <div style={{fontWeight: 'bold', fontSize: '30px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/wash_hands.png"  style={{width: '200px', height: '200px'}}/></div>
                             <div style={{margin: 'auto', height: '20px'}} />
                             <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Se laver très régulièrement les mains</div>
                          </div>
                          }
                         {i18n.language == "en" &&
                              <div>
                                <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/wash_hands.png"  style={{width: '200px', height: '200px'}}/></div>
                                 <div style={{margin: 'auto', height: '20px'}} />
                                 <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Wash your hands very often</div>
                              </div>
                          }



                           <div style={{margin: 'auto', height: '60px'}} />
                           <div style={{margin: 'auto', borderTop: '5px solid #beae00', width: '300px'}} />
                           <div style={{margin: 'auto', height: '60px'}} />



                          {i18n.language == "fr" &&
                          <div>
                             <div style={{fontWeight: 'bold', fontSize: '30px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/coughing.png"  style={{width: '200px', height: '200px'}}/></div>
                              <div style={{margin: 'auto', height: '20px'}} />
                              <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Tousser ou éternuer dans son coude ou dans un mouchoir</div>
                           </div>
                           }
                          {i18n.language == "en" &&
                               <div>
                                 <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/coughing.png"  style={{width: '200px', height: '200px'}}/></div>
                                  <div style={{margin: 'auto', height: '20px'}} />
                                  <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>When coughing or sneezing, cover your mouth and nose
with your arm or use a disposable tissue</div>
                               </div>
                           }


                           <div style={{margin: 'auto', height: '60px'}} />
                           <div style={{margin: 'auto', borderTop: '5px solid #beae00', width: '300px'}} />
                           <div style={{margin: 'auto', height: '60px'}} />



                          {i18n.language == "fr" &&
                          <div>
                             <div style={{fontWeight: 'bold', fontSize: '30px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/tissues.png"  style={{width: '200px', height: '200px'}}/></div>
                              <div style={{margin: 'auto', height: '20px'}} />
                              <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Utiliser un mouchoir à usage unique et le jeter</div>
                           </div>
                           }
                          {i18n.language == "en" &&
                               <div>
                                 <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/tissues.png"  style={{width: '200px', height: '200px'}}/></div>
                                  <div style={{margin: 'auto', height: '20px'}} />
                                  <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Dispose of any tissues you have used</div>
                               </div>
                           }


                           <div style={{margin: 'auto', height: '60px'}} />
                           <div style={{margin: 'auto', borderTop: '5px solid #beae00', width: '300px'}} />
                           <div style={{margin: 'auto', height: '60px'}} />



                          {i18n.language == "fr" &&
                          <div>
                             <div style={{fontWeight: 'bold', fontSize: '30px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/greeting.png"  style={{width: '200px', height: '200px'}}/></div>
                              <div style={{margin: 'auto', height: '20px'}} />
                              <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Saluer sans serrer la main, éviter les embrassades</div>
                           </div>
                           }
                          {i18n.language == "en" &&
                               <div>
                                 <div style={{fontWeight: 'bold', fontSize: '50px', color: '#222'}}><img src="https://yinitj5.s3.amazonaws.com/greeting.png"  style={{width: '200px', height: '200px'}}/></div>
                                  <div style={{margin: 'auto', height: '20px'}} />
                                  <div style={{fontWeight: 'bold', fontSize: '30px', color: '#333'}}>Avoid close contact when greeting people</div>
                               </div>
                           }
                    </div>

              </div>


              <div className="clear-both" style={{height: '200px',background: '#fff', }}/>


              <FooterTablet />

					  </TabletView>
					}

			        </div>
	  }
	 </Translation>
    )
  }
}




export default Covid19;
