import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { View, Text, Image} from "react-native-web";

import { connect } from "react-redux";


import {Link} from "react-router-dom";
import { Fade } from 'react-slideshow-image';



import HeaderTablet from "../../../header/HeaderTablet";
import   FooterTablet from "../../../footer/FooterTablet";

import i18n from "../../../../i18n";



import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';
//isBrowser,isMobile, isTablet

const fadeImages = [
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_stories.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/Publish_SP4_06.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_feels4.png'
];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

//@inject("appStore")
//@observer
class CDPageTablet extends Component {

    constructor(props) {

          super(props);



       //   console.log(">>>  store:" + JSON.stringify(store))

		//  store.dispatch("Hello World")

   	 this.state = {
           backgroundColor: "black",
          activeColor: "info",
		 width: 0,
		 ratio: 1,
		 currentYear: new Date().getFullYear()
   	};

     }


	 componentWillMount(){
   	   document.body.style.backgroundImage = "#fff";

	   window.addEventListener("orientationchange", function() {
	   	// Announce the new orientation number
		   window.location.reload();
	   }, false);
	 }



	 componentWillUnmount(){
    	   document.body.style.backgroundImage = null;
	 }

	 componentDidMount() {

		 console.log('>>>viewportWidth: ' + viewportWidth);
		 this.setState({
			 width: viewportWidth
		 })

	   }


    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
        //   this.props.appStore.locale = lang;

	 	  localStorage.setItem("language", lang);
	 	  i18n.changeLanguage(lang);
    }




  render() {
	//console.log("isMobile:" + isMobile)

  //	const { t, i18n } = useTranslation();

  /*	   const changeLanguage = lng => {
  	      i18n.changeLanguage(lng);
  	};*/

    return (
	    <Translation>
		    { t =>
		          <div>
					  <HeaderTablet store={this.props.store}/>

				   <div className="clear-both" style={{height: '72px',background: '#fff', }}/>

				   <div style={{position: 'relative', background: '#000', minHeight: '100px'}}>

				             <div className="clear-both" style={{height: '20px'}}/>

				          <div style={{margin: 'auto', width: '44px', height:  '44px', borderRadius: '50%', border: '1px solid #555'}}>
				                <img src={'https://s3.amazonaws.com/debyooinc20.allflagsround/cd.png'} style={{width: '45px'}} />
				          </div>

					    <div className="clear-both" style={{height: '35px'}}/>

					     <div style={{width: '80%', maxWidth: '600px', margin: 'auto'}}>
					               <div style={{float: 'left', width: '35%'}}>
					                 <img src={'https://yinitj5.s3.amazonaws.com/yinitj_picture.jpg'} style={{width: '100%'}} />
					             </div>
						     <div style={{float: 'right', width: '62%', color: '#fff', fontSize: '21px', textAlign: 'left', fontWeight: 'normal', marginTop: '3px', fontFamily: 'Lato'}}>
	  						       {i18n.language == "fr" && <div><span style={{color: '#beae00'}}>Youth Initiative Jeunesse RDC</span> a pour objectif de soutenir, encourager et promouvoir l'initiative des jeunes en RDC.</div>}
	  						      {i18n.language == "en" && <div><span style={{color: '#beae00'}}>Youth Initiative Jeunesse DR Congo</span> aims to support, encourage and promote youth initiative in DR Congo. </div>}
						      </div>
						      <div className="clear-both" style={{height: '0px'}}/>
					     </div>
					     <div className="clear-both" style={{height: '30px'}}/>
				 </div>

          <div className="clear-both" style={{height: '0px'}}/>



          <div style={{width: '100%', backgroundColor: '#222'}}>

                    <div className="clear-both" style={{height: '20px'}}/>

                     <div>
               		     <iframe width={viewportWidth} height={(viewportWidth*9)/16} src="https://www.youtube.com/embed/sz5X9_amntI?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1" frameborder="0" allowfullscreen></iframe>
               	     </div>

               	    <div className="clear-both" style={{height: '20px'}}/>

          </div>


           <div className="clear-both" style={{height: '20px'}}/>


         <div style={{width: '80%', maxWidth: '600px', margin: 'auto'}}>
           <div style={{float: 'right', width: '35%'}}>
               {i18n.language == "fr" &&
                <a href="/rdc/rachat">
                  <div style={{backgroundColor: '#0079ff', width: '240px', height: '70px', color: '#fff', fontSize: '25px',
                            textAlign: 'center', borderRadius: '10px', fontFamily: 'Lato', display: 'table-cell'}}><View style={{width: 240, height: 70, justifyContent: 'center'}}>Faire le don</View></div>
                </a>
                }
                {i18n.language == "en" &&
                 <a href="/rdc/rachat">
                   <div style={{backgroundColor: '#0079ff', width: '240px', height: '70px', color: '#fff', fontSize: '25px',
                             textAlign: 'center', borderRadius: '10px', fontFamily: 'Lato', display: 'table-cell'}}><View style={{width: 240, height: 70, justifyContent: 'center'}}>Make a donation</View></div>
                 </a>
                 }
         </div>
           <div style={{float: 'left', width: '62%', height: '70px', color: '#333', fontSize: '21px', textAlign: 'left', fontWeight: 'normal', fontFamily: 'Lato'}}>
                 <View style={{width: '100%', height: 70, justifyContent: 'center'}}>
                 {i18n.language == "fr" && <div>Contribuez volontairement au rachat de la RDC, Pays-Solution</div>}
                 {i18n.language == "en" && <div>Voluntarily contribute to the redemption of the DRC, Solution-Country</div>}
                 </View>
            </div>
            <div className="clear-both" style={{height: '0px'}}/>
         </div>
         <div className="clear-both" style={{height: '30px'}}/>





         <div className="clear-both" style={{height: '50px',background: '#fff', }}/>

              <div style={{position: 'relative', background: '#fff', width: '100%', paddingTop: '30px', paddingBottom: '30px'}}>
                       <div style={{position: 'relative', background: '#fff', width: '80%', margin: 'auto',fontFamily: 'Lato'}}>
                         {i18n.language == "fr" &&
                            <div style={{color: '#333', fontSize: '30px', fontWeight: 'bold', textAlign: 'center'}}>
                                    Rejoignez la Youth Initiative Jeunesse et construisons ensemble une plateforme où les jeunes seront inspirés et vont également inspirer les autres.
                             </div>
                        }
                         {i18n.language == "en" &&
                            <div style={{color: '#333', fontSize: '30px', fontWeight: 'bold', textAlign: 'center'}}>
                                 Join the Youth Initiative Jeunesse and let's build together a platform for youths to inspire and be inspired.
                             </div>
                         }

                         {i18n.language == "fr" &&
                          <div style={{width: '600px', margin: 'auto', color: '#555', fontSize: '25px', textAlign: 'center', marginTop: '50px'}}>
                                Vous pouvez adhérez gratuitement à la Youth Initiative Jeunesse uniquement via l'application mobile de la Youth Initiative Jeunesse.
                           </div>
                      }
                       {i18n.language == "en" &&
                          <div style={{width: '600px', margin: 'auto', color: '#555', fontSize: '25px',  textAlign: 'center', marginTop: '50px'}}>
                               You can join for free the Youth Initiative Jeunesse only via the Youth Initiative Jeunesse mobile application
                           </div>
                       }
                     </div>





                    <div style={{textAlign: 'center', marginTop: '30px'}}>
                       <img src={'https://yinitj5.s3.amazonaws.com/cop27/5_facets_iphone_min_3-min.png'} style={{width: '600px'}}/>
                    </div>

                    {i18n.language == "fr" && 1==2 &&
                       <div style={{width: '600px', margin: 'auto', color: '#438aad', fontSize: '20px', textAlign: 'center', marginTop: '30px',fontFamily: 'Lato'}}>
                             Appli Mobile disponible dès la mi-septembre
                        </div>
                   }
                    {i18n.language == "en" && 1==2 &&
                       <div style={{width: '600px', margin: 'auto', color: '#438aad', fontSize: '20px',  textAlign: 'center', marginTop: '30px',fontFamily: 'Lato'}}>
                           Mobile App available starting mid-September
                        </div>
                    }


                    <div style={{position: 'relative', width: '80%', textAlign: 'center', margin: 'auto', marginTop: '20px'}}>
                    {i18n.language == "fr" &&
                        <a href="https://apps.apple.com/fr/app/youth-initiative-jeunesse/id1641538504" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download.png'} style={{width: '180px'}} /></a>

                    }
                    {i18n.language == "en" &&
                        <a href="https://apps.apple.com/app/youth-initiative-jeunesse/id1641538504" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/apple_store_download.png'} style={{width: '180px'}} /></a>

                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;

                        <a href="https://play.google.com/store/apps/details?id=com.youthinitiativejeunesse" target="_blank"><img src={'https://d3van47cb9u9tj.cloudfront.net/google_store_download.png'} style={{width: '180px'}} /></a>

                    </div>

            </div>

        <div className="clear-both" style={{height: '20px',background: '#fff', }}/>







                <div className="clear-both" style={{height: '50px',background: '#fff', }}/>


					 <FooterTablet />

		         </div>
		  }
		 </Translation>
    )
  }
}



const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setColorAction: (payload) => dispatch(setColorAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(CDPageTablet);
