import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";


import {Link} from "react-router-dom";
import { Fade } from 'react-slideshow-image';



import HeaderTablet from "../../../header/HeaderTablet";
import   FooterTablet from "../../../footer/FooterTablet";

import i18n from "../../../../i18n";



import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';
//isBrowser,isMobile, isTablet

const fadeImages = [
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_stories.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/Publish_SP4_06.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_feels4.png'
];
 
const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true, 
  arrows: false,
  indicators: false
}

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

//@inject("appStore")
//@observer
class NGPageTablet extends Component {
	
    constructor(props) {
		
          super(props);
	   
   	  
		  
       //   console.log(">>>  store:" + JSON.stringify(store))
		  
		//  store.dispatch("Hello World")
			
   	 this.state = {
           backgroundColor: "black",
          activeColor: "info",
		 width: 0,
		 ratio: 1,
		 currentYear: new Date().getFullYear()
   	};
	
     }
	 
	 
	 componentWillMount(){
   	   document.body.style.backgroundImage = "#fff";
	   
	   window.addEventListener("orientationchange", function() {
	   	// Announce the new orientation number
		   window.location.reload();
	   }, false);
	 }
	 

	 
	 componentWillUnmount(){
    	   document.body.style.backgroundImage = null;
	 }
	 
	 componentDidMount() {
	    
		 console.log('>>>viewportWidth: ' + viewportWidth);
		 this.setState({
			 width: viewportWidth
		 })
	
	   }
	 
	 
    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
        //   this.props.appStore.locale = lang;
		   
	 	  localStorage.setItem("language", lang);
	 	  i18n.changeLanguage(lang);
    }
	
	
	
	
  render() {
	//console.log("isMobile:" + isMobile)
	  
  //	const { t, i18n } = useTranslation();

  /*	   const changeLanguage = lng => {
  	      i18n.changeLanguage(lng);
  	};*/
	  
    return (
	    <Translation>
		    { t =>
		          <div>        		
					  <HeaderTablet store={this.props.store}/>

				   <div className="clear-both" style={{height: '72px',background: '#fff', }}/>

				   <div style={{position: 'relative', background: '#000', minHeight: '100px'}}>

				             <div className="clear-both" style={{height: '20px'}}/>

				          <div style={{margin: 'auto', width: '44px', height:  '44px', borderRadius: '50%', border: '1px solid #555'}}>
				                <img src={'https://s3.amazonaws.com/debyooinc20.allflagsround/ng.png'} style={{width: '45px'}} />
				          </div>

					    <div className="clear-both" style={{height: '35px'}}/>
				
					     <div style={{width: '80%', maxWidth: '600px', margin: 'auto'}}>
					               <div style={{float: 'left', width: '35%'}}>
					                 <img src={'https://yinitj5.s3.amazonaws.com/yinitj_picture.jpg'} style={{width: '100%'}} />
					             </div>
						     <div style={{float: 'right', width: '62%', color: '#fff', fontSize: '21px', textAlign: 'left', fontWeight: 'normal', marginTop: '3px'}}>
	  						       {i18n.language == "fr" && <div><span style={{color: '#beae00'}}>Youth Initiative Jeunesse Nigéria</span> a pour objectif de soutenir, encourager et promouvoir l'initiative des jeunes au Nigéria.</div>}  
	  						      {i18n.language == "en" && <div><span style={{color: '#beae00'}}>Youth Initiative Jeunesse Nigeria</span> aims to support, encourage and promote youth initiative in Nigeria. </div>}    
						      </div>
						      <div className="clear-both" style={{height: '0px'}}/>
					     </div>
					     <div className="clear-both" style={{height: '30px'}}/>
				 </div>
									  
									  
	  			  {1==2 &&	
					  <div style={{width: '100%', backgroundColor: '#fff'}}> 	
							  
	  					   <div style={{margin: 'auto', height: '20px'}} />	
				
	  					    <div style={{position: 'relative', width: '80%', maxWidth: '600px', margin: 'auto', textAlign: 'center', fontWeight: 'bold', fontSize: '27px', color: '#555'}}>
	  						      {i18n.language == "fr" &&
	  							    <div>
	  							       <div style={{fontWeight: 'bold', fontSize: '40px', color: '#222'}}>Rejoindre la communauté</div>
	  							        <div style={{margin: 'auto', height: '40px'}} />	
	  							        <div>Vous pouvez rejoindre la communauté de Youth Initiative Jeunesse Togo en tant que <span style={{color: '#beae00'}}>rêveur</span>, <span style={{color: '#beae00'}}>initiateur</span> ou <span style={{color: '#beae00'}}>sage</span>.</div>
	  							     </div>
	  							}
	  						      {i18n.language == "en" &&
	  							   <div>
	  							       <div style={{fontWeight: 'bold', fontSize: '40px', color: '#222'}}>Join the community</div>
	  							        <div style={{margin: 'auto', height: '40px'}} />
	  							      <div>You can join the Youth Initiative Jeunesse Togo's community as a <span style={{color: '#beae00'}}>dreamer</span>, an <span style={{color: '#beae00'}}>initiator</span> or an <span style={{color: '#beae00'}}>elder</span>.</div>
	  							   </div>
	  							}
	  					   </div>
							
	  					   <div style={{margin: 'auto', height: '40px'}} />
								
							
						
		                               <div style={{position: 'relative', width: '96%', margin: 'auto'}}>	

				                    <div style={{float: 'left', width: '32%', textAlign: 'justify', fontSize: '16px', color: '#333'}}>
									        <div style={{textAlign: 'center', fontSize: '25px', color: '#beae00', fontWeight: 'bold'}}>{t('A_dreamer')}</div>
									        <div className="clear-both" style={{height: '6px', width: '100%', borderBottom: '1px solid #ccc'}}/>

									          <div style={{margin: 'auto', height: '20px'}} />

									        {i18n.language == "fr" &&
									            <div style={{textAlign: 'left', fontSize: '18px', color: '#555'}}>
									              Vous êtes une jeune personne? Vous pouvez rejoindre la communauté en tant que rêveur: <br/>· Si vous avez un rêve et souhaitez passer du rêve à l'initiative<br/>· Ou si vos rêves ont été enterrés, brisés ou anéantis, et que vous voulez les ranimer<br/>· Ou si vous voulez être inspiré pour construire votre rêve. <br/><br/>
									              Appuyez sur le bouton «Je suis un rêveur» pour rejoindre la communauté.
									          </div>
								               }
									        {i18n.language == "en" &&
									            <div style={{textAlign: 'left', fontSize: '18px', color: '#555'}}>
									              You’re a young person? You can join the community as a dreamer: <br/>· If you have a dream and want to move from dream to initiative<br/>· Or if your dreams have been buried, bruised or shattered, and want to awaken them<br/>· Or if you want to be inspired to build your dream. <br/><br/>
									              Tap the «I'm a dreamer» button to join the community.
									          </div>
								               }
								    </div>
								    <div style={{float: 'left', marginLeft: '2%', width: '32%',  textAlign: 'justify', fontSize: '16px', color: '#333'}}>
									        <div style={{textAlign: 'center', fontSize: '25px', color: '#beae00', fontWeight: 'bold'}}>{t('An_initiator')}</div>
									        <div className="clear-both" style={{height: '6px', width: '100%', borderBottom: '1px solid #ccc'}}/>

									          <div style={{margin: 'auto', height: '20px'}} />

									         {i18n.language == "fr" &&
									           <div style={{textAlign: 'justify', fontSize: '18px', color: '#555'}}>
									              Un initiateur est un jeune qui a déjà initié quelque chose (une entreprise, un événement, une organisation, un produit, une invention, etc.).  <br/><br/>Vous souhaitez partager votre histoire? Ou Vous avez besoin de plus d’impact, de visibilité, de ressources, de connexions, d'un mentor ou de quoi que ce soit pour accomplir votre mission? Appuyez sur le bouton «Je suis un initiateur» pour rejoindre la communauté.
									            </div>
										   }
									         {i18n.language == "en" &&
									           <div style={{textAlign: 'justify', fontSize: '18px', color: '#555'}}>
									             An initiator is a young person who has already initiated something (a company, an event, an organization, a product,  an invention etc.).  <br/><br/>You want to share your story? Or  You need more impact, visibility, resources, connection, mentorships or anything to fulfill your mission? Tap the «I'm an initiator» button to join the community.
									            </div>
										   }
								    </div>
								    <div style={{ float: 'right', width: '32%',  textAlign: 'justify', fontSize: '16px', color: '#333'}}>
									        <div style={{textAlign: 'center', fontSize: '25px', color: '#beae00', fontWeight: 'bold'}}>{t('An_elder')}</div>
									        <div className="clear-both" style={{height: '6px', width: '100%', borderBottom: '1px solid #ccc'}}/>

									          <div style={{margin: 'auto', height: '20px'}} />
									         {i18n.language == "fr" &&
									             <div style={{textAlign: 'justify', fontSize: '18px', color: '#555'}}>
									                Un sage est une personne qui a vécu toutes sortes d’expériences et qui veut partager ses leçons de vie et ses expériences avec les jeunes. <br/><br/>Si vous avez des histoires pleines de courage, de bonheur, de tristesse et même de colère, et que vous souhaitez partager ces histoires merveilleuses et votre sagesse avec les jeunes, veuillez appuyer sur le bouton «Je suis un sage» pour rejoindre la communauté.
									           </div>
									         }
									         {i18n.language == "en" &&
									             <div style={{textAlign: 'justify', fontSize: '18px', color: '#555'}}>
									               An elder is a person who has lived all kinds of experiences and want to share their life lessons and experiences with youth. <br/><br/>If you have stories full of bravery, happiness, sadness, and even anger, and want to share those wonderful stories and your wisdom to youth, please tap the «I'm an elder» button to join the community.
									           </div>
									         }
								    </div>


								     <div className="clear-both" style={{height: '30px'}}/>


								    <div style={{float: 'left', width: '32%', height: '60px', lineHeight: '60px', borderRadius: '30px',
										        textAlign: 'center', fontSize: '18px', backgroundColor: '#0857da', color: '#fff', border: '1px solid #31579d'}}>
									        {t('Im_a_dreamer')}
								    </div>
								    <div style={{float: 'left', marginLeft: '2%', width: '32%', height: '60px', lineHeight: '60px', borderRadius: '30px',
										        textAlign: 'center', fontSize: '18px', backgroundColor: '#0857da', color: '#fff', border: '1px solid #31579d'}}>
									        {t('Im_an_initiator')}
								    </div>
								    <div style={{float: 'right', width: '32%', height: '60px', lineHeight: '60px', borderRadius: '30px',
										        textAlign: 'center', fontSize: '18px', backgroundColor: '#0857da', color: '#fff', border: '1px solid #31579d'}}>
									        {t('Im_an_elder')}
								    </div>

								    <div className="clear-both" style={{height: '0px'}}/>
	   
						 </div>
						 	
				    </div>
				 }
								  
									  
									  
  			          <div className="clear-both" style={{height: '20px',background: '#fff', }}/>	
									  
									
							
  				   {1==2 &&
					  <div style={{width: '100%', backgroundColor: '#fff'}}> 
  						{i18n.language == "fr" &&	
  						    <div style={{position: 'relative', float:'left', background: 'url(https://yinitj5.s3.amazonaws.com/inspire_dreamers_fr.jpg) no-repeat center center',   backgroundSize: 'cover',
  							            width: (viewportWidth/2)-1, backgroundColor: '#f5f5f7', height: (((viewportWidth/2)-1)/1.46)+'px'}}>			
  						    </div>
  					        }
  						{i18n.language == "en" &&	
  						    <div style={{position: 'relative', float:'left', background: 'url(https://yinitj5.s3.amazonaws.com/inspire_dreamers_en.jpg) no-repeat center center',   backgroundSize: 'cover',
  							             width: (viewportWidth/2)-1, backgroundColor: '#f5f5f7', height: (((viewportWidth/2)-1)/1.46)+'px'}}>			
  						    </div>
  					        }
  						{i18n.language == "fr" &&	
  						   <div style={{position: 'relative', float:'right', background: 'url(https://yinitj5.s3.amazonaws.com/elder_fr.jpg) no-repeat center center',   backgroundSize: 'cover',
  							            width: (viewportWidth/2)-1, backgroundColor: '#f5f5f7', height: (((viewportWidth/2)-1)/1.46)+'px'}}>			
  						   </div>	
  						  }
  						{i18n.language == "en" &&	
  						   <div style={{position: 'relative', float:'right', background: 'url(https://yinitj5.s3.amazonaws.com/elder_en.jpg) no-repeat center center',   backgroundSize: 'cover',
  							             width: (viewportWidth/2)-1, backgroundColor: '#f5f5f7', height: (((viewportWidth/2)-1)/1.46)+'px'}}>			
  						   </div>	
  						  }
  						<div className="clear-both" style={{height: '0px'}}/>		
  				          </div>
					  }
									  
					
				  <div style={{width: '100%', backgroundColor: '#fff'}}> 	

					    <div style={{margin: 'auto', height: '0px'}} />
					     <div style={{position: 'relative', width: '80%', margin: 'auto', textAlign: 'center', fontSize: '23px', color: '#333'}}>	


			  			      {i18n.language == "fr" &&
			  				    <div>
			  				       <div style={{fontWeight: 'bold', fontSize: '40px', color: '#222'}}>Rejoindre la communauté</div>
			  				        <div style={{margin: 'auto', height: '80px'}} />	
			  				        <div>Vous aurez bientôt la possibilité de rejoindre Youth Initiative Jeunesse Nigéria.</div>
			  				     </div>
			  				}
			  			      {i18n.language == "en" &&
			  				   <div>
			  				       <div style={{fontWeight: 'bold', fontSize: '40px', color: '#222'}}>Join the community</div>
			  				        <div style={{margin: 'auto', height: '80px'}} />
			  				      <div>You will soon be able to join Youth Initiative Jeunesse Nigeria.</div>
			  				   </div>
			  				}

					    </div>
					   <div style={{margin: 'auto', height: '200px'}} />

				 </div>
					
				          <div className="clear-both" style={{height: '10px',background: '#fff', }}/>	
							  
							  
					 <FooterTablet />				  
								  						  
		         </div>
		  }
		 </Translation>
    )
  }
}



const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setColorAction: (payload) => dispatch(setColorAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(NGPageTablet);


