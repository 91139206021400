import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";


import {Link} from "react-router-dom";
import { Fade } from 'react-slideshow-image';

import { Gradient } from 'react-gradient';

import Spinner from 'react-activity/lib/Spinner';
import 'react-activity/lib/Spinner/Spinner.css';

import HeaderTablet from "../../../header/HeaderTablet";
import   FooterTablet from "../../../footer/FooterTablet";

import i18n from "../../../../i18n";
//import {changeLanguage} from "../../../actions/changeLanguage";

import PubSub from 'pubsub-js';


import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';
//isBrowser,isMobile, isTablet

const fadeImages = [
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_stories.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/Publish_SP4_06.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_feels4.png'
];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;


class Checkbox extends React.Component {

  static propTypes = {
    hasError: PropTypes.bool,
 //   id: PropTypes.string.isRequired,
    indeterminate: PropTypes.bool,
  //  label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['default', 'switch']),
  };
  static defaultProps = {
    hasError: false,
    indeterminate: undefined,
    type: 'default',
  };

  componentDidMount() {
    // Apply the indeterminate attribute of the checkbox input
    this.selector.indeterminate = this.props.indeterminate;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.selector.indeterminate = this.props.indeterminate;
    }
  }

  render() {
    const { id, label, type, indeterminate, hasError, ...inputProps } = this.props;
    const checkboxClassname = `
      m-checkbox
      ${type === 'switch' && 'm-checkbox--switch'}
      ${hasError && 'm-checkbox--has-error'}
    `;

    const inputClassname = `
      m-checkbox__input
      ${type === 'switch' && 'm-checkbox--switch__input'}
      ${hasError && 'm-checkbox--has-error__input'}
    `;

    const labelClassname = `
      m-checkbox__label
      ${type === 'switch' && 'm-checkbox--switch__label'}
    `;

    return (
      <div className={checkboxClassname}>
        <input style={{width:20, height:20}}
          type="checkbox"
          className={inputClassname}
          ref={el => (this.selector = el)}
          id={id}
          {...inputProps}
        />
          <label className={labelClassname} htmlFor={id}>{label}</label>
      </div>
    );
  }
}


export const generateIPForREST = () => {


    var numb = Math.floor(Math.random() * 3);

    if (numb == 2)
    {
      var  ip = 'https://api2.debyoo.com/';
    }
    else if (numb == 1)
    {
      var  ip = 'https://api1.debyoo.com/';
    }
    else
    {
      var  ip = 'https://api0.debyoo.com/';
    }

    console.log("IP: " + ip);


   return ip;
}



class TGPageTablet extends Component {

    constructor(props) {

	          super(props);



	       //   console.log(">>>  store:" + JSON.stringify(store))

			//  store.dispatch("Hello World")

	   	 this.state = {
         displayPopUp: false,
			  posY: 0,
	           backgroundColor: "black",
	          activeColor: "info",
		    showSUPswd: false,
			  showSIPswd: false,
			  showNPPswd: false,
			 resetPasswordStatus: "",
		 emailRP: '',
		 passwordRP: '',
		 pcodeRP: '',
		 new_password: '',
		 reset_password_token: '',
		 showCodePopMessager: 'block',
              showPasswordError: 'none',
              showPasswordSuccess: 'none',
	      showEmailInvalidError: 'none',
		 showPasswordCodeError: 'none',
		 showPasswordError: 'none',
		 showPasswordTooShortError: 'none',
		 displayPCodeVerif: false,
		 displayPCodeResend: false,
              displaySignButtons: true,
		 displayNewPasswordWrapper: false,
		 displayPCodeWrapper: false,
		 displayNewPassword: false,
			 displayResetPassword: false,
			 isLoggedIn: false,
			 processStatus: '',
			 user_name: '',
			 uid: null,
			 typef: '',
			 livingPlace: 'Togo',
			 emailSignin: '',
			 passwordSignin: '',
	              showSigninError: 'none',
	            displaySignButtons: true,
		  name: '',
		 email: '',
		 password: '',
			 whatsapp: '',
             showNameError: 'none',
		showEmailTakenError: 'none',
		showEmailInvalidError: 'none',
		showPasswordError: 'none',
		showPasswordTooShortError: 'none',
		showTermsError: 'none',
		 agreedTerms: false,
			 displaySignPopUp: false,
			  displaySigninButtons: true,
			  displaySignupButtons: true,
			 currentYear: new Date().getFullYear()
	   	};

		this.handleChangeEmailSignin = this.handleChangeEmailSignin.bind(this);
		this.handleChangePasswordSignin = this.handleChangePasswordSignin.bind(this);

	         this.onSubmitSignin = this.onSubmitSignin.bind(this);


	         this.handleChangeName= this.handleChangeName.bind(this);
	  	this.handleChangeEmail = this.handleChangeEmail.bind(this);
	  	this.handleChangePassword = this.handleChangePassword.bind(this);
	  //	this.handleChangeAgreedTerms = this.handleChangeAgreedTerms(this);

	  	   this.onSubmit = this.onSubmit.bind(this);

		   this.handleChangeWhatsapp = this.handleChangeWhatsapp.bind(this);



		   this.handleChangeEmailRP = this.handleChangeEmailRP.bind(this);
	          this.handleChangePCode = this.handleChangePCode.bind(this);
		   this.onResendPCode = this.onResendPCode.bind(this);
		   this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this);
		 // this.handleOnInput = this.handleOnInput.bind(this);
	    	   this.onSubmitRP = this.onSubmitRP.bind(this);
		   this.onSubmitPCode = this.onSubmitPCode.bind(this);
		   this.onSubmitNewPassword  = this.onSubmitNewPassword.bind(this);

     }


	 componentWillMount(){
   	   document.body.style.backgroundImage = "#fff";
	 }

	 componentWillUnmount(){
    	   document.body.style.backgroundImage = null;
	 }


     isEmail(email) {
        return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
     }


     async onSubmitSignin(event) {

		// console.log(">>> this.props: " + JSON.stringify(this.props.userConnection.userConnection))
           event.preventDefault();

           this.setState({
             displaySignButtons: false,
		displaySignupButtons: false,
           });

 	   var isValid = true;
 	   isValid = isValid && this.isEmail(this.state.emailSignin);

 	   if (!isValid)
 	   {
 		           this.setState({showSigninError: 'block', displaySignButtons: true, displaySignupButtons: true});
 		           setTimeout(function(){
 		             this.setState({showSigninError: 'none'});
 		           }.bind(this),4000);
 	   }
 	   else
 	  {
    	         var isPasswordValid = this.state.passwordSignin.length > 0 ? true : false;
    	         isValid = isValid && isPasswordValid;

 		  if (!isValid)
 		  {
 	              this.setState({showSigninError: 'block', displaySignButtons: true, displaySignupButtons: true});
 	              setTimeout(function(){
 	                this.setState({showSigninError: 'none'});
 	              }.bind(this),4000);
 		  }
 		  else
 		  {
 			 this.confirmOnSubmitSignin();
			   console.log("Submit sign in");
 		  }
 	  }

   }

   async confirmOnSubmitSignin() {


           try {
                console.log("submit");

               // var ip = 'https://www.debyoo.com/';

               //  var ip = 'http://localhost:3000/';

               var ip = generateIPForREST();

                let response = await fetch(ip+'signin_app_api', {
                                        method: 'POST',
                                        headers: {
                                          'Accept': 'application/json',
                                          'Content-Type': 'application/json',
                                        },
                                        credentials: 'include',
                                        body: JSON.stringify({
                                          user:{
                                            email: this.state.emailSignin,
                                            password: this.state.passwordSignin,
                                          }
                                        })
                                      });
                let res = await response.json();

                if (response.status >= 200 && response.status < 300) {

                          if (res.status == "100")
                          {
                                 console.log(">>> 100 HERE >>> " + JSON.stringify(res));

                                /* this.reactivateStep = 1;
                                  this.responseData = res;

                                 this.setState({
                                   virtualjobvar: 'a',
                                   global_account_active_status: res.global_account_active_status
                                 });*/
                          }
                           else if (res.status == "200")
                           {

                                  console.log(">>> 200 HERE >>> " + JSON.stringify(res));

					this.setState({
						isLoggedIn: true,
						 uid: res.uid,
			                      processStatus: '',
			                       user_name: res.name,
						displaySignButtons: true,
						displaySignupButtons: true
					});

					if (res.already_joined_yinitj == "1")
					{
				            this.setState({
				 		 processStatus: 'exists',
				                displaySignButtons: false
				             });

				 	     setTimeout (function(){
				 			 this.cancelConfirm();
				 	     }.bind(this), 5000);
					}



                          }
                          else
                          {
                             console.log(">>> Other HERE >>> " + JSON.stringify(res));
                             console.log(">>> response >>> " + JSON.stringify(response));
                            this.setState({showSigninError: 'block', displaySignButtons: true, displaySignupButtons: true});
                            setTimeout(function(){
                              this.setState({showSigninError: 'none'});
                            }.bind(this),4000);

                            //this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                             //  showSignUpButton: 'flex',});
                          }

                } else {
                           console.log("2.res: " + JSON.stringify(res));
                           console.log("2.response: " + JSON.stringify(response.status));
                                //Handle error
                               // let error = res;
                               // throw error;
                          this.setState({showSigninError: 'block', displaySignButtons: true, displaySignupButtons: true});
                          setTimeout(function(){
                            this.setState({showSigninError: 'none'});
                          }.bind(this),4000);

                         // this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                          //showSignUpButton: 'flex',});
                }



           } catch(error) {
                  console.log("error: " + JSON.stringify(error));

                  this.setState({showSigninError: 'block', displaySignButtons: true});
                  setTimeout(function(){
                    this.setState({showSigninError: 'none'});
                  }.bind(this),4000);
           }


      }



	  onSubmit(event) {
	         event.preventDefault();

	         this.setState({
	           displaySignButtons: false,
		    displaySigninButtons: false,
	         });



	 	var cb = document.getElementById("termsCB").checked;

	 	var isValid =  true;
	 	isValid = isValid && cb;

	 	if (!isValid)
	 	{
	             this.setState({
	 		 showTermsError: 'block',
	 		 displaySignButtons: true,
			  displaySigninButtons: true,
	            });

	 	       setTimeout (function(){
	 	               this.setState({
	 	   		     showTermsError: 'none'
	 	              });
	 	       }.bind(this),3000);
	 	}
	 	else
	 	{
	 		var isNameValid = this.state.name.length > 0 ? true : false;
	 		isValid = isValid && isNameValid;

	 		if (!isValid)
	 		{
	 		            this.setState({
	 				  showNameError: 'block',
	 				 displaySignButtons: true,
					   displaySigninButtons: true,
	 		           });

	 			       setTimeout (function(){
	 			               this.setState({
	 			   		     showNameError: 'none'
	 			              });
	 			       }.bind(this),3000);
	 		}
	 		else
	 		{

	 			var isEmailValid = this.isEmail(this.state.email);
	 			isValid = isValid && isEmailValid;

	 			if (!isValid)
	 			{
	 			            this.setState({
	 					  showEmailInvalidError: 'block',
	 					 displaySignButtons: true,
						  displaySigninButtons: true,
	 			           });

	 				       setTimeout (function(){
	 				               this.setState({
	 				   		     showEmailInvalidError: 'none'
	 				              });
	 				       }.bind(this),3000);
	 			}
	 			else
	 			{
	 				  var isPasswordValid = this.state.password.length > 0 ? true : false;
	 				  isValid = isValid && isPasswordValid;

	 		  		if (!isValid)
	 		  		{
	 		  		            this.setState({
	 		  				  showPasswordError: 'block',
	 		  				 displaySignButtons: true,
							  displaySigninButtons: true,
	 		  		           });

	 		  			       setTimeout (function(){
	 		  			               this.setState({
	 		  			   		     showPasswordError: 'none'
	 		  			              });
	 		  			       }.bind(this),3000);
	 		  		}
	 				else
	 				{
	   				          var isPasswordNotShort = this.state.password.length > 5 ? true : false;
	   				          isValid = isValid && isPasswordNotShort;

	 		  		  		if (!isValid)
	 		  		  		{
	 		  		  		            this.setState({
	 		  		  				  showPasswordTooShortError: 'block',
	 		  		  				 displaySignButtons: true,
									   displaySigninButtons: true,
	 		  		  		           });

	 		  		  			       setTimeout (function(){
	 		  		  			               this.setState({
	 		  		  			   		     showPasswordTooShortError: 'none'
	 		  		  			              });
	 		  		  			       }.bind(this),3000);
	 		  		  		}
	 						else
	 						{
	 							this.confirmOnSubmit();

								 console.log(">>> Submit Sign up");
	 						}
	 				}
	 			}
	 		}
	 	}

	   }

	     async confirmOnSubmit() {


	 	        try {


	 			       console.log("submit");

	 		            // var ip = 'https://www.debyoo.com/';

	 		            //  var ip = 'http://localhost:3000/';

	 		            var ip = generateIPForREST();

	 		             let response = await fetch(ip+'signup', {
	 		                                     method: 'POST',
	 		                                     headers: {
	 		                                       'Accept': 'application/json',
	 		                                       'Content-Type': 'application/json',
	 		                                     },
	 		                                     body: JSON.stringify({
	                                                      typef: 'webajx',
	 				                         req: 'api',
								    a: 'yinitj',
	 		                                       user:{
	 							      name: this.state.name,
	 		                                         email: this.state.email,
	 		                                         password: this.state.password,
	 		                                       }
	 		                                     })
	 		                                   });
	 		             let res = await response.json();

	 		             if (response.status >= 200 && response.status < 300) {

	 		                       if (res.status == "100")
	 		                       {
	 		                              console.log(">>> 100 HERE >>> " + JSON.stringify(res));

	 		                             /* this.reactivateStep = 1;
	 		                               this.responseData = res;

	 		                              this.setState({
	 		                                virtualjobvar: 'a',
	 		                                global_account_active_status: res.global_account_active_status
	 		                              });*/
	 		                       }
	 		                        else if (res.status == "200")
	 		                        {

	 		                               console.log(">>> 200 HERE >>> " + JSON.stringify(res));

			   					this.setState({
			   						isLoggedIn: true,
			   						 uid: res.uid,
			   			                      processStatus: '',
			   			                       user_name: res.name,
			   						displaySignButtons: true,
			   						displaySignupButtons: true,
									  displaySigninButtons: true,
			   					});




	 		                       }
	 		                       else
	 		                       {
	 				                          console.log(">>> Other HERE >>> " + JSON.stringify(res));
	 				                          console.log(">>> response >>> " + JSON.stringify(response));

	 				                      if (res.error == "name")
	 				 			  {
	 				 				  this.setState({password: '', showNameError: 'block', displaySignButtons: true,   displaySigninButtons: true,});
	 				 				  setTimeout(function(){
	 				 				  	this.setState({showNameError: 'none'});
	 				 				  }.bind(this),4000);
	 				 			  }
	 				 			  else if (res.error == "email taken")
	 				 			  {
	 				 				  this.setState({password: '', showEmailTakenError: 'block', displaySignButtons: true,   displaySigninButtons: true,});
	 				 				  setTimeout(function(){
	 				 				  	this.setState({showEmailTakenError: 'none'});
	 				 				  }.bind(this),4000);
	 				 			  }
	 				 			  else if (res.error == "email format")
	 				 			  {
	 				 				  this.setState({password: '', showEmailInvalidError: 'block', displaySignButtons: true,   displaySigninButtons: true,});
	 				 				  setTimeout(function(){
	 				 				  	this.setState({showEmailInvalidError: 'none'});
	 				 				  }.bind(this),4000);
	 				 			  }
	 				 			  else if (res.error == "pswd blank")
	 				 			  {
	 				 				  this.setState({password: '', showPasswordError: 'block', displaySignButtons: true,   displaySigninButtons: true,});
	 				 				  setTimeout(function(){
	 				 				  	this.setState({showPasswordError: 'none'});
	 				 				  }.bind(this),4000);
	 				 			  }
	 				 			  else if (res.error == "pswd short")
	 				 			  {
	 				 				  this.setState({password: '', showPasswordTooShortError: 'block', displaySignButtons: true,   displaySigninButtons: true,});
	 				 				  setTimeout(function(){
	 				 				  	this.setState({showPasswordTooShortError: 'none'});
	 				 				  }.bind(this),4000);
	 				 			  }


	 		                       }

	 		             } else {
	 		                        console.log("2.res: " + JSON.stringify(res));
	 		                        console.log("2.response: " + JSON.stringify(response.status));

	 		                       this.setState({displaySignButtons: true,   displaySigninButtons: true,});


	 		             }



	 		        } catch(error) {

	 		               console.log("error: " + JSON.stringify(error));

	 		               this.setState({displaySignButtons: true,   displaySigninButtons: true,});

	 		        }
	    }


      handleChangeEmailSignin(event) {
               this.setState({emailSignin: event.target.value});
     }

     handleChangePasswordSignin(event) {
              this.setState({passwordSignin: event.target.value});
    }



    handleChangeName(event) {
            this.setState({name: event.target.value});
   }

    handleChangeEmail(event) {
            this.setState({email: event.target.value});
   }

   handleChangePassword(event) {
           this.setState({password: event.target.value});
  }


  handleChangeWhatsapp(event) {
          this.setState({whatsapp: event.target.value});
 }



    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
        //   this.props.appStore.locale = lang;

	 	  localStorage.setItem("language", lang);
	 	  i18n.changeLanguage(lang);
    }

    startRegistration(a) {

       this.setState({displayPopUp: true});
     }

  _startRegistration(a) {
		var _posY = document.documentElement.scrollTop || document.body.scrollTop;

		this.setState({
			posY: _posY,
			showSUPswd: false,
		   showSIPswd: false,
		 showNPPswd: false,
		 resetPasswordStatus: "",
		 emailRP: '',
		 passwordRP: '',
		 pcodeRP: '',
		 new_password: '',
		 reset_password_token: '',
		 showCodePopMessager: 'block',
              showPasswordError: 'none',
              showPasswordSuccess: 'none',
	      showEmailInvalidError: 'none',
		 showPasswordCodeError: 'none',
		 showPasswordError: 'none',
		 showPasswordTooShortError: 'none',
		 displayPCodeVerif: false,
		 displayPCodeResend: false,
              displaySignButtons: true,
		 displayNewPasswordWrapper: false,
		 displayPCodeWrapper: false,
		 displayNewPassword: false,
			 displayResetPassword: false,
			  displaySigninButtons: true,
			  displaySignupButtons: true,
			isLoggedIn: false,
                      processStatus: '',
                       user_name: '',
			 uid: null,
			typef: a,
			 livingPlace: 'Togo',
			displaySignPopUp: true,
			 emailSignin: '',
			 passwordSignin: '',
	              showSigninError: 'none',
	            displaySignButtons: true,
		  name: '',
		 email: '',
		 password: '',
			 whatsapp: '',
             showNameError: 'none',
		showEmailTakenError: 'none',
		showEmailInvalidError: 'none',
		showPasswordError: 'none',
		showPasswordTooShortError: 'none',
		showTermsError: 'none',
		 agreedTerms: false,
		});

		document.body.style.overflow = 'hidden';
		document.body.style.position = 'fixed';
	}



	cancelConfirm() {
		this.setState({
		showSUPswd: false,
		showSIPswd: false,
		  showNPPswd: false,
		  resetPasswordStatus: "",
		 emailRP: '',
		 passwordRP: '',
		 pcodeRP: '',
		 new_password: '',
		 reset_password_token: '',
		 showCodePopMessager: 'block',
              showPasswordError: 'none',
              showPasswordSuccess: 'none',
	      showEmailInvalidError: 'none',
		 showPasswordCodeError: 'none',
		 showPasswordError: 'none',
		 showPasswordTooShortError: 'none',
		 displayPCodeVerif: false,
		 displayPCodeResend: false,
              displaySignButtons: true,
		 displayNewPasswordWrapper: false,
		 displayPCodeWrapper: false,
		 displayNewPassword: false,
			 displayResetPassword: false,
			  displaySigninButtons: true,
			  displaySignupButtons: true,
			isLoggedIn: false,
                      processStatus: '',
                       user_name: '',
		        uid: null,
			typef: '',
			 livingPlace: 'Togo',
			displaySignPopUp: false,
			 emailSignin: '',
			 passwordSignin: '',
	              showSigninError: 'none',
	            displaySignButtons: true,
		  name: '',
		 email: '',
		 password: '',
			 whatsapp: '',
             showNameError: 'none',
		showEmailTakenError: 'none',
		showEmailInvalidError: 'none',
		showPasswordError: 'none',
		showPasswordTooShortError: 'none',
		showTermsError: 'none',
		 agreedTerms: false,
		});

		document.body.style.overflow = 'auto';
		document.body.style.position = 'relative';

		window.scrollTo({ top: this.state.posY, behavior: 'smooth' });
	}


	changeShowSUPswd() {
		this.setState({
			showSUPswd: !this.state.showSUPswd
		});
	}

	changeShowSIPswd() {
		this.setState({
			showSIPswd: !this.state.showSIPswd
		});
	}

	changeShowNPPswd() {
		this.setState({
			showNPPswd: !this.state.showNPPswd
		});
	}



	sendConfirmation() {
		console.log(">>> uid: " + this.state.uid + " >>>  name: " +  this.state.user_name + " >>>  typef: " +  this.state.typef + " >>>  livingPlace: " +  this.state.livingPlace + " >>>  wa: " +  this.state.whatsapp);

             this.setState({
                 displaySignButtons: false
             });

	          var ip = generateIPForREST();

	   	fetch(ip+'join_yinitj_api', {
	                               method: 'POST',
	                               headers: {
	                                 'Accept': 'application/json',
	                                 'Content-Type': 'application/json'
	                               },
	                               body: JSON.stringify({
				               uid: this.state.uid,
						name: this.state.user_name,
						typef: this.state.typef,
						country: "TG",
						 living_place: this.state.livingPlace,
						whatsapp: this.state.whatsapp,
				               lang: i18n.language,
						device: "Tablet",
						debyoo_platform: "yinitj.com",
						ip: this.props.userConnection.userConnection.ip,
						connection_country: this.props.userConnection.userConnection.connection_country,
						connection_city: this.props.userConnection.userConnection.connection_city,
					       user_agent: navigator.userAgent,
	                               })
	                       })
	   	  	.then((response) => response.json())
	   	  	.then((responseData) => {

			            this.setState({
			 		 processStatus: 'success'
			             });

			 	     setTimeout (function(){
			 			 this.cancelConfirm();
			 	     }.bind(this), 5000);

	                })
	               .catch(function(error) {

	   	       }.bind(this));


	}

	changeTypef(a) {
		console.log("typef:" + a)
        this.setState({
          typef: a
        });
	}


	changeLivingPlace(a) {
		console.log(" livingPlace:" + a)
          this.setState({
            livingPlace: a
          });
	}






    async onResendPCode(event) {
		 event.preventDefault();

             this.setState({
                 displaySignButtons: false,
                 showCodePopMessager: 'none',
	          showPasswordCodeError: 'none',
	          displayPCodeVerif: false,
	          displayPCodeResend: true,
		   displayNewPassword: false
             });

	     try {
		         var ip = generateIPForREST();

		          let response = await fetch(ip+'reset_pswd_api', {
		                                 method: 'POST',
		                                 headers: {
		                                   'Accept': 'application/json',
		                                   'Content-Type': 'application/json',
		                                 },
		                                 body: JSON.stringify({
		                                   user:{
		                                     email: this.state.emailRP
		                                   }
		                                 })
		                               });
		         let res = await response.json();

			  if (response.status >= 200 && response.status < 300) {

				                 if (res.status == "200")
				 		  {
				 			 this.setState({
								 displayNewPasswordWrapper: false,
								 displayPCodeWrapper: true,
				 				 showCodePopMessager: 'block',
				 				 displayPCodeVerif: false,
				 				 displayPCodeResend: false,
				 		              displaySignButtons: true,
								displayNewPassword: false
				 			 });
				 		  }
				 		  else
				 		  {
			 	   			 this.setState({
								 displayNewPasswordWrapper: false,
								 displayPCodeWrapper: true,
			 	   				 showCodePopMessager: 'none',
			 	   				 displayPCodeVerif: false,
			 	   				 displayPCodeResend: false,
			 	   		              displaySignButtons: true,
								displayNewPassword: false
			 	   			 });
				 		  }
			  }
			  else
			  {
	   			 this.setState({
					 displayNewPasswordWrapper: false,
					 displayPCodeWrapper: true,
	   				 showCodePopMessager: 'none',
	   				 displayPCodeVerif: false,
	   				 displayPCodeResend: false,
	   		              displaySignButtons: true,
					displayNewPassword: false
	   			 });
			  }

	       } catch(error) {
  			 this.setState({
				 displayNewPasswordWrapper: false,
				 displayPCodeWrapper: true,
  				 showCodePopMessager: 'none',
  				 displayPCodeVerif: false,
  				 displayPCodeResend: false,
  		              displaySignButtons: true,
				displayNewPassword: false
  			 });
	       }





	}

   async onSubmitPCode(event) {
          event.preventDefault();

          this.setState({
              displaySignButtons: false,
	       showCodePopMessager: 'none',
		showPasswordCodeError: 'none',
		 displayPCodeVerif: true,
		 displayPCodeResend: false,
		 displayNewPassword: false
          });

          try {
                       console.log("submit");

                       //  var ip = 'https://www.debyoo.com/';
	                var ip = generateIPForREST();


		          let response = await fetch(ip+'verify_pswd_code_api', {
		                                 method: 'POST',
		                                 headers: {
		                                   'Accept': 'application/json',
		                                   'Content-Type': 'application/json',
		                                 },
		                                 body: JSON.stringify({
		                                   user:{
							  pcode: this.state.pcode,
		                                     email: this.state.emailRP
		                                   }
		                                 })
		                               });
		         let res = await response.json();

			 if (response.status >= 200 && response.status < 300) {

				  if (res.status == "200")
				 {
					 console.log(">>> reset_password_token: " + res.reset_password_token);

					 this.setState({
						 reset_password_token: res.reset_password_token,
						 displayPCodeWrapper: false,
						 displayNewPasswordWrapper: true,
						 showPasswordCodeError: 'none',
						 displayPCodeVerif: false,
						 displayPCodeResend: false,
				              displaySignButtons: true,
						displayNewPassword: false
					 });
				 }
				 else
				 {
					 this.setState({
						 showPasswordCodeError: 'block',
						 displayPCodeVerif: false,
						 displayPCodeResend: false,
				              displaySignButtons: true,
						displayNewPassword: false
					 });
				 }
			 }
			 else
			 {
				 this.setState({
					 showPasswordCodeError: 'block',
					 displayPCodeVerif: false,
					 displayPCodeResend: false,
			              displaySignButtons: true,
					displayNewPassword: false
				 });
			 }

	    } catch(error) {

			 this.setState({
				 showPasswordCodeError: 'block',
				 displayPCodeVerif: false,
				 displayPCodeResend: false,
		              displaySignButtons: true,
				displayNewPassword: false
			 });
	    }


   }

  async  onSubmitNewPassword(event) {
	      event.preventDefault();

          this.setState({
              displaySignButtons: false,
	       showCodePopMessager: 'none',
		showPasswordCodeError: 'none',
		 displayPCodeVerif: false,
		 displayPCodeResend: false,
		 displayNewPassword: true
          });

		  var  isValid = true;
		  var isPasswordValid = this.state.new_password.length > 0 ? true : false;
		  isValid = isValid && isPasswordValid;

  		if (!isValid)
  		{
  		            this.setState({
  				  showPasswordError: 'block',
  				 displaySignButtons: true
  		           });

  			       setTimeout (function(){
  			               this.setState({
  			   		     showPasswordError: 'none'
  			              });
  			       }.bind(this),3000);
  		}
		else
		{
		          var isPasswordNotShort = this.state.new_password.length > 5 ? true : false;
		          isValid = isValid && isPasswordNotShort;

  		  		if (!isValid)
  		  		{
  		  		            this.setState({
  		  				  showPasswordTooShortError: 'block',
  		  				 displaySignButtons: true
  		  		           });

  		  			       setTimeout (function(){
  		  			               this.setState({
  		  			   		     showPasswordTooShortError: 'none'
  		  			              });
  		  			       }.bind(this),3000);
  		  		}
				else
				{
					this.onConfirmSubmitNewPassword();
				}
		}
   }


   async onConfirmSubmitNewPassword() {
   	      try {
			         var ip = generateIPForREST();

			          let response = await fetch(ip+'users/password.json', {
			                                 method: 'POST',
			                                 headers: {
			                                   'Accept': 'application/json',
			                                   'Content-Type': 'application/json',
			                                 },
			                                 body: JSON.stringify({
                                                        reset_password_token: this.state.reset_password_token,
				                           typef: 'api',
			                                   user:{
	                                                      reset_password_token: this.state.reset_password_token,
								     password: this.state.new_password,
								     password_confirmation: this.state.new_password,
								     email: this.state.emailRP
			                                   }
			                                 })
			                               });
			         let res = await response.json();

				 if (response.status >= 200 && response.status < 300) {

					  if (res.status == "200")
					 {

						 this.setState({
						 	 resetPasswordStatus: "success",
							 displayNewPasswordWrapper: false,
							 displayPCodeWrapper: false,
							 displayResetPassword: false,
						 });

						 setTimeout (function(){
							 var _typef = this.state.typef;
							 this.cancelConfirm();
							 this.startRegistration(_typef);
						 }.bind(this), 4000);





					 }
					 else
					 {
						 this.setState({
							 showPasswordCodeError: 'block',
							 displayPCodeVerif: false,
							 displayPCodeResend: false,
					              displaySignButtons: true,
							displayNewPassword: false
						 });
					 }
				 }
				 else
				 {
					 this.setState({
						 showPasswordCodeError: 'block',
						 displayPCodeVerif: false,
						 displayPCodeResend: false,
				              displaySignButtons: true,
						displayNewPassword: false
					 });
				 }

		} catch(error) {

		}
   }


   onSubmitRP(event) {
          event.preventDefault();


          this.setState({
            displaySignButtons: false
          });

	   var isValid =  true;
	   var isEmailValid = this.isEmail(this.state.emailRP);
	   isValid = isValid && isEmailValid;

 		if (!isValid)
 		{
                    this.setState({showEmailInvalidError: 'block', displaySignButtons: true});
                    setTimeout(function(){
                          this.setState({showEmailInvalidError: 'none'});
                    }.bind(this),4000);
 		}
		else
		{
			this.confirmOnSubmitRP();
		}




   }


    async confirmOnSubmitRP() {

           try {
                console.log("submit");

              //  var ip = 'https://www.debyoo.com/';
		    var ip = generateIPForREST();

               //  var ip = 'http://localhost:3000/';

                var jwtToken = localStorage.getItem("jwtToken") || '';

                console.log(">>> jwtToken: " + jwtToken);

                let response = await fetch(ip+'reset_pswd_api', {
                                        method: 'POST',
                                        headers: {
                                          'Accept': 'application/json',
                                          'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({
                                          user:{
                                            email: this.state.emailRP
                                          }
                                        })
                                      });
                let res = await response.json();

                if (response.status >= 200 && response.status < 300) {

                          if (res.status == "100")
                          {
                                 console.log(">>> 100 HERE >>> " + JSON.stringify(res));

                                /* this.reactivateStep = 1;
                                  this.responseData = res;

                                 this.setState({
                                   virtualjobvar: 'a',
                                   global_account_active_status: res.global_account_active_status
                                 });*/
                          }
                           else if (res.status == "200")
                           {

                                  console.log(">>> 200 HERE >>> " + JSON.stringify(res));
					  console.log(">>> email : " + this.state.email);

                                  this.setState({
						displaySignButtons: true,
					       displayPCodeWrapper: true,
					    displayNewPasswordWrapper: false,
						displayResetPassword: false,
					});

                                /*  store.setSelectedLocalePage('PasswordCode');
                                  this.props.navigation.navigate('PasswordCode', {email: this.state.email});

                                        this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                                    showSignUpButton: 'flex',});


                                  this.setState({email: ''});*/
                          }
                          else
                          {
                             console.log(">>> Other HERE >>> " + JSON.stringify(res));
                             console.log(">>> response >>> " + JSON.stringify(response));
                            this.setState({showPasswordError: 'block', displaySignButtons: true});
                            setTimeout(function(){
                              this.setState({showPasswordError: 'none'});
                            }.bind(this),4000);

                            //this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                             //  showSignUpButton: 'flex',});
                          }

                } else {
                           console.log("2.res: " + JSON.stringify(res));
                           console.log("2.response: " + JSON.stringify(response.status));
                                //Handle error
                               // let error = res;
                               // throw error;
                          this.setState({showPasswordError: 'block', displaySignButtons: true});
                          setTimeout(function(){
                            this.setState({showPasswordError: 'none'});
                          }.bind(this),4000);

                         // this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                          //showSignUpButton: 'flex',});
                }



           } catch(error) {
                  console.log("error: " + JSON.stringify(error));

                  this.setState({showPasswordError: 'block', displaySignButtons: true});
                  setTimeout(function(){
                    this.setState({showPasswordError: 'none'});
                  }.bind(this),4000);
           }
     }


      handleChangeEmailRP(event) {
               this.setState({emailRP: event.target.value});
     }

   /*  handleChangePasswordRP(event) {
              this.setState({passwordRP: event.target.value});
    }*/

    handleChangeNewPassword(event) {
             this.setState({new_password: event.target.value});
   }

	handleChangePCode(event) {
		var pcode = event.target.value;
		if (pcode.length > 6)
		{
			 this.setState({pcode: pcode.slice(0, 6)});
		}
		else
		{
			this.setState({pcode: pcode});
		}

    }

	resetPassword() {
	   this.setState({

		 displayNewPasswordWrapper: false,
		 displayPCodeWrapper: false,
		displayResetPassword: true,
	   });
	}


	cancelResetPassword() {
	   this.setState({
		   new_password: '',
		   passwordRP: '',
		   pcode: '',
		   emailRP: '',
		 displayNewPasswordWrapper: false,
		 displayPCodeWrapper: false,
		 displayResetPassword: false,
	   });
	}

  closePopUp() {
     this.setState({
       displayPopUp: false
     });
 }


 whatchFilm(t) {
      PubSub.publish('launchVideoPop', t);
   }




  render() {
	//console.log("isMobile:" + isMobile)

  //	const { t, i18n } = useTranslation();

  /*	   const changeLanguage = lng => {
  	      i18n.changeLanguage(lng);
  	};*/

    return (
	    <Translation>
		    { t =>
		          <div>



                {this.state.displayPopUp &&
                  <div style={{position: 'fixed', zIndex: 999, width: '100%', height: '100%', top: 0, left: 0,
                                         background: 'rgba(0,0,0,0.8)'}}>

                   <div style={{position: 'relative', width: '100%', height: '100%',
                               background: 'transparent'}}>

                       <div onClick={this.closePopUp.bind(this)} style={{cursor: 'pointer',  position: 'relative',  width: '100%', height: '100%',}}>
                           <div style={{position: 'fixed', top: '10px', right: '20px', zIndex: 99999, color: '#fff', fontSize: '60px'}}>
                               &times;
                           </div>
                       </div>

                       <div style={{position: 'absolute', top: '80px', width: '660px', left: '50%', marginLeft: '-330px',
                            zIndex: 99999}}>

                         <div style={{clear: 'both', height: '0px'}} />
                          <div style={{margin:'auto', background:  '#fff', width: '660px', borderRadius: '10px'}}>

                            <div style={{clear: 'both', height: '20px'}} />

                              <div style={{margin:'auto', background:  '#fff', width: '600px', color: 'red', fontSize: '25px', textAlign: 'center'}}>
                                   {i18n.language == "en" &&
                                     <div>
                                        From now on, requests for membership can only be made via the Debyoo application.
                                     </div>
                                   }
                                   {i18n.language == "fr" &&
                                     <div>
                                         Désormais, les demandes d'adhésion se font uniquement via l'application Debyoo.
                                     </div>
                                   }
                              </div>

                              <div style={{clear: 'both', height: '20px'}} />
                              <div style={{clear: 'both', width: '100%', margin: 'auto', borderTop: '1px solid #ccc'}} />
                              <div style={{clear: 'both', height: '20px'}} />


                            <div style={{margin:'auto', background:  '#fff', width: '600px', color: '#333', fontSize: '25px', textAlign: 'center'}}>
                              {i18n.language == "en" &&
                                 <div>
                                     How to join the Youth Initiative Jeunesse Togo via the application Debyoo for <a href="https://apple.co/3g76hoz" target="_bblank">iPhone</a> and <a href="https://bit.ly/3kU3QJA" target="_bblank">Android</a>
                                 </div>
                               }
                               {i18n.language == "fr" &&
                                 <div>
                                     Comment rejoindre la Youth Initiative Jeunesse Togo via l'application Debyoo app for <a href="https://apple.co/3g76hoz" target="_bblank">iPhone</a> et <a href="https://bit.ly/3kU3QJA" target="_bblank">Android</a>
                                 </div>
                               }
                            </div>

                            <div style={{clear: 'both', height: '20px'}} />

                            <div style={{margin:'auto', background:  '#fff', width: '600px', border: '1px  solid #333', height: '337px'}}>
                              <iframe width="600" height="337" src="https://www.youtube.com/embed/asVcIC-l5-Q?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1" frameborder="0" allowfullscreen></iframe>
                            </div>

                             <div style={{clear: 'both', height: '30px'}} />


                               {i18n.language == "en" &&
                                   <div style={{margin:'auto', background:  '#fff', width: '300px', color: '#333', fontSize: '20px', textAlign: 'center'}}>
                                                               {'Download'} {'Debyoo'}
                                  </div>
                               }
                               {i18n.language == "fr" &&
                                   <div style={{margin:'auto', background:  '#fff', width: '300px', color: '#333', fontSize: '20px', textAlign: 'center'}}>
                                                               {'Télécharger'} {'Debyoo'}
                                  </div>
                               }


                             <div style={{clear: 'both', height: '20px'}} />

                                 <div style={{textAlign: 'center', marginTop: '0px'}}>
                                          {i18n.language == "fr" &&
                                              <a href={'https://itunes.apple.com/fr/app/debyoo/id1460835118'} target="_blank">
                                                  <img src={'https://s3.amazonaws.com/debyooinc20.img/apple_store_download.png'} style={{width: '180px'}} />
                                              </a>
                                          }
                                           {i18n.language == "en" &&
                                           <a href={'https://itunes.apple.com/app/debyoo/id1460835118'} target="_blank">
                                             <img src={'https://s3.amazonaws.com/debyooinc20.img/apple_store_download.png'} style={{width: '180px'}} />
                                           </a>
                                          }

                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                          <a href={'https://play.google.com/store/apps/details?id=com.androidv.debyooapp&hl='+i18n.language} target="_blank">
                                           <img src={'https://s3.amazonaws.com/debyooinc20.img/google_store_download.png'} style={{width: '180px'}} />
                                         </a>
                               </div>


                               <div style={{clear: 'both', height: '20px'}} />


                         </div>
                       </div>

                       </div>


                   </div>
               }


	                {this.state.displaySignPopUp &&
		           <div  style={{position: 'fixed', zIndex: 999, width: '100%', height: '100%', top: 0, left: 0,
	                          background: 'rgba(0,0,0,0.8)'}}>


				        <div style={{clear: 'both', height: '20px'}} />
				        <div style={{margin:'auto', background:  '#fff', width: '80%', height: '95%', borderRadius: '10px', maxHeight: (viewportHeight-40)+'px', overflow: 'auto'}}>

						           <div style={{clear: 'both', height: '5px'}} />

							    <div style={{position: 'relative', width: '98%', margin: 'auto', textAlign: 'center', color: '#555'}}>
								      {i18n.language == "fr" &&
									    <div>
										        <div style={{fontWeight: 'bold', fontSize: '20px', color: '#222'}}>Rejoindre la communauté de Youth Initiative Jeunesse Togo</div>
											 <div style={{clear: 'both', height: '5px'}} />
											  <div style={{clear: 'both', width: '100%', borderTop: '1px solid #c8d1e2'}} />

										    </div>
										}
									      {i18n.language == "en" &&
										   <div>
										       <div style={{fontWeight: 'bold', fontSize: '20px', color: '#222'}}>Join the Youth Initiative Jeunesse Togo's community </div>
											<div style={{clear: 'both', height: '5px'}} />
											  <div style={{clear: 'both', width: '100%', borderTop: '1px solid #c8d1e2'}} />

										   </div>
										}
								   </div>

						                <div style={{padding:'20px', paddingTop: '5px', position: 'relative'}}>
											{this.state.resetPasswordStatus == "success" &&
											    <div style={{position: 'absolute', zIndex: 6, width: '96%', height: '88%', background: '#fff'}}>
													<div style={{padding: '40px', textAlign: 'center'}}>
													  <div style={{fontSize: '20px', color: '#333'}}>
												                   {t('Your_password_has_been_successfully_changed')}
											               </div>
													  <div style={{clear: 'both', height: '30px'}} />
													  <div style={{background: 'rgb(113,179,0)', display: 'table', margin: 'auto', color: '#fff', borderRadius: '50%', textAlign: 'center', height: '70px', width: '70px'}}>
													    <div style={{display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '50px', width: '50px', fontWeight: 'normal', fontSize: '40px'}}>
													         &#x2713;
													    </div>
													  </div>
													 <div style={{clear: 'both', height: '20px'}} />
													  <div style={{color: 'rgb(143,148,157)', fontSize: '20px', textAlign: 'center'}}>{t('You_can_signin_with_your_new_password')}</div>
													</div>

											   </div>
										     }



											 {this.state.displayNewPasswordWrapper &&
								                       <div style={{position: 'absolute', zIndex: 6, width: '96%', height: '88%', background: '#fff'}}>

													   <div style={{position: 'relative', width: '90%', margin: 'auto', textAlign: 'center', color: '#555'}}>
													      {i18n.language == "fr" &&
														    <div>
															  <div style={{clear: 'both', height: '5px'}} />
														        <div style={{fontSize: '14px'}}>Le processus d'enregistrement est propulsé par <a href={'https://www.debyoo.com'}  target="_blank">Debyoo</a>. Les identifiants de compte Debyoo sont donc nécessaires pour continuer.</div>
														     </div>
														}
													      {i18n.language == "en" &&
														   <div>
															  <div style={{clear: 'both', height: '5px'}} />
														         <div style={{fontSize: '14px'}}>The registration process is powered by <a href={'https://www.debyoo.com'}  target="_blank">Debyoo</a>. Debyoo account credentials then needed to continue.</div>
														   </div>
														}
												      </div>

													<div style={{clear: 'both', height: '15px'}} />

													<div style={{width: '370px', margin: 'auto', position: 'relative', fontSize: '20px', color: '#2489CE', marginTop: '0px', textAlign: 'center'}}>

														              <div style={{clear: 'both', height: '45px'}} />

										                                          <b>{t('Create_a_new_password')}</b>


														        <div style={{clear: 'both', height: '10px'}} />

													 </div>

										                   <div style={{position: 'relative', width: '370px',margin:'auto', height: '40px'}}>
											                                <Gradient style={{display: this.state.showPasswordError, position: 'absolute',zIndex:3,top:0,width:370}}
											                                    gradients={[
											                                         ['#CA1C01', '#BB1603'],
											                                       ]}
											                                          property="background"
											                                          duration={ 3000 }
											                                          angle="0deg"
											                                     className="buttonWhiteText">
											                                     {t('password_blank')}
											                                  </Gradient>

											                                  <Gradient style={{display: this.state.showPasswordTooShortError, position: 'absolute',zIndex:3,top:0,width:370}}
											                                    gradients={[
											                                         ['#CA1C01', '#BB1603'],
											                                       ]}
											                                          property="background"
											                                          duration={ 3000 }
											                                          angle="0deg"
											                                     className="buttonWhiteText">
											                                     {t('password_short')}
											                                    </Gradient>
													    </div>

													<div style={{clear: 'both', height: '20px'}}></div>


								                                     <div style={{width: '370px', margin: 'auto'}}>

										   							<form onSubmit={this.onSubmitNewPassword} noValidate="novalidate">
										   								 <div style={{width:'370px',margin:'auto'}}>

										   								    <div style={{position: 'relative',width: '370px',margin:'auto',}}>
					   														    <div onClick={this.changeShowNPPswd.bind(this)} style={{position: 'absolute', zIndex: 3, cursor: 'pointer', right: '5px', top: '8px', width: '28px', height: '28px'}}>
					   													                   {this.state.showNPPswd && <img src={'/img/hide_pswd_icon.png'} style={{width: '28px'}}/>}
					   															     {!this.state.showNPPswd && <img src={'/img/show_pswd_icon.png'} style={{width: '28px'}}/>}
					   													           </div>
										   								        <input className="basic_ti"  onChange={this.handleChangeNewPassword} value={this.state.new_password}
										   								              style={{width: '354px',padding: '6px', height: '30px', fontSize: '16px'}}
										   										name="user[new_password]" placeholder={t('Type_a_new_password')} type={this.state.showNPPswd ? 'text':'password'} autoCapitalize="off" autoComplete="off" autoCorrect="off"/>
										   								     </div>





										   								   <div style={{clear: 'both', height: '10px'}}></div>




										   								      {this.state.displaySignButtons &&
										   				                                             <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
										   									           <Gradient style={{width:'370px', marginTop: 10}}
										   										   gradients={[
										   										   			['#1392E8', '#035598'],
										   										   		]}
										   									           property="background"
										   									           duration={ 3000 }
										   									           angle="0deg"
										   										    className="buttonWhiteText">
										   											  {t('Continue')}
										   									        </Gradient>
										   								           </button>
										   				                                         }

										   				                                   {!this.state.displaySignButtons && this.state.displayNewPassword &&
										   						                            <Gradient style={{width:'370px',  marginTop: 10}}
										   						                             gradients={[
										   						                             ['#1392E8', '#035598'],
										   						                             ]}
										   						                             property="background"
										   						                             duration={ 3000 }
										   						                             angle="0deg"
										   						                             className="buttonWhiteText">
										   						                             <div style={{paddingTop:3}}>
										   											  <Spinner color="#fff" size={14} speed={0.8}/>
										   										  </div>
										   						                          </Gradient>
										   						                        }

										   								   </div>

										   					 </form>

															  {this.state.displaySignButtons &&
															       <div style={{textAlign:'center', width:370, margin: 'auto', marginTop: '17px'}}>
																	  <a href="javascript:void(0)" onClick={this.cancelResetPassword.bind(this)} style={{fontSize: '16px', fontWeight: 600}}>{t('Cancel')}</a>
															       </div>
														          }
													 </div>

											    </div>
										 }





		 								{this.state.displayPCodeWrapper &&
		 					                       <div style={{position: 'absolute', zIndex: 6, width: '96%', height: '88%', background: '#fff'}}>
		 								    <div style={{position: 'relative', width: '90%', margin: 'auto', textAlign: 'center', color: '#555'}}>
		 										      {i18n.language == "fr" &&
		 											    <div>
		 													  <div style={{clear: 'both', height: '5px'}} />
		 												        <div style={{fontSize: '14px'}}>Le processus d'enregistrement est propulsé par <a href={'https://www.debyoo.com'}  target="_blank">Debyoo</a>. Les identifiants de compte Debyoo sont donc nécessaires pour continuer.</div>
		 												     </div>
		 												}
		 											      {i18n.language == "en" &&
		 												   <div>
		 													  <div style={{clear: 'both', height: '5px'}} />
		 												         <div style={{fontSize: '14px'}}>The registration process is powered by <a href={'https://www.debyoo.com'}  target="_blank">Debyoo</a>. Debyoo account credentials then needed to continue.</div>
		 												   </div>
		 												}
		 										   </div>

		 										<div style={{clear: 'both', height: '15px'}} />

		 										<div style={{width: '370px', margin: 'auto', position: 'relative', fontSize: '20px', color: '#2489CE', marginTop: '0px', textAlign: 'center'}}>

		 												              <div style={{clear: 'both', height: '45px'}} />

		 								                                          <b>{t('resetpassword')}</b>


		 					   								  <div style={{clear: 'both', height: '20px'}}></div>

		 					   								  <div style={{color: '#63ac24',  fontSize: '17px', display:this.state.showCodePopMessager}}>{t('A_code_has_been_sent')}</div>

		 					   				                             <Gradient style={{display: this.state.showPasswordCodeError, width:370, margin: 'auto'}}
		 					   											   gradients={[
		 					   											   			['#CA1C01', '#BB1603'],
		 					   											   		]}
		 					   										           property="background"
		 					   										           duration={ 3000 }
		 					   										           angle="0deg"
		 					   											    className="buttonWhiteText">
		 					   												  {t('You_entered_an_incorrect_code')}
		 					   				         				</Gradient>

		 										 </div>


		                                                                        <div style={{clear: 'both', height: '20px'}}></div>

		 					                                      <div style={{width: '370px', margin: 'auto'}}>

		 													<form onSubmit={this.onSubmitPCode} noValidate="novalidate">
		 														 <div style={{width:'250px',margin:'auto'}}>

		 														    <div style={{width: '162px',margin:'auto',}}>
		 														         <input className="basic_ti"  onChange={this.handleChangePCode} value={this.state.pcode} maxLength="6"
		 														              style={{width: '150px',padding: '6px', textAlign: 'center', borderColor: '#ccc', fontSize: '30px'}}
		 																name="user[pcode]" placeholder={'·  ·  ·  ·  ·  ·'} type="number" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>
		 														     </div>

		 														   <div style={{clear: 'both', height: '10px'}}></div>

		 														      {this.state.displaySignButtons &&
		 									                                               <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
		 															           <Gradient style={{width:'250px', marginTop: 10}}
		 																   gradients={[
		 																   			['#1392E8', '#035598'],
		 																   		]}
		 															           property="background"
		 															           duration={ 3000 }
		 															           angle="0deg"
		 																    className="buttonWhiteText">
		 																	  {t('Continue')}
		 															        </Gradient>
		 														           </button>
		 									                                           }

		 										                                   {!this.state.displaySignButtons && this.state.displayPCodeVerif &&
		 												                            <Gradient style={{width:'250px',  marginTop: 10}}
		 												                             gradients={[
		 												                             ['#1392E8', '#035598'],
		 												                             ]}
		 												                             property="background"
		 												                             duration={ 3000 }
		 												                             angle="0deg"
		 												                             className="buttonWhiteText">
		 												                             <div style={{paddingTop:3}}>
		 																			 <Spinner color="#fff" size={14} speed={0.8}/>
		 																	</div>
		 												                          </Gradient>
		 												                        }

		 														   </div>

		 														</form>


		 														  <div style={{clear: 'both', height: '30px'}}></div>


		 													       {this.state.displaySignButtons &&
		 														       <Gradient onClick={this.onResendPCode} style={{width:'250px', margin:'auto', marginTop: 10}}
		 															   gradients={[
		 															   			['#1392E8', '#035598'],
		 															   		]}
		 														           property="background"
		 														           duration={ 3000 }
		 														           angle="0deg"
		 															    className="buttonWhiteText">
		 																  {t('Send_code_again')}
		 														        </Gradient>
		 								                                            }

		 									                                   {!this.state.displaySignButtons && this.state.displayPCodeResend &&
		 											                            <Gradient style={{width:'250px',  margin: 'auto', marginTop: 10}}
		 											                             gradients={[
		 											                             ['#1392E8', '#035598'],
		 											                             ]}
		 											                             property="background"
		 											                             duration={ 3000 }
		 											                             angle="0deg"
		 											                             className="buttonWhiteText">
		 											                             <div style={{paddingTop:3}}>
		 																		 <Spinner color="#fff" size={14} speed={0.8}/>
		 																</div>
		 											                          </Gradient>
		 											                        }


		 												  {this.state.displaySignButtons &&
		 												     <div style={{textAlign:'center', width:370, margin: 'auto', marginTop: '17px'}}>
		 														  <a href="javascript:void(0)" onClick={this.cancelResetPassword.bind(this)} style={{fontSize: '16px', fontWeight: 600}}>{t('Cancel')}</a>
		 												       </div>
		 											      }
		 										 </div>

		 								    </div>
		 								}




										{this.state.displayResetPassword  &&
										    <div style={{position: 'absolute', zIndex: 6, width: '96%', height: '88%', background: '#fff'}}>
												    <div style={{position: 'relative', width: '90%', margin: 'auto', textAlign: 'center', color: '#555'}}>
													      {i18n.language == "fr" &&
														    <div>
															  <div style={{clear: 'both', height: '5px'}} />
														        <div style={{fontSize: '14px'}}>Le processus d'enregistrement est propulsé par <a href={'https://www.debyoo.com'}  target="_blank">Debyoo</a>. Les identifiants de compte Debyoo sont donc nécessaires pour continuer.</div>
														     </div>
														}
													      {i18n.language == "en" &&
														   <div>
															  <div style={{clear: 'both', height: '5px'}} />
														         <div style={{fontSize: '14px'}}>The registration process is powered by <a href={'https://www.debyoo.com'}  target="_blank">Debyoo</a>. Debyoo account credentials then needed to continue.</div>
														   </div>
														}
												   </div>

												<div style={{clear: 'both', height: '15px'}} />

												<div style={{width: '370px', margin: 'auto', position: 'relative', fontSize: '20px', color: '#2489CE', marginTop: '0px', textAlign: 'center'}}>

														              <div style={{clear: 'both', height: '45px'}} />

										                                          <b>{t('resetpassword')}</b>


														        <div style={{clear: 'both', height: '30px'}} />

							     								   <Gradient style={{display: this.state.showPasswordError, position: 'absolute',zIndex:3,top:0,width:370}}
							     									   gradients={[
							     									   			['#CA1C01', '#BB1603'],
							     									   		]}
							     								           property="background"
							     								           duration={ 3000 }
							     								           angle="0deg"
							     									    className="buttonWhiteText">
							     										  {t('email_not_found')}
							     								  </Gradient>

							 			                                   <Gradient style={{display: this.state.showEmailInvalidError, position: 'absolute',zIndex:3,top:0,width:370}}
							 				                                   gradients={[
							 				                                       ['#CA1C01', '#BB1603'],
							 				                                     ]}
							 				                                        property="background"
							 				                                        duration={ 3000 }
							 				                                        angle="0deg"
							 				                                   className="buttonWhiteText">
							 				                                   {t('email_invalid')}
							 			                                    </Gradient>

							 									  <div style={{display: this.state.showPasswordSuccess, position: 'absolute',zIndex:3,top:-6,width:370, backgroundColor: '#4D8915', borderRadius: 4}}>
							                                                                    <div style={{padding:10, color: '#fff', fontSize: 14}}>{t('you_will_receive_an_email_with_instructions')}</div>
							 									   </div>

				 								 </div>

												<div style={{clear: 'both', height: '20px'}}></div>


						                                          <div style={{width: '370px', margin: 'auto'}}>
				 							           <form onSubmit={this.onSubmitRP} noValidate="novalidate">

													 <input className="basic_ti"  onChange={this.handleChangeEmailRP} value={this.state.emailRP} style={{width: '356px', margin: 'auto', padding: '6px', height: '30px', fontSize: '16px'}}
						 								        name="user[email]" placeholder={'Email'} type="email" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>

		                                                                                         <div style={{clear: 'both', height: '15px'}} />

						 								      {this.state.displaySignButtons &&
						                                                                  <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
						       								           <Gradient style={{width:370, margin: 'auto', marginTop: 0}}
						       									   gradients={[
						       									   			['#1392E8', '#035598'],
						       									   		]}
						       								           property="background"
						       								           duration={ 3000 }
						       								           angle="0deg"
						       									    className="buttonWhiteText">
						       										  {t('reset')}
						       								        </Gradient>
						 								           </button>
						                                                              }

						 			                                   {!this.state.displaySignButtons &&
						 					                            <Gradient style={{width:370, margin: 'auto', marginTop: 10}}
						 					                             gradients={[
						 					                             ['#1392E8', '#035598'],
						 					                             ]}
						 					                             property="background"
						 					                             duration={ 3000 }
						 					                             angle="0deg"
						 					                             className="buttonWhiteText">
						 					                             <div style={{paddingTop:3}}>
						 												 <Spinner color="#fff" size={14} speed={0.8}/>
						 										</div>
						 					                          </Gradient>
						 					                        }

				                                                              </form>

				          								  {this.state.displaySignButtons &&
														  <div style={{textAlign:'center', width:370, margin: 'auto', marginTop: '17px'}}>
				          										  <a href="javascript:void(0)" onClick={this.cancelResetPassword.bind(this)} style={{fontSize: '16px', fontWeight: 600}}>{t('Cancel')}</a>
				          								       </div>
													}
												 </div>

										    </div>
										}



											{this.state.isLoggedIn &&  this.state.processStatus == "success" &&
											    <div style={{position: 'absolute', zIndex: 6, width: '96%', height: '88%', background: '#fff'}}>
													<div style={{padding: '40px', textAlign: 'center'}}>
													  <div style={{fontSize: '20px', color: '#333'}}>
											                         {t('Thank_you')}, <span className="req_user_name"><b>{this.state.user_name}</b></span>
											               </div>
													  <div style={{clear: 'both', height: '30px'}} />
													  <div style={{background: 'rgb(113,179,0)', display: 'table', margin: 'auto', color: '#fff', borderRadius: '50%', textAlign: 'center', height: '70px', width: '70px'}}>
													    <div style={{display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '50px', width: '50px', fontWeight: 'normal', fontSize: '40px'}}>
													         &#x2713;
													    </div>
													  </div>
													  <div style={{clear: 'both', height: '20px'}} />
													  <div style={{color: 'rgb(143,148,157)', fontSize: '20px', textAlign: 'center'}}>{t('request_successfully_sent')}</div>
													  <div style={{color: 'rgb(143,148,157)', fontSize: '20px', textAlign: 'center', marginTop: '20px'}}>{t('we_will_contact_you_very_shortly')}</div>
													</div>

											   </div>
										     }

											{this.state.isLoggedIn &&  this.state.processStatus == "exists" &&
											    <div style={{position: 'absolute', zIndex: 6, width: '96%', height: '88%', background: '#fff'}}>
													<div style={{padding: '40px', textAlign: 'center'}}>
													  <div style={{fontSize: '20px', color: '#333'}}>
											                         {t('Hi')}, <span className="req_user_name"><b>{this.state.user_name}</b></span>
											               </div>
													  <div style={{clear: 'both', height: '30px'}} />
													  <div style={{background: 'rgb(113,179,0)', display: 'table', margin: 'auto', color: '#fff', borderRadius: '50%', textAlign: 'center', height: '70px', width: '70px'}}>
													    <div style={{display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '50px', width: '50px', fontWeight: 'normal', fontSize: '40px'}}>
													         &#x2713;
													    </div>
													  </div>
													  <div style={{clear: 'both', height: '20px'}} />
													  <div style={{color: 'rgb(143,148,157)', fontSize: '20px', textAlign: 'center'}}>{t('We_already_received_request')}</div>
													  <div style={{color: 'rgb(143,148,157)', fontSize: '20px', textAlign: 'center', marginTop: '20px'}}>{t('we_will_contact_you_very_shortly')}</div>
													</div>
											   </div>
										      }


								                    {this.state.isLoggedIn && this.state.processStatus == "" &&
									                  <div style={{position: 'absolute', zIndex: 6, width: '96%', height: '88%', background: '#fff'}}>
		  								             <div style={{clear: 'both', height: '15px'}} />
		  						                          <div style={{fontSize: '16px'}}>
			  							                          {i18n.language == "fr" &&
				  											        <div>Remplissez cet petit formulaire et puis cliquez sur «Je confirme».</div>

				  											}
				  										      {i18n.language == "en" &&
				  											   <div>Fill out this form and then click the «I confirm» button.</div>
				  											}
				  									    </div>
				  									   <div style={{clear: 'both', height: '35px'}} />


				  										<div style={{fontSize: '16px'}}>
				  						                                        <b>{t('I_want_to_join_the_community_as')}</b> <span style={{color: 'red'}}>*</span>
				  										</div>
														<div style={{clear: 'both', height: '5px'}} />
				  								              <div onClick={this.changeTypef.bind(this, 'dreamer')} style={{float: 'left', cursor: 'pointer', position: 'relative', width: '150px', fontSize: '15px', textAlign: 'center', border:'1px solid #c8d1e2', borderRadius: '20px', height: '40px', lineHeight: '40px'}}>
				  								                            {t('Dreamer')}

				  												{this.state.typef == "dreamer" &&
				  												  <div style={{position: 'absolute', zIndex: 3, top: '7px', right: '7px', background: 'rgb(113,179,0)', margin: 'auto', color: '#fff', borderRadius: '50%', textAlign: 'center',
				  														       height: '26px', width: '26px'}}>
				  												     <div style={{display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '24px', width: '24px',  lineHeight: '26px', fontWeight: 'normal', fontSize: '22px'}}>
				  												         &#x2713;
				  												      </div>
				  												 </div>
				  												}
				  								              </div>
				  								              <div onClick={this.changeTypef.bind(this, 'initiator')} style={{float: 'left', cursor: 'pointer', position: 'relative',  marginLeft: '20px', width: '150px', fontSize: '15px', textAlign: 'center', border:'1px solid #c8d1e2',   borderRadius: '20px', height: '40px', lineHeight: '40px'}}>
				  								                         {t('Initiator')}

				  												{this.state.typef == "initiator" &&
				  												  <div style={{position: 'absolute', zIndex: 3, top: '7px', right: '7px', background: 'rgb(113,179,0)', margin: 'auto', color: '#fff', borderRadius: '50%', textAlign: 'center',
				  														       height: '26px', width: '26px'}}>
				  												     <div style={{display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '24px', width: '24px',  lineHeight: '26px', fontWeight: 'normal', fontSize: '22px'}}>
				  												         &#x2713;
				  												      </div>
				  												 </div>
				  												}
				  								              </div>
				  								              <div onClick={this.changeTypef.bind(this, 'elder')}  style={{float: 'left', cursor: 'pointer', position: 'relative',  marginLeft: '20px', width: '150px', fontSize: '15px', textAlign: 'center', border:'1px solid #c8d1e2',   borderRadius: '20px', height: '40px', lineHeight: '40px'}}>
				  								                         {t('Elder')}

				  												{this.state.typef == "elder" &&
				  												  <div style={{position: 'absolute', zIndex: 3, top: '7px', right: '7px', background: 'rgb(113,179,0)', margin: 'auto', color: '#fff', borderRadius: '50%', textAlign: 'center',
				  														       height: '26px', width: '26px'}}>
				  												     <div style={{display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '24px', width: '24px',  lineHeight: '26px', fontWeight: 'normal', fontSize: '22px'}}>
				  												         &#x2713;
				  												      </div>
				  												 </div>
				  												}
				  								              </div>
				  								              <div style={{clear: 'both', height: '20px'}} />

				  										<div style={{fontSize: '16px'}}>
				  						                                        <b>{t('I_live')}</b> <span style={{color: 'red'}}>*</span>
				  										</div>
														<div style={{clear: 'both', height: '5px'}} />
				  								              <div onClick={this.changeLivingPlace.bind(this, 'Togo')} style={{float: 'left', cursor: 'pointer', position: 'relative', width: '150px', fontSize: '15px', textAlign: 'center', border:'1px solid #c8d1e2', borderRadius: '20px', height: '40px', lineHeight: '40px'}}>
				  								                         {t('In_togo')}

				  												{this.state.livingPlace == "Togo" &&
				  												  <div  style={{position: 'absolute', zIndex: 3, top: '7px', right: '7px', background: 'rgb(113,179,0)', margin: 'auto', color: '#fff', borderRadius: '50%', textAlign: 'center',
				  														       height: '26px', width: '26px'}}>
				  												     <div style={{display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '24px', width: '24px', lineHeight: '26px', fontWeight: 'normal', fontSize: '22px'}}>
				  												         &#x2713;
				  												      </div>
				  												 </div>
				  												}
				  								              </div>
				  								              <div onClick={this.changeLivingPlace.bind(this, 'Abroad')} style={{float: 'left', cursor: 'pointer', position: 'relative',  marginLeft: '20px', width: '150px', fontSize: '15px', textAlign: 'center', border:'1px solid #c8d1e2',   borderRadius: '20px', height: '40px', lineHeight: '40px'}}>
				  								                         {t('Outside_togo')}
				  												{this.state.livingPlace == "Abroad" &&
				  												  <div  style={{position: 'absolute', zIndex: 3, top: '7px', right: '7px', background: 'rgb(113,179,0)', margin: 'auto', color: '#fff', borderRadius: '50%', textAlign: 'center',
				  														       height: '26px', width: '26px'}}>
				  												     <div style={{display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '24px', width: '24px',  lineHeight: '26px', fontWeight: 'normal', fontSize: '22px'}}>
				  												         &#x2713;
				  												      </div>
				  												 </div>
				  												}
				  								              </div>
				  								              <div style={{clear: 'both', height: '20px'}} />

				  										<div style={{fontSize: '16px'}}>
				  						                                        <b>{t('My_whatsapp_contact')}</b> <span style={{color: '#999', fontWeight: 'normal', fontSize: '14px'}}>({t('optional')})</span>
				  										</div>
														<div style={{clear: 'both', height: '5px'}} />
				  								              <div style={{position: 'relative', width: '260px', fontSize: '15px', height: '30px', lineHeight: '30px'}}>
				  											<input className="basic_ti"  onChange={this.handleChangeWhatsapp} value={this.state.whatsapp} style={{width: '240px',padding: '6px', height: '22px', fontSize: '15px'}}
				  									      			  name="whatsapp" placeholder={''}  type="number" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>
				  								              </div>
				  									      <div style={{clear: 'both', height: '80px'}} />

				  								              <div onClick={this.sendConfirmation.bind(this)} style={{float: 'left', cursor: 'pointer', position: 'relative',  width: '300px', fontSize: '20px', textAlign: 'center',}}>
				  								                        {this.state.displaySignButtons &&
				  			 									<Gradient style={{width: '300px', height: '50px', lineHeight: '50px', fontSize:'20px', borderRadius: '25px'}}
				  			 										   gradients={[
				  			 										   			['#60AA24', '#8DC82C'],
				  			 										   		]}
				  			 									           property="background"
				  			 									           duration={ 3000 }
				  			 									           angle="20deg"
				  			 										    className="buttonWhiteText">
				  			 											{t('I_confirm')}
				  			 								      </Gradient>
				  											   }

				  							                        {!this.state.displaySignButtons &&
				  		 									<Gradient style={{width: '300px', height: '50px', lineHeight: '50px', fontSize:'20px', borderRadius: '25px'}}
				  		 										   gradients={[
				  		 										   			['#60AA24', '#8DC82C'],
				  		 										   		]}
				  		 									           property="background"
				  		 									           duration={ 3000 }
				  		 									           angle="20deg"
				  		 										    className="buttonWhiteText">
				  									                           <div style={{paddingTop:3}}>
				  															 <Spinner color="#fff" size={14} speed={0.8}/>
				  													 </div>
				  		 								      </Gradient>
				  										   }


				  								              </div>
				  								              <div style={{float: 'left', cursor: 'pointer', position: 'relative',  marginLeft: '20px', width: '150px', fontSize: '18px', textAlign: 'center',  height: '50px', lineHeight: '50px'}}>
				  										      {this.state.displaySignButtons && <a href="javascript:void(0)" onClick={this.cancelConfirm.bind(this)}>{t('Cancel')}</a>}
				  								              </div>
				  								    </div>
				  					    }




									    <div style={{position: 'relative', marginLeft: '20px', width: '90%', color: '#555'}}>
										      {i18n.language == "fr" &&
											    <div>
												  <div style={{clear: 'both', height: '5px'}} />
											        <div style={{fontSize: '14px'}}>Le processus d'enregistrement est propulsé par <a href={'https://www.debyoo.com'}  target="_blank">Debyoo</a>. Les identifiants de compte Debyoo sont donc nécessaires pour continuer.</div>
											     </div>
											}
										      {i18n.language == "en" &&
											   <div>
												  <div style={{clear: 'both', height: '5px'}} />
											         <div style={{fontSize: '14px'}}>The registration process is powered by <a href={'https://www.debyoo.com'}  target="_blank">Debyoo</a>. Debyoo account credentials then needed to continue.</div>
											   </div>
											}
									   </div>

									<div style={{clear: 'both', height: '15px'}} />


		                                               <div style={{marginLeft: '20px'}}>
									<div>
									     <div style={{fontSize: '19px', color: '#2489CE', width: '100%', marginTop: '0px'}}>
					                                       <b>{t('If_you_already_have_a_debyoo_profile')}</b>
					                                    </div>
									        <div style={{clear: 'both', height: '20px'}} />
									       <div style={{fontSize: '17px', color: '#333', width: '600px'}}><b>»</b> {t('Signin_with_your_debyoo_account')}</div>
									       <div style={{fontSize: '17px', color: '#333', width: '600px', marginTop: '5px'}}><b>»</b> {t('And_then_follow_instructions')}</div>
									</div>
									<div style={{clear: 'both', height: '20px'}} />
					                            <div style={{width: '480px'}}>
											<div style={{padding: '0px', paddingTop: '0px', paddingBottom: '0px', position: 'relative'}}>
											         <div style={{display: 'none', fontSize: '18px', color: '#2489CE', width: '100%', marginTop: '0px'}}><b>{t('signin')}</b></div>
											         <div style={{clear: 'both', height: '10px'}} />

												    <Gradient style={{display:this.state.showSigninError, position: 'absolute',zIndex:3,top:'-10px',width:370}}
													   gradients={[
													   			['#CA1C01', '#BB1603'],
													   		]}
												           property="background"
												           duration={ 3000 }
												           angle="0deg"
													    className="buttonWhiteText">
														  {t('sign_in_error')}
												     </Gradient>

							                                      <form onSubmit={this.onSubmitSignin} noValidate="novalidate">

														     <input className="basic_ti_top"  onChange={this.handleChangeEmailSignin} value={this.state.emailSignin} style={{width: '356px',padding: '6px', height: '20px', fontSize: '16px'}}
										      								        name="user[email]" placeholder={'Email'} type="email" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>

														      <div style={{position: 'relative', width: '370px'}}>
															    <div onClick={this.changeShowSIPswd.bind(this)} style={{position: 'absolute', zIndex: 3, cursor: 'pointer', right: '5px', top: '3px', width: '28px', height: '28px'}}>
														                   {this.state.showSIPswd && <img src={'/img/hide_pswd_icon.png'} style={{width: '28px'}}/>}
																     {!this.state.showSIPswd && <img src={'/img/show_pswd_icon.png'} style={{width: '28px'}}/>}
														           </div>
											      				    <input  className="basic_ti_bottom" name="user[password]" placeholder={t('Password')} onChange={this.handleChangePasswordSignin} value={this.state.passwordSignin}
											      								        style={{width: '356px',padding: '6px', height: '20px', fontSize: '16px'}} type={this.state.showSIPswd ? 'text':'password'}  autoCapitalize="off" autoComplete="off" autoCorrect="off"/>
														   </div>

				  		  								  {this.state.displaySigninButtons &&
														     <div>
												                          {this.state.displaySignButtons &&
													                              <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
													                                             <Gradient style={{width:370, marginTop: 10}}
													          									   gradients={[
													          									   			['#FAD741', '#F3BA21'],
													          									   		]}
													          								           property="background"
													          								           duration={ 3000 }
													          								           angle="0deg"
													          									    className="buttonBlackText">
													          										  {t('signin')}
													          					  </Gradient>
													                              </button>
													                           }

																	 {!this.state.displaySignButtons &&
													                              <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
												                                             <Gradient style={{width:370, marginTop: 10}}
												          									   gradients={[
												          									   			['#FAD741', '#F3BA21'],
												          									   		]}
												          								           property="background"
												          								           duration={ 3000 }
												          								           angle="0deg"
										 										                 className="buttonBlackText">
																	                           <div style={{paddingTop:3}}>
																							 <Spinner color="#000" size={14} speed={0.8}/>
																					 </div>
													          					  </Gradient>
													                              </button>
													                           }

								          								  {this.state.displaySignButtons &&
																		  <div style={{marginTop: '17px'}}>
								          										  <a href="javascript:void(0)" onClick={this.resetPassword.bind(this)} style={{fontSize: '16px', fontWeight: 600}}>{t('Forgot_password')}</a>
								          								       </div>
																	}
															      </div>
															   }


										                    </form>

											</div>
					                            </div>

									<div style={{clear: 'both', height: '10px'}} />
									  <div style={{clear: 'both', width: '100%', borderTop: '1px solid #c8d1e2'}} />
									  <div style={{clear: 'both', height: '10px'}} />

									<div>
									     <div style={{fontSize: '19px', color: '#2489CE', width: '100%', marginTop: '0px'}}>
					                                       <b>{t('If_you_dont_have_a_debyoo_profile')}</b>
					                                    </div>
									        <div style={{clear: 'both', height: '20px'}} />
									       <div style={{fontSize: '17px', color: '#333', width: '600px'}}><b>»</b> {t('Signup_first')}</div>
									       <div style={{fontSize: '17px', color: '#333', width: '600px', marginTop: '5px'}}><b>»</b> {t('And_then_follow_instructions')}</div>
									</div>
									<div style={{clear: 'both', height: '20px'}} />
					                            <div style={{width: '480px'}}>
											<div style={{padding: '0px', paddingTop: '0px', paddingBottom: '0px', position: 'relative'}}>

											           <div style={{display: 'none', fontSize: '18px', color: '#2489CE', width: '100%', marginTop: '0px'}}><b>{t('signup')}</b></div>
											           <div style={{clear: 'both', height: '10px'}} />


					                                                         <div style={{display: this.state.showTermsError, position: 'absolute',zIndex:3,top:-6,width:370, backgroundColor: '#CA1C01', borderRadius: 4}}>
											                   <div style={{padding:10, color: '#fff', fontSize: 14}}>{t('You_must_agree_to_the_terms')}</div>
								      			     	        </div>


									                             <Gradient style={{display: this.state.showNameError, position: 'absolute',zIndex:3,top:0,width:370}}
					         									   gradients={[
					         									   			['#CA1C01', '#BB1603'],
					         									   		]}
					         								           property="background"
					         								           duration={ 3000 }
					         								           angle="0deg"
					         									    className="buttonWhiteText">
					         										  {t('name_blank')}
									         				</Gradient>

									                               <Gradient style={{display: this.state.showEmailTakenError, position: 'absolute',zIndex:3,top:0,width:370}}
											                                gradients={[
											                                     ['#CA1C01', '#BB1603'],
											                                   ]}
											                                      property="background"
											                                      duration={ 3000 }
											                                      angle="0deg"
											                                 className="buttonWhiteText">
											                                 {t('email_taken')}
									                                </Gradient>

									                                <Gradient style={{display: this.state.showEmailInvalidError, position: 'absolute',zIndex:3,top:0,width:370}}
										                                   gradients={[
										                                       ['#CA1C01', '#BB1603'],
										                                     ]}
										                                        property="background"
										                                        duration={ 3000 }
										                                        angle="0deg"
										                                   className="buttonWhiteText">
										                                   {t('email_invalid')}
									                                  </Gradient>

									                                  <Gradient style={{display: this.state.showPasswordError, position: 'absolute',zIndex:3,top:0,width:370}}
										                                    gradients={[
										                                         ['#CA1C01', '#BB1603'],
										                                       ]}
										                                          property="background"
										                                          duration={ 3000 }
										                                          angle="0deg"
										                                     className="buttonWhiteText">
										                                     {t('password_blank')}
									                                    </Gradient>

									                                    <Gradient style={{display: this.state.showPasswordTooShortError, position: 'absolute',zIndex:3,top:0,width:370}}
									                                      gradients={[
									                                           ['#CA1C01', '#BB1603'],
									                                         ]}
									                                            property="background"
									                                            duration={ 3000 }
									                                            angle="0deg"
									                                       className="buttonWhiteText">
									                                       {t('password_short')}
									                                      </Gradient>


							  							    <form onSubmit={this.onSubmit} noValidate="novalidate">

							  			  								 <input className="basic_ti_top"  onChange={this.handleChangeName} value={this.state.name} style={{width: '356px',padding: '6px', height: '20px', fontSize: '16px'}}
							  			  								        name="user[name]" placeholder={t('Full_name')} type="text" autoCapitalize="on" autoComplete="off" autoCorrect="off"/>

							  			  								 <input className="basic_ti_middle"  onChange={this.handleChangeEmail} value={this.state.email} style={{width: '356px',padding: '6px', height: '20px', fontSize: '16px'}}
							  			  								        name="user[email]" placeholder={'Email'} type="email" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>

							  			  								   <div style={{position: 'relative', width: '370px'}}>
																		       <div onClick={this.changeShowSUPswd.bind(this)} style={{position: 'absolute', zIndex: 3, cursor: 'pointer', right: '5px', top: '3px', width: '28px', height: '28px'}}>
																		                {this.state.showSUPswd && <img src={'/img/hide_pswd_icon.png'} style={{width: '28px'}}/>}
																				  {!this.state.showSUPswd && <img src={'/img/show_pswd_icon.png'} style={{width: '28px'}}/>}
																		       </div>
																		       <input  className="basic_ti_bottom" name="user[password]" placeholder={t('Password')} onChange={this.handleChangePassword} value={this.state.password}
							  			  								        style={{width: '356px',padding: '6px', height: '20px', fontSize: '16px'}}  type={this.state.showSUPswd ? 'text':'password'} autoCapitalize="off" autoComplete="off" autoCorrect="off"/>
																		</div>


							  		  								     {this.state.displaySignupButtons &&
																	     <div>
																	      {this.state.displaySignButtons &&
							  											     <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
							  													<Gradient style={{width:370, marginTop: 10}}
							  					  									   gradients={[
							  					  									   			['#1392E8', '#035598'],
							  					  									   		]}
							  					  								           property="background"
							  					  								           duration={ 3000 }
							  					  								           angle="0deg"
							  					  									    className="buttonWhiteText">
							  					  										  {t('signup')}
							  			  								              </Gradient>
							  											      </button>
							  											 }


							  								                     {!this.state.displaySignButtons &&
							  											<Gradient style={{width:370, marginTop: 10}}
							  			  									   gradients={[
							  			  									   			['#1392E8', '#035598'],
							  			  									   		]}
							  			  								           property="background"
							  			  								           duration={ 3000 }
							  			  								           angle="0deg"
							  			  									    className="buttonWhiteText">
							  								                             <div style={{paddingTop:3}}>
							  								  						 <Spinner color="#fff" size={14} speed={0.8}/>
							  								  				 </div>
							  								                      </Gradient>
							  								                    }

							  			  								    <div style={{width:370, textAlign:'left', marginTop: 10, color:'#333', fontSize: 15}}>
							  												  <div style={{float: 'left', width:25,marginTop:-5, marginRight:7, marginLeft: 0}}>
							  												    <Checkbox id="termsCB" name="agreedTerms"
							  									                            type="default"
							  									                           defaultChecked={this.state.agreedTerms}
							  									                           onChange={this.handleChangeAgreedTerms} />
							  												  </div>
							  												  <div style={{float: 'left', width:20}}></div>
							  												  <div style={{float: 'left', width:320, marginLeft: 10}}>
							  													 {t('I_agree_to_debyoos')} <a href="/terms" style={{fontSize: '13px'}}>{t('terms_short')}</a>  {t('and')} <a href="/privacy" style={{fontSize: '13px'}}>{t('Privacy_policy')}</a> {t('of_debyoo_fr')}
							  											         </div>
							  												  <div style={{clear: 'both'}}></div>
							  											    </div>
																	    </div>
																	  }

							  									  </form>

											</div>
					                                     </div>
										 </div>





		   								<div style={{clear: 'both', height: '10px'}} />
		   								  <div style={{clear: 'both', width: '100%', borderTop: '1px solid #c8d1e2'}} />
		   								  <div style={{clear: 'both', height: '10px'}} />

		   								<div style={{float: 'left', width: '20px'}}>
		   									 <img src={'https://s3.amazonaws.com/debyooinc20.img/pc/private_icon.png'} style={{opacity: '0.8', width: '20px'}} />
		   								 </div>
		   								 <div style={{marginLeft: '10px', float: 'left', textAlign: 'left', fontSize: '12px', width: '70%', lineHeight: '15px', color: '#777'}}>
		   									    {i18n.language == "fr" &&
		   									        <div>Nous vous fournissons un environnement en ligne sécurisé. Les sites web de Togo the place to go et Debyoo utilisent le protocole SSL (Secure Sockets Layers) pour crypter vos informations personnelles. </div>
		   				                                         }
		   									    {i18n.language == "en" &&
		   										<div>We provide you with a secure online environment. Togo the place to go and Debyoo websites use the SSL (Secure Sockets Layer) to encrypt your personal information.</div>
		   									  }
		   								  </div>
		   								  <div style={{float: 'right', textAlign: 'right', height: '30px', lineHeight: '30px'}}>
		   								        <a href="javascript:void(0)" onClick={this.cancelConfirm.bind(this)} style={{fontSize: '18px'}}>{t('Cancel')}</a>
		   								  </div>

		   								 <div style={{clear: 'both', height: '0px'}} />



								  </div>

						</div>
			            </div>
				}


					  <HeaderTablet store={this.props.store}/>

					   <div className="clear-both" style={{height: '72px',background: '#fff', }}/>

					   <div style={{position: 'relative', background: '#000', minHeight: '100px'}}>

					             <div className="clear-both" style={{height: '20px'}}/>

					          <div style={{margin: 'auto', width: '44px', height:  '44px', borderRadius: '50%', border: '1px solid #555'}}>
					                <img src={'https://s3.amazonaws.com/debyooinc20.allflagsround/tg.png'} style={{width: '45px'}} />
					          </div>

						    <div className="clear-both" style={{height: '35px'}}/>

						     <div style={{width: '80%', maxWidth: '600px', margin: 'auto'}}>
						               <div style={{float: 'left', width: '35%'}}>
						                 <img src={'https://yinitj5.s3.amazonaws.com/yinitj_picture.jpg'} style={{width: '100%'}} />
						             </div>
							     <div style={{float: 'right', width: '62%', color: '#fff', fontSize: '21px', textAlign: 'left', fontWeight: 'normal', marginTop: '3px'}}>
								       {i18n.language == "fr" && <div><span style={{color: '#beae00'}}>Youth Initiative Jeunesse Togo</span> a pour objectif de soutenir, encourager et promouvoir l'initiative des jeunes au Togo.</div>}
								      {i18n.language == "en" && <div><span style={{color: '#beae00'}}>Youth Initiative Jeunesse Togo</span> aims to support, encourage and promote youth initiative in Togo. </div>}
							      </div>
							      <div className="clear-both" style={{height: '0px'}}/>
						     </div>
						     <div className="clear-both" style={{height: '30px'}}/>
				     </div>


	  			 <div style={{width: '100%', backgroundColor: '#fff'}}>

	  					   <div style={{margin: 'auto', height: '20px'}} />

	  					    <div style={{position: 'relative', width: '80%', maxWidth: '600px', margin: 'auto', textAlign: 'center', fontWeight: 'bold', fontSize: '27px', color: '#555'}}>
	  						      {i18n.language == "fr" &&
	  							    <div>
	  							       <div style={{fontWeight: 'bold', fontSize: '40px', color: '#222'}}>Rejoindre la communauté</div>
	  							        <div style={{margin: 'auto', height: '40px'}} />
	  							        <div>Vous pouvez rejoindre la communauté de Youth Initiative Jeunesse Togo en tant que <span style={{color: '#beae00'}}>rêveur</span>, <span style={{color: '#beae00'}}>initiateur</span> ou <span style={{color: '#beae00'}}>sage</span>.</div>
	  							     </div>
	  							}
	  						      {i18n.language == "en" &&
	  							   <div>
	  							       <div style={{fontWeight: 'bold', fontSize: '40px', color: '#222'}}>Join the community</div>
	  							        <div style={{margin: 'auto', height: '40px'}} />
	  							      <div>You can join the Youth Initiative Jeunesse Togo's community as a <span style={{color: '#beae00'}}>dreamer</span>, an <span style={{color: '#beae00'}}>initiator</span> or an <span style={{color: '#beae00'}}>elder</span>.</div>
	  							   </div>
	  							}
	  					   </div>

	  					   <div style={{margin: 'auto', height: '40px'}} />



		                               <div style={{position: 'relative', width: '96%', margin: 'auto'}}>

				                    <div style={{float: 'left', width: '32%', textAlign: 'justify', fontSize: '16px', color: '#333'}}>
									        <div style={{textAlign: 'center', fontSize: '25px', color: '#beae00', fontWeight: 'bold'}}>{t('A_dreamer')}</div>
									        <div className="clear-both" style={{height: '6px', width: '100%', borderBottom: '1px solid #ccc'}}/>

									          <div style={{margin: 'auto', height: '20px'}} />

									        {i18n.language == "fr" &&
									            <div style={{textAlign: 'left', fontSize: '18px', color: '#555'}}>
									              Vous êtes une jeune personne? Vous pouvez rejoindre la communauté en tant que rêveur: <br/>· Si vous avez un rêve et souhaitez passer du rêve à l'initiative<br/>· Ou si vos rêves ont été enterrés, brisés ou anéantis, et que vous voulez les ranimer<br/>· Ou si vous voulez être inspiré pour construire votre rêve. <br/><br/>
									              Appuyez sur le bouton «Je suis un rêveur» pour rejoindre la communauté.
									          </div>
								               }
									        {i18n.language == "en" &&
									            <div style={{textAlign: 'left', fontSize: '18px', color: '#555'}}>
									              You’re a young person? You can join the community as a dreamer: <br/>· If you have a dream and want to move from dream to initiative<br/>· Or if your dreams have been buried, bruised or shattered, and want to awaken them<br/>· Or if you want to be inspired to build your dream. <br/><br/>
									              Tap the «I'm a dreamer» button to join the community.
									          </div>
								               }
								    </div>
								    <div style={{float: 'left', marginLeft: '2%', width: '32%',  textAlign: 'justify', fontSize: '16px', color: '#333'}}>
									        <div style={{textAlign: 'center', fontSize: '25px', color: '#beae00', fontWeight: 'bold'}}>{t('An_initiator')}</div>
									        <div className="clear-both" style={{height: '6px', width: '100%', borderBottom: '1px solid #ccc'}}/>

									          <div style={{margin: 'auto', height: '20px'}} />

									         {i18n.language == "fr" &&
									           <div style={{textAlign: 'justify', fontSize: '18px', color: '#555'}}>
									              Un initiateur est un jeune qui a déjà initié quelque chose (une entreprise, un événement, une organisation, un produit, une invention, etc.).  <br/><br/>Vous souhaitez partager votre histoire? Ou Vous avez besoin de plus d’impact, de visibilité, de ressources, de connexions, d'un mentor ou de quoi que ce soit pour accomplir votre mission? Appuyez sur le bouton «Je suis un initiateur» pour rejoindre la communauté.
									            </div>
										   }
									         {i18n.language == "en" &&
									           <div style={{textAlign: 'justify', fontSize: '18px', color: '#555'}}>
									             An initiator is a young person who has already initiated something (a company, an event, an organization, a product,  an invention etc.).  <br/><br/>You want to share your story? Or  You need more impact, visibility, resources, connection, mentorships or anything to fulfill your mission? Tap the «I'm an initiator» button to join the community.
									            </div>
										   }
								    </div>
								    <div style={{ float: 'right', width: '32%',  textAlign: 'justify', fontSize: '16px', color: '#333'}}>
									        <div style={{textAlign: 'center', fontSize: '25px', color: '#beae00', fontWeight: 'bold'}}>{t('An_elder')}</div>
									        <div className="clear-both" style={{height: '6px', width: '100%', borderBottom: '1px solid #ccc'}}/>

									          <div style={{margin: 'auto', height: '20px'}} />
									         {i18n.language == "fr" &&
									             <div style={{textAlign: 'justify', fontSize: '18px', color: '#555'}}>
									                Un sage est une personne qui a vécu toutes sortes d’expériences et qui veut partager ses leçons de vie et ses expériences avec les jeunes. <br/><br/>Si vous avez des histoires pleines de courage, de bonheur, de tristesse et même de colère, et que vous souhaitez partager ces histoires merveilleuses et votre sagesse avec les jeunes, veuillez appuyer sur le bouton «Je suis un sage» pour rejoindre la communauté.
									           </div>
									         }
									         {i18n.language == "en" &&
									             <div style={{textAlign: 'justify', fontSize: '18px', color: '#555'}}>
									               An elder is a person who has lived all kinds of experiences and want to share their life lessons and experiences with youth. <br/><br/>If you have stories full of bravery, happiness, sadness, and even anger, and want to share those wonderful stories and your wisdom to youth, please tap the «I'm an elder» button to join the community.
									           </div>
									         }
								    </div>


								     <div className="clear-both" style={{height: '30px'}}/>


								    <div onClick={this.startRegistration.bind(this, 'dreamer')} style={{float: 'left', width: '32%', height: '60px', lineHeight: '60px', borderRadius: '30px',
										        textAlign: 'center', fontSize: '18px', backgroundColor: '#0857da', color: '#fff', border: '1px solid #31579d'}}>
									        {t('Im_a_dreamer')}
								    </div>
								    <div onClick={this.startRegistration.bind(this, 'initiator')} style={{float: 'left', marginLeft: '2%', width: '32%', height: '60px', lineHeight: '60px', borderRadius: '30px',
										        textAlign: 'center', fontSize: '18px', backgroundColor: '#0857da', color: '#fff', border: '1px solid #31579d'}}>
									        {t('Im_an_initiator')}
								    </div>
								    <div onClick={this.startRegistration.bind(this, 'elder')} style={{float: 'right', width: '32%', height: '60px', lineHeight: '60px', borderRadius: '30px',
										        textAlign: 'center', fontSize: '18px', backgroundColor: '#0857da', color: '#fff', border: '1px solid #31579d'}}>
									        {t('Im_an_elder')}
								    </div>

								    <div className="clear-both" style={{height: '0px'}}/>

						 </div>

				    </div>




  			          <div className="clear-both" style={{height: '80px',background: '#fff', }}/>



  				   <div style={{width: '100%', backgroundColor: '#fff'}}>
  						{i18n.language == "fr" &&
  						    <div style={{position: 'relative', float:'left', background: 'url(https://yinitj5.s3.amazonaws.com/inspire_dreamers_fr.jpg) no-repeat center center',   backgroundSize: 'cover',
  							            width: (viewportWidth/2)-1, backgroundColor: '#f5f5f7', height: (((viewportWidth/2)-1)/1.46)+'px'}}>
  						    </div>
  					        }
  						{i18n.language == "en" &&
  						    <div style={{position: 'relative', float:'left', background: 'url(https://yinitj5.s3.amazonaws.com/inspire_dreamers_en.jpg) no-repeat center center',   backgroundSize: 'cover',
  							             width: (viewportWidth/2)-1, backgroundColor: '#f5f5f7', height: (((viewportWidth/2)-1)/1.46)+'px'}}>
  						    </div>
  					        }
  						{i18n.language == "fr" &&
  						   <div style={{position: 'relative', float:'right', background: 'url(https://yinitj5.s3.amazonaws.com/elder_fr.jpg) no-repeat center center',   backgroundSize: 'cover',
  							            width: (viewportWidth/2)-1, backgroundColor: '#f5f5f7', height: (((viewportWidth/2)-1)/1.46)+'px'}}>
  						   </div>
  						  }
  						{i18n.language == "en" &&
  						   <div style={{position: 'relative', float:'right', background: 'url(https://yinitj5.s3.amazonaws.com/elder_en.jpg) no-repeat center center',   backgroundSize: 'cover',
  							             width: (viewportWidth/2)-1, backgroundColor: '#f5f5f7', height: (((viewportWidth/2)-1)/1.46)+'px'}}>
  						   </div>
  						  }
  						<div className="clear-both" style={{height: '0px'}}/>
  				     </div>



				          <div className="clear-both" style={{height: '10px',background: '#fff', }}/>


					 <FooterTablet />

		         </div>
		  }
		 </Translation>
    )
  }
}



const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setColorAction: (payload) => dispatch(setColorAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TGPageTablet);
